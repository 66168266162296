import TransportStream from 'winston-transport';

import NewRelicAbstract from '@@utils/logger/transports/NewRelicAbstract';

import Browser from '../../newrelicAgent/Browser';

/**
 * Logs to newrelic using the newrelic browser agent API
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/
 */
export default class NewRelicBrowser extends NewRelicAbstract {
  public constructor(opts?: TransportStream.TransportStreamOptions) {
    super(opts);
    this.newRelicAgent = Browser;
  }
}
