import { FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';

interface HttpRedirectProps {
  to: string;
  statusCode?: number;
}

const HttpRedirect: FunctionComponent<HttpRedirectProps> = (props) => {
  const { to, statusCode = 301 } = props;

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = statusCode;
        }
        return <Redirect key={to} to={to}/>;
      }}
    />
  );
};

export default HttpRedirect;
