import { Box, Card, CardContent, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlayButton from '@@src/components/Video/PlayButton';
import useCalculateProgress from '@@src/hooks/useCalculateProgress';
import useDurationToLive from '@@src/hooks/useDurationToLive';
import useIsInViewPort from '@@src/hooks/useIsInViewPort';
import { generatePathFromLinkProps, OdLink } from '@@src/routes';
import grey from '@@styles/colors/grey';
import orange from '@@styles/colors/orange';
import fontFamily from '@@styles/typography/fontFamily';

import LiveBadge from '../DataDisplay/Badge/LiveBadge';
import AvailableDateTimeLabel from '../Utils/AvailableDateTimeLabel';
import LiveCardMedia from '../Utils/LiveCardMedia';

export const sharedStyle = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      backgroundColor: 'transparent',
      position: 'relative',
      borderRadius: 4,
    },
    title: {
      transition: 'color 300ms',
      '$root:hover &': {
        color: orange.darkTangerine,
      },
    },
    mediaRoot: {
      overflow: 'hidden',
      borderRadius: 5,
    },
    link: {
      display: 'block',
      padding: 4,
      '&:focus-visible': {
        outlineOffset: -3,
      },
    },
    media: {
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%',
      overflow: 'hidden',
      transition: 'transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);',
      [theme.breakpoints.up('sm')]: {
        '@media (hover: hover)': {
          '$root:hover &': {
            transform: 'scale(1.1)',
          },
        },
      },
      '$root:hover &': {
        transform: 'none',
      },
    },
    playButton: {
      width: 80,
      height: 80,
      [theme.breakpoints.down('sm')]: {
        width: 48,
        height: 48,
        opacity: 0,
      },
      'html:not(.mobile) $root:hover &': {
        opacity: 0.8,
      },
      'html:not(.mobile) $root:hover &:hover': {
        opacity: 0.9,
      },
    },
    playButtonOverlay: {
      opacity: 0,
      'html:not(.mobile) $root:hover &': {
        opacity: 1,
      },
    },
    status: {
      fontFamily: fontFamily.secondary,
      fontWeight: 500,
    },
  });
};
const useSharedStyles = makeStyles(sharedStyle);

const useStyles = makeStyles<Theme, LiveScheduleTileLiveProps>((theme: Theme) => {
  return createStyles({
    statusBadge: {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '3px 20px 4px 10px',
      borderRadius: '0 0 18px 0',
    },
    titleContainer: {
      flexWrap: 'nowrap',
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      fontSize: '1.25rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      lineHeight: '1.3',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    cardContent: {
      width: '100%',
      padding: '16px 12px 16px 12px',
      [theme.breakpoints.down('sm')]: {
        padding: 8,
      },
    },
    descriptionBox: {
      marginTop: '4px',
    },
    description: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      fontSize: '0.875rem',
      color: grey.chateau,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      lineHeight: '1.1',
    },
    thumbnail: {
      height: '50%',
      position: 'relative',
    },
  });
});

export interface LiveScheduleTileLiveProps {
  /** The video ID of the live stream, used to generate a link to its watch page */
  id: string;
  /** The title of the live stream. */
  title: string;
  /** The url for the thumbnail */
  imageUrl: string;
  /** Start date/time in ISO format. */
  start: string;
  /** End date/time in ISO format. */
  end: string;
  onClick?: () => void;
}

/**
 * Live Tile to be used on a Live Content shelf.
 * Displays Live badge, background image, progress bar, title of live stream and "Started Xm ago" message
 */
const LiveScheduleTileLive: FunctionComponent<LiveScheduleTileLiveProps> = (props) => {
  const classes = useStyles(props);
  const sharedClasses = useSharedStyles(props);
  const { t } = useTranslation('common');

  const {
    title,
    imageUrl,
    start,
    end,
    id,
    onClick,
  } = props;

  const durationToLive = useDurationToLive(start);
  const progressPercent = useCalculateProgress(start, end);
  const [upcomingOrLiveDateTimeStatus, setUpcomingOrLiveDateTimeStatus] = useState<string | ReactElement>('');

  // since UpcomingOrLiveDateTime is dynamic, it needs to be rendered client side
  useEffect(() => {
    setUpcomingOrLiveDateTimeStatus(
      <AvailableDateTimeLabel start={start}/>,
    );
  }, [start]);

  const badgeColor = 'success';
  const badgeLabel = t('availabilityBadge.live');

  const tileRef = useRef();

  const inViewPort = useIsInViewPort(tileRef);

  const link = generatePathFromLinkProps({
    name: 'watch',
    params: {
      id,
    },
  });

  const playButtonLabel = t('playback.watchLivestreamFromLiveTile', {
    title,
    context: durationToLive < -3153600 && 'noDuration',
    duration: durationToLive,
    units: ['mo', 'w', 'd', 'h', 'm', 's'],
  });

  return (
    <OdLink
      to={link}
      onClick={onClick}
      aria-label={playButtonLabel}
      className={sharedClasses.link}
      data-testid="tile"
      tabIndex={inViewPort ? 0 : -1}
    >
      <Card className={sharedClasses.root} square elevation={0} ref={tileRef}>
        <div className={classes.thumbnail}>
          <LiveCardMedia
            title={title}
            imageUrl={imageUrl}
            progressPercent={progressPercent}
            classes={{
              media: clsx(sharedClasses.media, classes.media),
              root: sharedClasses.mediaRoot,
            }}
          />
          <Hidden smDown>
            <PlayButton
              overlay
              classes={{ playIcon: sharedClasses.playButton, overlay: sharedClasses.playButtonOverlay }}
            />
          </Hidden>
          <Hidden mdUp>
            <PlayButton
              size={48}
              overlay
              classes={{ playIcon: sharedClasses.playButton }}
            />
          </Hidden>
        </div>
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid container className={classes.titleContainer}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5" component="h3" className={clsx(sharedClasses.title, classes.title)}>
                {title}
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid item>
              <Box mt={1} className={classes.descriptionBox}>
                {
                  /* Don't show the date if the stream started more than a year ago */
                  durationToLive >= -31536000 && (
                    <Typography variant="body2" className={classes.description}>
                      {upcomingOrLiveDateTimeStatus}
                    </Typography>
                  )
                }
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Box className={classes.statusBadge}>
          <LiveBadge color={badgeColor} overlap="rectangular">
            <Typography variant="body2" className={sharedClasses.status}>
              {badgeLabel}
            </Typography>
          </LiveBadge>
        </Box>
      </Card>
    </OdLink>
  );
};

export default LiveScheduleTileLive;
