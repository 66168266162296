import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

/**
 * Calculate the time before it expires
 * @param end - end date in ISO format
 */
const useDurationToExpired = (end: string) => {
  const endDateTime = DateTime.fromISO(end) as DateTime;

  const [durationToExpired, setDurationToExpired] = useState<number>(Math.floor(endDateTime.diffNow().as('seconds')));

  useEffect(() => {
    let expiredTimeout;

    // Update duration to expired
    const delayExpiredCalculation = durationToExpired > 60 ? 60000 : 1000;

    setDurationToExpired(Math.round(endDateTime.diffNow().as('seconds')));
    if (durationToExpired > 0) {
      expiredTimeout = setTimeout(() => {
        setDurationToExpired(Math.round(endDateTime.diffNow().as('seconds')));
      }, delayExpiredCalculation);
    }

    return () => {
      clearTimeout(expiredTimeout);
    };
  }, [durationToExpired, endDateTime]);

  return durationToExpired;
};

export default useDurationToExpired;
