import OnDemand from '@@types/OnDemand';

import videoLookupApi from '../apis/VideoLookupApi';
import { transformToVideoItem } from '../transformers/FeedTransformer';

/**
 * Fetch a video object from API and transform it to video item
 */
export function getVideo(id): Promise<OnDemand.Video> {
  return videoLookupApi.fetch(id)
    .then((result) => {
      if (result !== undefined) {
        return transformToVideoItem(result);
      }

      return null;
    });
}

export function getOgType(video: OnDemand.Video): string {
  switch (video.type) {
    case 'Movie':
    case 'OneOff':
      return 'video.movie';
    case 'Episode':
      return 'video.episode';
    case 'Clip':
    default:
      return 'video.other';
  }
}
