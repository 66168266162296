import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useCallback } from 'react';

import LiveTileSlider from '@@src/components/Carousel/LiveTileSlider';
import { ShelfRow } from '@@src/components/PageRows/PageRow';
import ShelfTitle from '@@src/components/Shelf/ShelfTitle';
import LiveScheduleTile from '@@src/components/Tiles/LiveScheduleTile';
import OdContainer from '@@src/components/Utils/OdContainer';
import padding from '@@styles/padding';
import DataLayer from '@@utils/DataLayer';

const liveContentStyles = (theme: Theme) => {
  return createStyles({
    sliderRoot: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    slider: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    sliderContent: {
      paddingLeft: padding.container.xs - 2,
      paddingRight: padding.container.xs + 2,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: padding.container.sm - 2,
        paddingRight: padding.container.sm - 6,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: padding.container.md - 2,
        paddingRight: padding.container.md - 6,
      },
      '& > div': {
        whiteSpace: 'normal',
        marginRight: theme.spacing(0.5),
      },
    },
    sliderButton: {
      width: 60,
    },
    sliderIcon: {
      margin: 'auto',
    },
    shelfTitle: {
      marginBottom: '0.875rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.625rem',
      },
    },
  });
};
export const useStyles = makeStyles(liveContentStyles);

export interface LiveScheduleShelfProps {
  row: ShelfRow;
  rowIndex: number;
}

const LiveScheduleShelf: FunctionComponent<LiveScheduleShelfProps> = (props) => {
  const { row: { name, items, route }, rowIndex } = props;
  const classes = useStyles(props);

  const handleShelfTitleClick = useCallback(() => {
    DataLayer.setClickSource(
      `${name}:view all`,
      'carousel',
      rowIndex + 1,
      null,
    );
  }, [name, rowIndex]);

  return (
    <LiveTileSlider
      buttonVariant="arrow"
      titleComponent={(
        <OdContainer className={classes.shelfTitle}>
          <ShelfTitle
            name={name}
            route={route}
            onClick={handleShelfTitleClick}
          />
        </OdContainer>
      )}
      getPrevOffset={
        /* istanbul ignore next */
        (sliderContentElement: HTMLDivElement) => {
          const sliderContentPaddingLeft = parseInt(window.getComputedStyle(sliderContentElement, null).getPropertyValue('padding-left'), 10);
          const visibleTiles = sliderContentElement.querySelectorAll('a[tabindex="0"]');

          // Scrolling to the previous set of tiles. We are here calculating the amount of scrolling to perform in order
          // to bring the previous (hidden or partially displayed) tile into full view
          for (let ti = 0; ti < visibleTiles.length; ti += 1) {
            const tile = visibleTiles[ti];
            const tilePositions = tile.getBoundingClientRect();

            // Checking this tile is fully visible
            if (tilePositions.left >= 0) {
              return sliderContentElement.scrollLeft - ((window.innerWidth - tilePositions.left) - (sliderContentPaddingLeft));
            }
          }

          return 0;
        }
      }
      getNextOffset={
        /* istanbul ignore next */
        (sliderContentElement: HTMLDivElement) => {
          const sliderContentPaddingLeft = parseInt(window.getComputedStyle(sliderContentElement, null).getPropertyValue('padding-left'), 10);
          const visibleTiles = sliderContentElement.querySelectorAll('a[tabindex="0"]');

          // Scrolling to the next set of tiles. We are here calculating the amount of scrolling to perform in order
          // to bring the next (hidden or partially displayed) tile into full view
          for (let ti = visibleTiles.length; ti > 0; ti -= 1) {
            const tile = visibleTiles[ti - 1];
            const tilePositions = tile.getBoundingClientRect();
            const tileMarginRight = parseInt(window.getComputedStyle(tile.parentElement, null).getPropertyValue('margin-right'), 10);

            // Checking this tile is fully visible
            if (tilePositions.right <= window.innerWidth) {
              return sliderContentElement.scrollLeft + tilePositions.right + tileMarginRight - sliderContentPaddingLeft;
            }
          }

          return 0;
        }
      }
      classes={{
        root: classes.sliderRoot,
        slider: classes.slider,
        sliderContent: classes.sliderContent,
        sliderButton: classes.sliderButton,
        sliderIcon: classes.sliderIcon,
      }}
    >
      {items.map((tile, tileIndex) => {
        const { id: tileId, item, imageProps } = tile;
        if (item.type === 'Movie' || item.type === 'Episode' || item.type === 'OneOff' || item.type === 'Clip') {
          return (
            <LiveScheduleTile
              key={tileId}
              item={item}
              imageProps={imageProps}
              rowIndex={rowIndex}
              tileIndex={tileIndex}
              shelfName={name}
            />
          );
        }
        return null;
      })}
    </LiveTileSlider>
  );
};

export default LiveScheduleShelf;
