import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export interface SettingsStoreState {
  showMobileSearchBar: boolean;
  serverSideRendered: boolean;
  adBlockDetected: boolean | undefined;
}

const initialState: SettingsStoreState = {
  showMobileSearchBar: false,
  serverSideRendered: true,
  adBlockDetected: undefined,
};

const settingsStore = createSlice({
  name: 'SettingsStore',
  initialState,
  reducers: {
    switchOn(state, action: PayloadAction<keyof SettingsStoreState>) {
      state[action.payload] = true;
    },
    switchOff(state, action: PayloadAction<keyof SettingsStoreState>) {
      state[action.payload] = false;
    },
    toggleSwitch(state, action: PayloadAction<keyof SettingsStoreState>) {
      state[action.payload] = !state[action.payload];
    },
    setSetting(state, action: PayloadAction<{ key: keyof SettingsStoreState; value: any }>) {
      state[action.payload.key] = action.payload.value;
    },
    removeSetting(state, action: PayloadAction<keyof SettingsStoreState>) {
      state[action.payload] = null;
    },
  },
});

// selectors
export const getSetting = createSelector(
  (rootState: RootState) => {
    return rootState.settings;
  },
  (state, key) => {
    return key;
  },
  (state, key) => {
    return state[key];
  },
);

export const getServerSideRendered = createSelector(
  (rootState: RootState) => {
    return rootState.settings;
  },
  (state) => {
    return state.serverSideRendered;
  },
);

export default settingsStore;
export const {
  switchOn,
  switchOff,
  toggleSwitch,
  setSetting,
  removeSetting,
} = settingsStore.actions;
