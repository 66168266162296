import { ClassNameMap } from '@material-ui/styles';
import { RefObject } from 'react';

import OnDemand2 from '@@src/@types/OnDemand2';

import CollectionTile from './CollectionTile';
import PageTile from './PageTile';
import SeriesTile from './SeriesTile';
import VideoTile from './VideoTile';

interface CollectionItemTileProps {
  ref?: RefObject<HTMLDivElement>;
  className: string;
  onActivate: () => void;
  onDeactivate: () => void;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  canBeFocused?: boolean;
  classes?: ClassNameMap;
  displayType: '16:9' | '2:3'
}

const CollectionItemTile: React.FC<{ item: OnDemand2.CollectionItem, props: CollectionItemTileProps }> = ({ item, props }) => {
  switch (item.entityType) {
    case 'Movie':
    case 'Program':
    case 'Episode':
    case 'Clip':
      return (
        <VideoTile collectionTileItem={item} {...props}/>
      );
    case 'Series':
      return (
        <SeriesTile collectionTileItem={item} {...props}/>
      );
    case 'Collection':
      return (
        <CollectionTile collectionTileItem={item} {...props}/>
      );
    case 'Page':
      return (
        <PageTile collectionTileItem={item} {...props}/>
      );
    default:
      return null;
  }
};

export default CollectionItemTile;
