/**
 * Abstracts the browser monitoring tool implementation (newrelic) that is used throughout the application.
 */

declare global {
  interface Window {
    NREUM: any;
  }
}

// Report error
function noticeError(error: any, customAttributes: any = undefined) {
  if (window.NREUM) {
    window.NREUM.noticeError(error, customAttributes);
  }
}

function addPageAction(name: string, attributes?: Record<string, string | number>) {
  if (window.NREUM) {
    window.NREUM.addPageAction(name, attributes);
  }
}

const Browser = {
  noticeError,
  addPageAction,
};

export default Browser;
