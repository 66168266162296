import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import Logger from '@@utils/logger/Logger';

import GptManager from './GptManager';

const gptManager = new GptManager();

interface GptProps {
  adUnitPath: string;
  slotSize: number[] | number[][];
  targeting?: Record<string, string>;
  enableSingleRequest?: boolean;
  onSlotRenderEnded?(event?: googletag.events.SlotRenderEndedEvent): void;
  ppid?: string;
}

const Gpt: FunctionComponent<GptProps> = (props) => {
  const [gptScriptLoaded, setGptScriptLoaded] = useState<boolean>(false);

  const {
    targeting = {},
    slotSize,
    adUnitPath,
    enableSingleRequest = false,
    onSlotRenderEnded,
    ppid = undefined,
    ...rest
  } = props;

  const adSlot = useRef();

  const divId = useMemo(() => {
    return `mrec-${gptManager.generateId()}`;
  }, []);

  // load gpt library
  useEffect(() => {
    if (!gptScriptLoaded) {
      gptManager.load()
        .then(() => {
          Logger.debug('Gpt: gptManager loaded');
          setGptScriptLoaded(true);
        });
    }
  }, [gptScriptLoaded]);

  const onSlotRenderCallback = useCallback((event: googletag.events.SlotRenderEndedEvent) => {
    const { slot } = event;

    if (Logger.level === 'debug') {
      /* eslint-disable no-console */
      console.group('Slot', slot.getSlotElementId(), 'finished rendering.');
      console.log(event);
      console.log('Advertiser ID:', event.advertiserId);
      console.log('Campaign ID: ', event.campaignId);
      console.log('Creative ID: ', event.creativeId);
      console.log('Size:', event.size);
      console.log('Is empty?:', event.isEmpty);
      console.groupEnd();
      /* eslint-enable no-console */
    }
    if (onSlotRenderEnded) {
      onSlotRenderEnded(event);
    }
  }, [onSlotRenderEnded]);

  // define an ad slot and display the ad
  // useDeepCompareEffect because the targeting is an object and sizeSlot can be a nested array
  useDeepCompareEffect(() => {
    let _adSlot;

    const { googletag } = gptManager;

    if (adSlot.current) {
      googletag.destroySlots([adSlot.current]);
    }

    if (gptScriptLoaded) {
      Logger.debug('Gpt: Define an ad slot and display it', {
        divId,
      });

      googletag.cmd.push(() => {
        _adSlot = googletag.defineSlot(adUnitPath, slotSize, divId)
          .addService(googletag.pubads());

        if (enableSingleRequest) {
          googletag.pubads().enableSingleRequest();
        }

        googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderCallback);

        if (ppid) {
          googletag.pubads().setPublisherProvidedId(ppid);
        }

        gptManager.enableServices();

        Object.keys(targeting).forEach((key) => {
          _adSlot.setTargeting(key, targeting[key]);
        });

        googletag.display(divId);

        adSlot.current = _adSlot;
      });
    }

    return () => {
      if (_adSlot) {
        Logger.debug('Gpt: Destroy adslot');
        googletag.destroySlots([_adSlot]);
        googletag.pubads().removeEventListener('slotRenderEnded', onSlotRenderCallback);
      }
    };
  }, [divId, gptScriptLoaded, slotSize, targeting, enableSingleRequest, onSlotRenderEnded]);

  if (gptScriptLoaded) {
    // only assign the id when the script is loaded
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div id={divId} {...rest}/>;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...rest}/>;
};

export default Gpt;
