import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';

import OnDemand from '../../@types/OnDemand';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import AvailableVideoPlayWidget from './VideoPlayWidget-Available';
import AvailableSoonVideoPlayWidget from './VideoPlayWidget-AvailableSoon';
import ExpiredVideoPlayWidget from './VideoPlayWidget-Expired';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    playButtonContainer: {
      display: 'inline-flex',
      marginRight: 24,
    },
    centeredContent: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
    },
    playButton: {
      fontSize: '5.5rem',
      padding: 0,
      cursor: 'pointer',
      '&:hover': {
        background: 'none',
      },
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
      '&:hover circle': {
        fill: orange.goldenTainoi,
      },
      '&:disabled': {
        opacity: 0.2,
        '& circle': {
          fill: 'white',
        },
      },
    },
    label: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      lineHeight: '1',
      color: orange.darkTangerine,
      marginBottom: 8,
      '.hasProgress &': {
        marginBottom: 6,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
      fontFamily: fontFamily.secondary,
    },
    availabilityLabel: {
      fontFamily: fontFamily.secondary,
      fontSize: '1.375rem',
      fontWeight: 700,
      lineHeight: '1',
      color: orange.darkTangerine,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    watchLabel: {
      fontFamily: fontFamily.secondary,
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1',
      color: 'white',
      marginTop: 1,
      '&.no-badge': {
        marginTop: 8,
      },
    },
    badge: {
      marginTop: 7,
    },
    metadata: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontSize: '1.375rem',
      fontWeight: 'bold',
      lineHeight: '1.2',
      color: 'white',
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.125rem',
      },
    },
    expiredTitle: {
      '& $title': {
        marginBottom: 0,
      },
    },
    duration: {
      fontFamily: fontFamily.secondary,
      fontSize: '1rem',
      lineHeight: '1',
      color: grey.chateau,
      marginBottom: 0,
      '.hasProgress &': {
        marginBottom: 10,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
    },
    progressBarFill: {
      width: 304,
      [theme.breakpoints.down('xs')]: {
        width: 272,
      },
    },
  });
});

export interface VideoPlayWidgetProps {
  video: OnDemand.SlimVideo;
  label?: string;
  classes?: {
    root?: any;
  };
}

const VideoPlayWidget: FunctionComponent<VideoPlayWidgetProps> = (props) => {
  const { video, label, classes } = props;

  const [durationToAvailable, setDurationToAvailable] = useState(Math.floor(DateTime.fromISO(video.availableDate).diffNow().as('seconds')));

  useEffect(() => {
    if (durationToAvailable > 0) {
      setTimeout(() => {
        setDurationToAvailable(Math.floor(DateTime.fromISO(video.availableDate).diffNow().as('seconds')));
      }, durationToAvailable * 1000);
    }
  }, [video, durationToAvailable]);

  if (video.expired) {
    return <ExpiredVideoPlayWidget video={video} classes={classes}/>;
  }

  if (!video.available && durationToAvailable > 0) {
    return <AvailableSoonVideoPlayWidget video={video} classes={classes}/>;
  }

  return <AvailableVideoPlayWidget video={video} label={label} classes={classes}/>;
};

export default VideoPlayWidget;
