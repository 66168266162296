import { loadableReady } from '@loadable/component';
import { createBrowserHistory, History } from 'history';
import { decode } from 'js-base64';
import { hydrate } from 'react-dom';
import { useSSR } from 'react-i18next';
import { Router } from 'react-router-dom';

import 'focus-visible';
import 'lazysizes';
// this plugin is required to work properly with react when the attribute changes
import 'lazysizes/plugins/attrchange/ls.attrchange';

import App from './App';
import InlineError from './components/Error/InlineError';
import { getUrlPrefix, SupportedLanguage } from './i18n';
import initialiseStore from './store';
import DataLayer from './utils/DataLayer';
import { BASENAME } from './utils/constants';
import { getScrollPosition, saveLocationScrollPosition } from './utils/helpers';

declare global {
  interface Window {
    _LANG_: SupportedLanguage;
    _I18N_STORE_: any;
    _REDUX_DATA_: any;
    _INITIAL_PROPS_: any;
  }
}

// remove duplicate slash on the location pathname on the client side
// akamai (prod) and nginx reverse proxy (QA / STG) are removing double slashes on the path forwarded to the origin
// we are stripping duplicate slashes on the client side to avoid path mismatch between server side and client side
const sanitisedPath = window.location.pathname.replace(/\/+/g, '/');
if (sanitisedPath !== window.location.pathname) {
  window.location.replace(sanitisedPath);
}

const store = initialiseStore(JSON.parse(decode(window._REDUX_DATA_)));
const initialProps = JSON.parse(decode(window._INITIAL_PROPS_));

DataLayer.init();

/**
 * Wrap the history object so that we can listen to history changes and record the scroll history
 * @param history
 */
const wrapHistory = (history: History) => {
  let previousLocation = history.location;

  history.listen((location) => {
    saveLocationScrollPosition(previousLocation, getScrollPosition());
    previousLocation = location;
  });
};

const BaseApp = () => {
  useSSR(JSON.parse(decode(window._I18N_STORE_)), window._LANG_);

  let basename = BASENAME;

  // add language to the basename
  const urlPrefix = getUrlPrefix(window._LANG_);
  if (urlPrefix) {
    basename += `/${urlPrefix}`;
  }

  const history = createBrowserHistory({ basename });
  wrapHistory(history);

  // The server can't render a react error page
  if (initialProps.fatalError) {
    return (
      <Router history={history}>
        <InlineError homeLink={basename}/>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <App store={store} initialProps={initialProps} language={window._LANG_} ssr={false}/>
    </Router>
  );
};

loadableReady().then(() => {
  hydrate(
    <BaseApp/>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
