import { createSelector, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { RootState } from '@@src/store';
import OnDemand2 from '@@types/OnDemand2';

export interface RecommendationData {
  recommendationId: string;
  recommendationVariantName: string;
  target: {
    entityType: OnDemand2.EntityTypes;
    id: string;
  };
}

export interface RecommendationStoreState {
  data: RecommendationData | undefined;
}

const initialState: RecommendationStoreState = {
  data: undefined,
};

const recommendationStore = createSlice({
  name: 'RecommendationStore',
  initialState,
  reducers: {
    setRecommendation(state, action) {
      state.data = { ...action.payload };
    },
    clearRecommendation(state) {
      state.data = undefined;
    },
    clearMatchingRecommendation(state, action) {
      const targetId = action?.payload?.targetId;
      if (targetId === state.data?.target?.id) {
        state.data = undefined;
      }
    },
  },
});

export const getRecommendation = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'recommendation');
  },
  (recommendation) => {
    return get(recommendation, 'data', null);
  },
);

export default recommendationStore;
export const { setRecommendation, clearRecommendation, clearMatchingRecommendation } = recommendationStore.actions;
