import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { ReactComponent } from './chevron-right.svg';

const ChevronRight: OverridableComponent<SvgIconTypeMap> = (props) => {
  return <SvgIcon component={ReactComponent} {...props}/>;
};

export default ChevronRight;
