import ProgressApi from '../apis/ProgressApi';

export function removeFromContinueWatching(videoId: string): Promise<boolean> {
  return ProgressApi.removeFromContinueWatching(videoId);
}

export function record(videoId: string, position: number): Promise<boolean> {
  return ProgressApi.record(videoId, Math.round(position));
}

export interface Progresses {
  [key: string]: {
    seconds: number;
    percent: number;
    completed: boolean;
  };
}

let getAllPromise: Promise<Progresses> | undefined;

export function getAll(): Promise<Progresses> {
  if (!(getAllPromise instanceof Promise)) {
    getAllPromise = ProgressApi.getAll();
  }

  return getAllPromise.finally(() => { getAllPromise = undefined; });
}

export default {
  removeFromContinueWatching,
  record,
  getAll,
};
