import { FunctionComponent } from 'react';
import LazyLoad from 'react-lazyload';

import TileSlider from '@@src/components/Carousel/TileSlider';
import { ShelfRow } from '@@src/components/PageRows/PageRow';
import ShelfShell from '@@src/components/Shelf/ShelfShell';
import { PAGESIZE_CAROUSEL_SHELF } from '@@src/services/PageService';

import PlaceholderShelf from './PlaceholderShelf';

interface CarouselShelfProps {
  row: ShelfRow;
  rowIndex: number;
}

const CarouselShelf: FunctionComponent<CarouselShelfProps> = (props) => {
  const {
    row,
    rowIndex,
  } = props;

  const shelfShell = (
    <ShelfShell
      lazyloadImages={false}
      row={row}
      rowIndex={rowIndex}
      pageSize={PAGESIZE_CAROUSEL_SHELF}
      renderShelfItems={
        (renderProps) => {
          return (
            <TileSlider
              tiles={renderProps.tiles}
              tileSpec={renderProps.tileSpec}
              hasMoreItems={renderProps.hasMoreItems}
              hasActiveState={renderProps.itemType !== 'collection'}
            />
          );
        }
      }
    />
  );

  // If the API has returned an empty row then don't render it
  if ('items' in row && row.items.length === 0) {
    return null;
  }

  // Render the first 5 rows directly and lazyload the remaining
  return rowIndex < 5 && ('items' in row && row.items)
    ? shelfShell
    : (
      <LazyLoad
        offset={800}
        placeholder={<PlaceholderShelf collectionTitle={row.name} collectionDisplayType="16:9"/>}
        unmountIfInvisible
        // if the row items is to be loaded asynchonously, then only lazyload it once
        // or else it will keep disappearing/reappearing instead of showing the placeholder
        once={'feedUrl' in row}
      >
        {shelfShell}
      </LazyLoad>
    );
};

export default CarouselShelf;
