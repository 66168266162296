import {
  AppBar, Box, Drawer, Hidden, IconButton, Toolbar, Typography,
} from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { getSetting, switchOff, switchOn } from '@@stores/SettingsStore';
import { getIsLoggedIn, getIsReady, getUserName } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';

import logoImage from '../../../static/images/logo.png';
import { RtlThemeProvider } from '../../ProviderWrapper';
import { isRtl } from '../../i18n';
import { ReactComponent as AccountIcon } from '../../images/icons/account.svg';
import { ReactComponent as MoreMenuIcon } from '../../images/icons/more-menu.svg';
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { getRouteFromName } from '../../routes';
import { getMenuItems, MenuItemKey } from '../../services/MenuService';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import fontFamily from '../../styles/typography/fontFamily';
import SkipToContentButton from '../Buttons/SkipToContentButton';
import Button from '../Inputs/Button';
import { dispatchOpenCreateAccount, dispatchOpenSignIn } from '../Login/LoginForm';
import SearchBar from '../Search/SearchBar';
import DimmedOverlay from '../Utils/DimmedOverlay';
import OdContainer from '../Utils/OdContainer';
import MobileMenuDrawer from './MobileMenuDrawer';
import MoreMenu from './MoreMenu';

const menuHeight = 60;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 1000,
      fontSize: '1rem',
      [theme.breakpoints.only('md')]: {
        fontSize: '0.9375rem',
        '[lang="vi"] &': {
          fontSize: '0.875rem',
        },
        '[lang="ar"] &': {
          fontSize: '0.875rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.375rem',
      },
      '& + *': {
        marginTop: -menuHeight,
      },
    },
    container: {
      width: '100%',
      [theme.breakpoints.only('md')]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(3),
      },
    },
    appBar: {
      boxShadow: 'none',
      background: `linear-gradient(to bottom, ${alpha(common.black, 0.5)}, ${alpha(common.black, 0)})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }).concat(', background-color 0.3s'),
      '&:before': {
        content: '""',
        background: `${alpha(common.black, 0.9)} !important`,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'absolute',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
      },
      '@media (hover: hover)': {
        '&:hover:before': {
          opacity: 1,
        },
      },
      '&:focus-within:before': {
        opacity: 1,
      },
      '&$appBarShift': {
        background: 'black !important',
      },
      zIndex: 999,
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      minHeight: menuHeight,
    },
    menuButton: {},
    logoImg: {
      width: 202,
      maxWidth: '100%',
      [theme.breakpoints.only('md')]: {
        width: 160,
      },
    },
    logoContainer: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
    logoLink: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      height: 60,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      '&:focus-visible': {
        outlineOffset: '-3px',
      },
    },
    menuItemsContainer: {
      flexGrow: 1,
      display: 'flex',
      height: menuHeight,
      alignItems: 'center',
      marginRight: 40,
    },
    menuItem: {
      fontFamily: fontFamily.secondary,
      fontWeight: 500,
      opacity: 0.7,
      height: 'inherit',
      paddingLeft: 16,
      paddingRight: 16,
      flexShrink: 0,
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        paddingLeft: 12,
        paddingRight: 12,
      },
      '&:hover, &$menuItemActive, &:focus': {
        opacity: 1,
      },
      '&::after': {
        display: 'block',
        content: 'attr(data-title)',
        height: 0,
        color: 'transparent',
        overflow: 'hidden',
        visibility: 'hidden',
      },
      '& div': {
        display: 'flex',
        height: 'inherit',
        alignItems: 'center',
      },
      '&[role=button]': {
        cursor: 'pointer',
      },
      '&:focus-visible': {
        outlineOffset: '-3px',
      },
    },
    menuItemActive: {
      fontWeight: 700,
      pointerEvents: 'none',
      cursor: 'default',
    },
    drawer: {
      color: 'grey',
      flexShrink: 0,
      position: 'absolute',
      zIndex: 1100,
    },
    mobileSearchDrawer: {
      zIndex: 1200,
      '& $drawerPaper': {
        background: grey.codgrey,
        height: 'auto',
        width: '100%',
        overflow: 'visible',
        paddingLeft: 0,
        paddingRight: 0,
        borderLeft: 0,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
    drawerPaper: {
      borderRight: 'none',
      background: grey.darkBlackPearl,
      [theme.breakpoints.down('sm')]: {
        width: 400,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
      minHeight: menuHeight,
    },
    searchDrawerHeader: {
      padding: 0,
    },
    mobileSearchCloseButton: {
      position: 'absolute',
      backgroundColor: grey.black,
      borderRadius: 0,
      padding: 18,
      '&:hover': {
        backgroundColor: grey.black,
      },
    },
    searchBar: {
      flex: '0 1 auto',
      flexBasis: 300,
      [theme.breakpoints.only('md')]: {
        flexBasis: 200,
      },
    },
    user: {
      fontFamily: fontFamily.roboto,
      marginLeft: 32,
      maxWidth: 92,
      maxHeight: 45,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // The below CSS is to allow multi-line ellipsis
      // Tested on Chrome, Safari & FF Mac.
      // Also working on Opera 78
      // https://caniuse.com/?search=line-clamp
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    accountIcon: {
      width: 32,
      height: 32,
      [theme.breakpoints.down('sm')]: {
        width: 48,
        height: 48,
      },
    },
    iconContainer: {
      position: 'relative',
      width: 24,
      height: 24,
      [theme.breakpoints.up('md')]: {
        width: 32,
        height: 32,
      },
    },
    moreMenuButton: {
      marginLeft: theme.spacing(1),
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    moreMenuAnchor: {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    menuIconInActive: {
      opacity: 0.6,
    },
    createAccountButton: {
      // reduced padding to save space
      paddingLeft: 14,
      paddingRight: 14,
    },
  });
});

const mobileSearchBarStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: alpha(common.black, 0.4),
      flexGrow: 1,
      border: 0,
      '&:hover input::placeholder, &:focus-within input::placeholder': {
        opacity: '1 !important',
      },
      '& input': {
        paddingRight: '65px !important',
      },
      '& input, & button': {
        background: 'transparent',
      },
      '&:hover input, &:hover button, &:focus-within input, &:focus-within button': {
        color: grey.fiord,
      },
      '&:focus-within input:active': {
        color: grey.black,
      },
    },
    searchInput: {
      height: menuHeight,
      '&:hover, &:active, &$searchInputActive': {
        background: 'transparent',
        color: 'inherit',
      },
    },
    searchInputActive: {
      background: 'transparent',
    },
    suggestionsContainerOpen: {
      marginTop: 0,
    },
    searchButton: {
      marginRight: -12,
    },
  });
});

const MainMenu: FunctionComponent = (props) => {
  const classes = useStyles(props);
  const mobileSearchBarClasses = mobileSearchBarStyles(props);
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(getIsLoggedIn);
  const location = useLocation();
  const openMoreMenuRef = useRef(null);

  const userStateReady = useAppSelector(getIsReady);
  const displayName = useAppSelector(getUserName);

  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;

  let logo = (
    <Link to="/" className={classes.logoLink} aria-label="Visit SBS On Demand homepage">
      <img src={logoImage} alt="SBS On Demand" className={classes.logoImg}/>
    </Link>
  );

  // use the logo as h1 for home page
  if (location.pathname === '/') {
    logo = <Typography component="h1" className={classes.logoContainer}>{logo}</Typography>;
  } else {
    logo = <div className={classes.logoContainer}>{logo}</div>;
  }

  /* Desktop menu items */
  const menuItemKeys: MenuItemKey[] = ['home', 'tvShows', 'movies', 'liveTv', 'news', 'sport'];
  if (loggedIn) {
    menuItemKeys.push('favourites');
  }

  const [menuBackgroundColor, setMenuBackgroundColor] = React.useState(null);

  const setMenuBackgroundColorBasedOnScroll = () => {
    if (window.scrollY > 0) {
      // gradual opacity based on how much scroll
      const opacity = Math.min(1, window.scrollY / 300) * 0.9;
      setMenuBackgroundColor(`rgba(0, 0, 0, ${opacity})`);
    } else {
      setMenuBackgroundColor(null);
    }
  };

  useEffect(() => {
    setMenuBackgroundColorBasedOnScroll();

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', setMenuBackgroundColorBasedOnScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', setMenuBackgroundColorBasedOnScroll);
      }
    };
  });

  /* Mobile menu */
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);

  const handleOpenMobileMenu = useCallback(() => {
    setOpenMobileMenu(true);
  }, []);

  const handleCloseMobileMenu = useCallback(() => {
    setOpenMobileMenu(false);
  }, []);

  /**
   /* Mobile search */
  const openMobileSearch = useAppSelector((state) => {
    return getSetting(state, 'showMobileSearchBar');
  });

  const handleOpenMobileSearch = useCallback(async () => {
    await dispatch(switchOn('showMobileSearchBar'));
    const input = document.querySelector<HTMLInputElement>('#search-drawer input');
    if (input) {
      // There is a search bar animation when you open the mobile search,
      // showing the popular search (Autocomplete component) too early would render it too narrow.
      // This timeout gives time to the search bar to fully grow so that the Autocomplete pane gets the correct width.
      setTimeout(() => {
        input.focus();
      }, 250);
    }
  }, [dispatch]);

  const handleCloseMobileSearch = useCallback(() => {
    dispatch(switchOff('showMobileSearchBar'));
  }, [dispatch]);

  const mobileSearchButton = (
    <IconButton
      aria-label={t('searchBar.openMobileSearch')}
      onClick={handleOpenMobileSearch}
      classes={{ root: mobileSearchBarClasses.searchButton }}
    >
      <SearchIcon/>
    </IconButton>
  );

  const mobileSearchDrawer = (
    <Drawer
      className={clsx(classes.drawer, classes.mobileSearchDrawer)}
      variant="persistent"
      anchor="right"
      open={openMobileSearch}
      classes={{
        paper: `${classes.drawerPaper}`,
      }}
    >
      <div id="search-drawer" className={clsx(classes.drawerHeader, classes.searchDrawerHeader)}>
        <SearchBar classes={{ ...mobileSearchBarClasses }} iconPosition="left"/>
        <IconButton className={classes.mobileSearchCloseButton} onClick={handleCloseMobileSearch} color="inherit">
          <CloseIcon/>
        </IconButton>
      </div>
    </Drawer>
  );

  const moreMenuAnchorRef = React.useRef<HTMLDivElement>(null);

  const [openMoreMenu, setOpenMoreMenu] = React.useState<boolean>(false);

  const toggleOpenMoreMenu = useCallback((e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    openMoreMenuRef.current.setAttribute('aria-expanded', !openMoreMenu);
    setOpenMoreMenu(!openMoreMenu);
  }, [openMoreMenu]);

  const handleCloseMoreMenu = useCallback(() => {
    setOpenMoreMenu(false);
    openMoreMenuRef.current.focus();
  }, []);

  const handleListKeyDown = useCallback((e: React.KeyboardEvent) => {
    // close the menu on tab or escape
    if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
      handleCloseMoreMenu();
    }
  }, [handleCloseMoreMenu]);

  const moreMenuId = openMoreMenu ? 'more-menu-popper' : undefined;

  const menuItems = getMenuItems();

  const itemLinkClickHandler = useCallback(() => {
    // Removes focus from the menu item to prevent the menu bar to stay black after a click
    (document.activeElement as HTMLElement).blur();
  }, []);

  const signInClickHandler = useCallback(() => {
    dispatchOpenSignIn();
    DataLayer.events.clickSignInButton();
  }, []);

  const createAccountClickHandler = useCallback(() => {
    dispatchOpenCreateAccount();
    DataLayer.events.clickCreateAccountButton();
  }, []);

  return (
    <div className={classes.root} dir={isRtl(currentLanguage) ? 'rtl' : 'ltr'}>
      <AppBar
        color="default"
        position="static"
        className={clsx(classes.appBar, { [classes.appBarShift]: openMobileMenu })}
        style={{ backgroundColor: menuBackgroundColor }}
      >
        <SkipToContentButton/>
        <OdContainer className={classes.container}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                edge="start"
                className={clsx(classes.menuButton, openMobileMenu)}
                aria-label={t('menuItem.openMenu')}
                data-testid="mobile-menu-toggle"
                onClick={handleOpenMobileMenu}
              >
                <MenuIcon/>
              </IconButton>
            </Hidden>
            {logo}
            <Hidden smDown>
              <nav className={classes.menuItemsContainer}>
                {
                  menuItemKeys.map((menuItemKey) => {
                    const menuItem = menuItems[menuItemKey];
                    const route = getRouteFromName(menuItem.routeName);

                    return (
                      <Route key={menuItem.tKey} path={route.path} exact={route.exact}>
                        {
                          ({ match }) => {
                            return (
                              <Link
                                to={route.path}
                                className={`${classes.menuItem} ${match ? classes.menuItemActive : ''}`}
                                onClick={itemLinkClickHandler}
                                aria-current={match ? 'page' : false}
                              >
                                <div>{t(menuItem.tKey)}</div>
                              </Link>
                            );
                          }
                        }
                      </Route>
                    );
                  })
                }
              </nav>

              <SearchBar classes={{ root: classes.searchBar }}/>

              {userStateReady && !loggedIn && (
                <>
                  <Box ml={1}>
                    <Button
                      size="small"
                      aria-haspopup="dialog"
                      aria-controls="login-modal"
                      onClick={signInClickHandler}
                    >
                      {t('button.signIn')}
                    </Button>
                  </Box>
                  <Box ml={1}>
                    <Button
                      size="small"
                      buttonType="secondary"
                      aria-haspopup="dialog"
                      aria-controls="login-modal"
                      className={classes.createAccountButton}
                      onClick={createAccountClickHandler}
                    >
                      {t('button.createAccount')}
                    </Button>
                  </Box>
                </>
              )}
              {userStateReady && loggedIn && (
                <div
                  className={classes.user}
                  data-ui-name="username"
                >
                  {displayName}
                </div>
              )}
              {userStateReady && (
                <IconButton
                  ref={openMoreMenuRef}
                  aria-label={t('menuItem.openMoreMenu')}
                  type="button"
                  onClick={toggleOpenMoreMenu}
                  className={classes.moreMenuButton}
                  data-testid="more-menu-toggle"
                  aria-expanded={openMoreMenu}
                >
                  {loggedIn ? (
                    <AccountIcon
                      data-ui-name="profile-icon"
                      className={`${classes.accountIcon} ${!openMoreMenu ? classes.menuIconInActive : ''}`}
                    />
                  ) : (
                    <MoreMenuIcon className={`${!openMoreMenu ? classes.menuIconInActive : ''}`}/>
                  )}
                </IconButton>
              )}
            </Hidden>
            <Hidden mdUp>
              {mobileSearchButton}
            </Hidden>
          </Toolbar>
        </OdContainer>
        <div className={classes.moreMenuAnchor} ref={moreMenuAnchorRef}/>
        <MoreMenu
          id={moreMenuId}
          open={openMoreMenu}
          anchorEl={moreMenuAnchorRef.current}
          onClose={handleCloseMoreMenu}
          onListKeyDown={handleListKeyDown}
        />
      </AppBar>
      <MobileMenuDrawer open={openMobileMenu} onClose={handleCloseMobileMenu}/>
      {mobileSearchDrawer}
      {(openMoreMenu) && <DimmedOverlay/>}
    </div>
  );
};

const MainMenuWithRtlProvider = () => {
  return <RtlThemeProvider><MainMenu/></RtlThemeProvider>;
};

export default MainMenuWithRtlProvider;
