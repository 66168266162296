import clsx from 'clsx';
import { FunctionComponent, useCallback } from 'react';

import { onLinkKeyListener } from '@@utils/helpers';

import OnDemand from '../../@types/OnDemand';
import { OdLink } from '../../routes';
import Image, { ImageProps } from '../Html/Image';
import { useTileStyles } from './Tile';

type TallTileProps = OnDemand.Tile<OnDemand.Collection> & {
  imageProps: ImageProps;
  onClick?(e): void;
  tabable?: boolean;
};

const TallTile: FunctionComponent<TallTileProps> = (props) => {
  const {
    item,
    imageProps,
    onClick = () => {
      // do nothing
    },
    tabable = true,
  } = props;

  const classes = useTileStyles(props);

  const handleClick = useCallback((e) => {
    onClick(e);
  }, [onClick]);

  return (
    <div className={classes.root} data-testid="tile">
      <div className={classes.card}>
        <OdLink
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...item.route}
          onClick={handleClick}
          onKeyPress={onLinkKeyListener(handleClick)}
          tabIndex={tabable ? 0 : -1}
          aria-label={item.title}
          className={classes.tileLink}
        >
          <div className={clsx(classes.imageContainer, 'zoomable')}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Image {...imageProps}/>
          </div>
        </OdLink>
      </div>
    </div>
  );
};

export default TallTile;
