import { IconButton, Paper, TextField } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Autocomplete } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import { FunctionComponent, useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import DataLayer from '@@utils/DataLayer';

import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { OdRoute } from '../../routes';
import { Suggestion } from '../../services/SearchService';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import fontFamily from '../../styles/typography/fontFamily';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      border: `1px solid ${common.white}`,
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        '&:hover, &:focus-within': {
          backgroundColor: common.white,
        },
      },
    },
    searchForm: {
      position: 'relative',
    },
    inputBase: {
      fontSize: '1rem',
      '.MuiAutocomplete-inputRoot&': {
        paddingBottom: 0,
      },
    },
    searchInput: {
      width: '100%',
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
      height: 34,
      boxSizing: 'border-box',
      color: common.white,
      '&:focus::placeholder': {
        opacity: '1 !important',
        color: grey.fiord,
      },
      '&:hover, &:active, &$searchInputActive': {
        background: common.white,
        color: alpha(common.black, 0.6),
        borderRadius: theme.shape.borderRadius - 1,
      },
      '&:focus': {
        color: common.black,
        background: common.white,
        borderRadius: theme.shape.borderRadius - 1,
      },
      fontFamily: fontFamily.primary,
      '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child&': {
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        '&:focus:active, &$searchInputActive:focus': {
          color: grey.black,
        },
      },
    },
    searchInputActive: {},
    searchButton: {
      padding: '0 10px',
      borderRadius: 0,
      '&.Mui-disabled': {
        color: alpha(common.white, 0.5),
      },
      '&:hover': {
        backgroundColor: alpha(common.white, 0.3),
      },
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: 4,
        backgroundColor: alpha(common.white, 0.3),
      },
    },
    searchInputContainer: {
      display: 'flex',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: '1px',
      left: 0,
      right: 0,
      background: alpha(common.black, 0.8),
      fontSize: '1em',
      minWidth: 250,
      '.solidBg &': {
        backgroundColor: grey.codgrey,
        borderRadius: 3,
      },
      '& .MuiAutocomplete-option': {
        minHeight: 48,
        '&[data-focus="true"]': {
          outline: '3px solid transparent',
          outlineOffset: '-3px',
          borderRadius: 4,
        },
      },
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    suggestionsTitle: {
      minHeight: 48,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginTop: 8,
      lineHeight: 1.75,
      color: grey.chateau,
      display: 'flex',
      alignItems: 'center',
    },
    searchIcon: {
      width: 18,
      height: 18,
    },
  });
});

interface SearchInputProps {
  hasQuery: boolean;
  iconPosition: 'left' | 'right';
  suggestions: Suggestion[];
  inputPlaceholder: string;
  suggestionTitle: string;
  actionPath: string;
  searchRoute: OdRoute;
  onSuggestionSelected: (suggestionId: string) => void;
  onChange?: (newValue: string) => void;
  onSubmit?: (keywords: string) => void;
  classes?: any;
  suggestionsStyle?: 'transparent' | 'solid';
  searchKeywords?: string;
}

const SearchInput: FunctionComponent<SearchInputProps> = (props) => {
  const {
    suggestionTitle, onChange, hasQuery,
    iconPosition, suggestions, inputPlaceholder, searchRoute,
    suggestionsStyle = 'transparent', actionPath = '/search', searchKeywords = '',
    onSuggestionSelected, onSubmit,
  } = props;

  const { t } = useTranslation('common');
  const classes = useStyles(props);
  const formRef = React.createRef<HTMLFormElement>();
  const location = useLocation();
  const onSearchPage = matchPath(location.pathname, searchRoute);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();

    // Submit the form if we are not on the search page
    // if we are on the search page, the result will be automatically loaded
    if (!onSearchPage) {
      DataLayer.events.searchResult('directSearch', searchKeywords);
      if (onSubmit) {
        onSubmit(searchKeywords);
      }
    } else if (onChange) {
      // Submitting seems to cancel the onChange event further down, so we are calling it here.
      onChange(searchKeywords);
    }
  }, [onChange, onSearchPage, onSubmit, searchKeywords]);

  const searchIcon = (
    <IconButton
      aria-label={t('searchBar.search')}
      className={classes.searchButton}
      type="submit"
      disabled={!hasQuery}
      size="small"
    >
      <SearchIcon className={classes.searchIcon}/>
    </IconButton>
  );

  const PaperComponent = useCallback((paperProps) => {
    const { children, ...rest } = paperProps;
    return (
      <Paper
        {...rest}
        className={clsx(rest.className, classes.suggestionsContainerOpen)}
        elevation={0}
        square
      >
        {suggestions.length > 0 && (
          <div className={classes.suggestionsTitle}>
            {suggestionTitle}
            :
          </div>
        )}
        {children}
      </Paper>
    );
  }, [classes.suggestionsContainerOpen, classes.suggestionsTitle, suggestionTitle, suggestions.length]);

  return (
    <div className={clsx(classes.root, suggestionsStyle === 'solid' && 'solidBg')}>
      <form method="get" action={actionPath} className={classes.searchForm} ref={formRef} onSubmit={handleSubmit}>
        <Autocomplete
          freeSolo
          value=""
          handleHomeEndKeys
          openOnFocus
          inputValue={searchKeywords}
          options={suggestions}
          disableClearable
          getOptionLabel={(option) => {
            // Value selected with enter, from the input field
            if (typeof option === 'string') {
              return option;
            }

            // regular option
            return option.label;
          }}
          filterOptions={(options) => {
            return options;
          }}
          onInputChange={(_e, newInputValue, reason) => {
            if (reason !== 'reset') {
              if (onChange) {
                onChange(newInputValue);
              }
            }
          }}
          onChange={(_e, newValue, reason) => {
            if (reason === 'select-option') {
              if (typeof newValue === 'string') {
                // from input field
                onSuggestionSelected(newValue);
              } else {
                // regular option
                onSuggestionSelected(newValue.id);
              }
            }
          }}
          renderInput={(_params) => {
            const params = {
              ..._params,
              InputProps: {
                ..._params.InputProps,
                disableUnderline: true,
                classes: {
                  root: classes.inputBase,
                  input: `${classes.searchInput} ${hasQuery ? classes.searchInputActive : ''}`,
                },
                endAdornment: undefined,
              },
              inputProps: {
                ..._params.inputProps,
                'aria-label': t('searchBar.ariaLabel'),
              },
            };
            return (
              <div className={classes.searchInputContainer}>
                {iconPosition === 'left' && searchIcon}
                <TextField
                  {...params}
                  placeholder={inputPlaceholder}
                />
                {iconPosition === 'right' && searchIcon}
              </div>
            );
          }}
          PaperComponent={PaperComponent}
          renderOption={(option, state) => {
            const matches = match(option.label, state.inputValue);
            const parts = parse(option.label, matches);

            return (
              <div>
                {parts.map((part, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={`${part.text}:${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  );
                })}
              </div>
            );
          }}
        />
      </form>
    </div>
  );
};

export default SearchInput;
