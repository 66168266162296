import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';

import OnDemand2 from '@@src/@types/OnDemand2';
import { handleHttpError } from '@@utils/HttpClient';
import Logger from '@@utils/logger/Logger';

import { getPopularSearches, getSuggestions, search, Suggestion } from '../services/SearchService';
import type { RootState } from '../store';

type SearchResult = {
  title: string;
  items: OnDemand2.CollectionItem[];
  nextCursor: string;
  total: number;
  error?: string;
  layout: 'grid';
  displayType: '16:9';
};

export interface SearchStoreState {
  keywords: string;
  popularSearches: Suggestion[];
  suggestions: Suggestion[];
  isSearchInProgress: boolean;
  hasError: boolean,
  lastErrorMessage?: string,
  result?: {
    [key: string]: SearchResult;
  };
}

const initialState: SearchStoreState = {
  keywords: '',
  popularSearches: [],
  suggestions: [],
  isSearchInProgress: false,
  hasError: false,
  result: null,
};

interface LoadSuggestionsArgs {
  keywords: string
}
interface LoadSuggestionsThunkConfig {
  state: RootState
  rejectValue: string
}
export const loadSuggestionsAsyncThunk = createAsyncThunk<
Suggestion[],
LoadSuggestionsArgs,
LoadSuggestionsThunkConfig
>('SearchStore/getSuggestions', async (args: LoadSuggestionsArgs, { rejectWithValue }) => {
  try {
    return (await getSuggestions(args.keywords)) as Suggestion[];
  } catch (e) {
    Logger.error('getSuggestions received an error', {
      error: {
        message: e.response.data.error,
        status: e.response.status,
      },
    });
    return rejectWithValue(e.response.data.error as string);
  }
});

interface SearchResponse {
  keywords: string
  result: SearchResult
}

export const loadSearchResultAsyncThunk = createAsyncThunk<
SearchResponse,
LoadSuggestionsArgs,
LoadSuggestionsThunkConfig
>('SearchStore/loadSearchResult', async (args: LoadSuggestionsArgs, { rejectWithValue }) => {
  try {
    return (await search(args.keywords).then((result) => { return { result, keywords: args.keywords }; })) as SearchResponse;
  } catch (error) {
    handleHttpError(
      error,
      new Error('Error on SearchService.search()'),
      {},
      [404],
      null,
      'warn',
    );
    return rejectWithValue(error.response.data.error as string);
  }
});

export const loadMoreSearchResultAsyncThunk = createAsyncThunk<
SearchResponse,
LoadSuggestionsArgs,
LoadSuggestionsThunkConfig
>('SearchStore/loadMoreSearchResult', async (args: LoadSuggestionsArgs, { rejectWithValue, getState }) => {
  try {
    const { search: searchState } = getState();
    return (
      await search(args.keywords, searchState.result[args.keywords].nextCursor)
        .then((result) => {
          return {
            result: result || [],
            keywords: args.keywords,
          };
        })
    ) as SearchResponse;
  } catch (e) {
    Logger.error('loadMoreSearchResult received an error', {
      error: {
        message: e.response.data.error,
        status: e.response.status,
      },
    });
    return rejectWithValue(e.response.data.error as string);
  }
});

export const loadPopularSearchesAsyncThunk = createAsyncThunk<Suggestion[], undefined, LoadSuggestionsThunkConfig>('SearchStore/loadPopularSearches', async (
  _args,
  { rejectWithValue },
) => {
  try {
    return (await getPopularSearches()) as Suggestion[];
  } catch (e) {
    Logger.error('getPopularSearches received an error', {
      error: {
        message: e.response.data.error,
        status: e.response.status,
      },
    });
    return rejectWithValue(e.response.data.error as string);
  }
});

const searchStore = createSlice({
  name: 'SearchStore',
  initialState,
  reducers: {
    setKeywords(state, action) {
      state.keywords = action.payload;
    },
    setSuggestions(state, action) {
      state.suggestions = action.payload.filter((s: Suggestion) => {
        return s.label !== '';
      });
    },
    setError(state, action) {
      state.hasError = true;
      state.lastErrorMessage = action.payload.errorMessage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSuggestionsAsyncThunk.fulfilled, (state, action) => {
      state.suggestions = action.payload.filter((s: Suggestion) => {
        return s.label !== '';
      });
    });

    builder.addCase(loadSuggestionsAsyncThunk.rejected, (state, action) => {
      state.hasError = true;
      state.lastErrorMessage = action.payload;
    });

    builder.addCase(loadPopularSearchesAsyncThunk.fulfilled, (state, action) => {
      state.suggestions = action.payload.filter((s: Suggestion) => {
        return s.label !== '';
      });
      state.popularSearches = action.payload;
    });

    builder.addCase(loadPopularSearchesAsyncThunk.rejected, (state, action) => {
      state.hasError = true;
      state.lastErrorMessage = action.payload;
    });

    builder.addCase(loadSearchResultAsyncThunk.pending, (state, _action) => {
      state.isSearchInProgress = true;
    });

    builder.addCase(loadSearchResultAsyncThunk.fulfilled, (state, action) => {
      state.isSearchInProgress = false;
      state.hasError = false;
      state.result = {
        [action.payload.keywords]: action.payload.result as never,
      };
    });

    builder.addCase(loadSearchResultAsyncThunk.rejected, (state, action) => {
      state.hasError = true;
      state.isSearchInProgress = false;
      state.lastErrorMessage = action.payload;
    });

    builder.addCase(loadMoreSearchResultAsyncThunk.pending, (state, _action) => {
      state.isSearchInProgress = true;
    });

    builder.addCase(loadMoreSearchResultAsyncThunk.fulfilled, (state, action) => {
      state.isSearchInProgress = false;
      state.hasError = false;

      const { keywords, result } = action.payload;

      if (state.result[keywords]) {
        state.result[keywords].items = state.result[keywords].items.concat(result.items as never);
        state.result[keywords].nextCursor = result.nextCursor;
      }
    });

    builder.addCase(loadMoreSearchResultAsyncThunk.rejected, (state, action) => {
      state.isSearchInProgress = false;
      state.lastErrorMessage = action.payload;
    });
  },
});

export const getIsSearchInProgress = createSelector(
  (rootState: RootState) => {
    return rootState.search;
  },
  (state) => {
    return get(state, 'isSearchInProgress');
  },
);

export const getHasError = createSelector(
  (rootState: RootState) => {
    return rootState.search;
  },
  (state) => {
    return get(state, 'hasError');
  },
);

export const getLastErrorMessage = createSelector(
  (rootState: RootState) => {
    return rootState.search;
  },
  (state) => {
    return get(state, 'lastErrorMessage', null);
  },
);

export const getKeywords = createSelector(
  (rootState: RootState) => {
    return rootState.search;
  },
  (state) => {
    return state.keywords;
  },
);

export const getResult = createSelector(
  (rootState: RootState) => {
    return rootState.search;
  },
  (state) => {
    return state.result;
  },
);

export default searchStore;
export const { setKeywords, setSuggestions } = searchStore.actions;
