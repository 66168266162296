import SbsLogin from '@sbs/sbs-login';

let sbsLogin: SbsLogin;

let resolveGetSbsLogin;
let sbsLoginPromise;

export const createSbsLoginInstance = (options): SbsLogin => {
  sbsLogin = new SbsLogin(options);

  if (resolveGetSbsLogin) {
    resolveGetSbsLogin(sbsLogin);
  }

  return sbsLogin;
};

// this returns a promise and it will be resolved once sbsLogin instance is created
export const getSbsLoginInstance = (): Promise<SbsLogin> => {
  if (sbsLogin) {
    return Promise.resolve(sbsLogin);
  }

  // We need to make sure that there is only one promise in case this function is called multiple times
  if (!sbsLoginPromise) {
    sbsLoginPromise = new Promise<SbsLogin>((resolve) => {
      resolveGetSbsLogin = resolve;
    });
  }

  return sbsLoginPromise;
};
