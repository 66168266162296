import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha, darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';

import fontFamily from '../../styles/typography/fontFamily';
import LiveBadge from '../DataDisplay/Badge/LiveBadge';
import LiveCardMedia from '../Utils/LiveCardMedia';

const useStyles = makeStyles<Theme, LiveTvTileSecondaryProps>((theme: Theme) => {
  return createStyles({
    root: {
      width: 294,
      height: 410,
      backgroundColor: alpha(theme.palette.background.paper, 0.4),
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 536,
      },
      [theme.breakpoints.down('xs')]: {
        height: 370,
      },
      borderRadius: 4,
    },
    title: {
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      fontFamily: fontFamily.ubuntu,
    },
    status: {
      fontFamily: fontFamily.secondary,
      fontWeight: 500,
    },
    media: {
      position: 'relative',
      width: 294,
      height: 140,
      [theme.breakpoints.down('sm')]: {
        height: 194,
      },
      [theme.breakpoints.down('sm')]: {
        height: 160,
      },
      '.unavailable &': {
        opacity: 0.5,
      },
    },
    time: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 500,
      color: alpha(theme.palette.primary.main, 0.7),
    },
    muted: {
      opacity: 0.5,
    },
    metadata: {
      fontSize: '0.875rem',
    },
    description: {
      color: darken(theme.palette.primary.main, 0.12),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 6,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down('sm')]: {
        '-webkit-line-clamp': 11,
      },
      [theme.breakpoints.down('xs')]: {
        '-webkit-line-clamp': 3,
      },
    },
    classification: {},
  });
});

export interface LiveTvTileSecondaryProps {
  /** The title of the live stream. */
  title: string;
  /** The description of the live stream. */
  description: string;
  /** The url for the image. */
  imageUrl: string;
  badgeColor: 'warning' | 'error' | 'success';
  status: string | ReactElement;
  available: boolean;
  time: string;
  metadataComponent: ReactElement;
  progressPercent?: number;
  clickUrl?: string;
  playButtonLabel?: string;
}

const LiveTvTileSecondary: FunctionComponent<LiveTvTileSecondaryProps> = (props) => {
  const classes = useStyles(props);

  const {
    title,
    imageUrl,
    description,
    badgeColor,
    status,
    available,
    time,
    metadataComponent,
    progressPercent,
    clickUrl,
    playButtonLabel,
  } = props;

  return (
    <Card className={clsx(classes.root, !available && 'unavailable')} square>
      <LiveCardMedia
        title={title}
        imageUrl={imageUrl}
        progressPercent={progressPercent}
        clickUrl={available ? clickUrl : undefined}
        classes={{ media: classes.media }}
        playButtonLabel={playButtonLabel}
      />
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            {status && (
              <LiveBadge color={badgeColor} overlap="rectangular">
                <Box>
                  <Typography variant="body2" className={classes.status}>
                    {status}
                  </Typography>
                </Box>
              </LiveBadge>
            )}
          </Grid>
          <Grid item>
            <Box fontWeight={500}>
              <Typography
                variant="body2"
                component="div"
                className={clsx(classes.time, { [classes.muted]: !available })}
              >
                {time}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="column" className={clsx({ [classes.muted]: !available })}>
          <Box width="100%">
            <Grid item>
              <Box mt={2}>
                <Typography variant="body1" component="h3" className={classes.title}>
                  {title}
                </Typography>
              </Box>
              <Box mt={1} className={classes.metadata}>
                {metadataComponent}
              </Box>
            </Grid>
          </Box>
          <Grid item>
            <Box mt={1}>
              <Typography variant="body2" className={classes.description}>
                {description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LiveTvTileSecondary;
