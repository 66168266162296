import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import {
  FunctionComponent,
  createRef, useRef,
  useLayoutEffect,
  useCallback,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import OnDemand2 from '@@src/@types/OnDemand2';
import { getCurrentBreakpoint } from '@@styles/breakpoints';
import DataLayer from '@@utils/DataLayer';
import {
  tileItemsPerView16x9,
  tileItemsPerView2x3,
  getTileSpecV2,
} from '@@utils/helpers';

import Button from '../Inputs/Button';
import { sharedTileContainerLayoutStyles } from '../Shelf/CarouselShelfV2';
import CollectionItemTile from '../Tiles/TilesV2/CollectionItemTile';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gridContainer: {
      display: 'grid',
      flexDirection: 'row',
      width: '100%',
      gridColumnGap: theme.spacing(1),
    },
    'gridTileLayout2:3': {
      gridTemplateColumns: `repeat(${tileItemsPerView2x3.xl}, minmax(1px, 1fr))`,
      gridRowGap: theme.spacing(8),
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView2x3.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView2x3.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView2x3.sm}, minmax(1px, 1fr))`,
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView2x3.xs}, minmax(1px, 1fr))`,
      },
    },
    'gridTileLayout16:9': {
      gridTemplateColumns: `repeat(${tileItemsPerView16x9.xl}, minmax(1px, 1fr))`,
      gridRowGap: theme.spacing(8),
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView16x9.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView16x9.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView16x9.sm}, minmax(1px, 1fr))`,
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: `repeat(${tileItemsPerView16x9.xs}, minmax(1px, 1fr))`,
      },
    },
    link: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  });
});

export interface GridV2Props {
  collection: OnDemand2.Collection | OnDemand2.SearchResults;
  hasMoreItems?: boolean;
  loadMoreItems?: () => void;
  isLoadingMoreItems?: boolean;
  onClick?: () => void;
}

function getGridRowNumber(index, itemsPerRow) {
  return Math.floor(index / itemsPerRow);
}

export function getStartIndex(index, itemsPerRow) {
  const rowNumber = getGridRowNumber(index, itemsPerRow);

  return itemsPerRow * rowNumber;
}

export function getEndIndex(index, itemsPerRow) {
  const rowNumber = getGridRowNumber(index, itemsPerRow);

  return (itemsPerRow * (rowNumber + 1)) - 1;
}

const GridV2: FunctionComponent<GridV2Props> = (props) => {
  const {
    collection,
    hasMoreItems = false,
    loadMoreItems = () => {},
    isLoadingMoreItems = false,
    onClick = () => {},
  } = props;

  const classes = useStyles(props);
  const tileContainerClasses = sharedTileContainerLayoutStyles(props);
  const { displayType } = collection;

  const breakpoint = getCurrentBreakpoint();
  const { width: windowWidth } = useWindowSize();
  const tileSpec = getTileSpecV2(displayType, windowWidth, breakpoint);
  const {
    itemsPerView,
    growRatio,
  } = tileSpec;

  const gridTitle = 'title' in collection ? collection.title : null;
  const { t } = useTranslation('common');
  const itemRefs = useRef([]);

  // ensure that the first and last tiles in the view activate in the right direction
  useLayoutEffect(() => {
    itemRefs.current.forEach((itemRef, index) => {
      const activableTile = itemRef.current.querySelector('.activableTile') as HTMLDivElement;

      const startIndex = getStartIndex(index, itemsPerView);
      const endIndex = getEndIndex(index, itemsPerView);

      if (index === startIndex) {
        Object.assign(activableTile.style, { transformOrigin: 'left center' });
      } else if (index === endIndex) {
        Object.assign(activableTile.style, { transformOrigin: 'right center' });
      } else {
        Object.assign(activableTile.style, { transformOrigin: 'center center' });
      }
    });
  }, [collection.items.length, itemsPerView]);

  /**
   * Ensure that the tile being activated has a higher z-index than other tiles.
   * Ensure that the surrounding tiles move left/right as specified
   * @param index
   * @returns
   */
  const activate = useCallback((index) => {
    // no zoom in animation for small breakpoints
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return;
    }

    // start and end tile index in the current view's row
    const startIndex = getStartIndex(index, itemsPerView);
    const endIndex = getEndIndex(index, itemsPerView);

    for (let i = startIndex; i <= endIndex; i += 1) {
      const item = get(itemRefs, `current[${i}].current`);

      // items around the index
      let percentToMove = 0;
      if (i !== index) {
        if (index === startIndex) {
          // full movement to the right
          percentToMove = -(1 - growRatio) * 100;
        } else if (index === endIndex) {
          // full movement to the left
          percentToMove = (1 - growRatio) * 100;
        } else if (i < index) {
          // half movement to the left
          percentToMove = ((1 - growRatio) / 2) * 100;
        } else if (i > index) {
          // half movement to the right
          percentToMove = -((1 - growRatio) / 2) * 100;
        }
      }

      if (item) {
        Object.assign(item.style, {
          zIndex: 1,
          transform: `translate3d(${percentToMove}%, 0, 0)`,
        });
      }
    }
  }, [breakpoint, growRatio, itemsPerView]);

  /**
   * Move tiles back to original positions and scaling.
   * @param index
   */
  const deactivate = (index) => {
    itemRefs.current.forEach((currentItem) => {
      Object.assign(currentItem.current.style, {
        transform: 'translate3d(0, 0, 0)',
        zIndex: 0,
      });
    });

    Object.assign(itemRefs.current[index].current.firstChild.style, {
      transform: 'scale(1)',
    });
  };

  if (collection.items && collection.items.length > 0) {
    // create the refs
    const _itemRefs = [];
    collection.items.forEach(() => {
      const tileRef = createRef<HTMLDivElement>();
      _itemRefs.push(tileRef);
    });

    itemRefs.current = _itemRefs;

    let gridTileLayoutClass;
    if (displayType === '2:3') {
      gridTileLayoutClass = classes['gridTileLayout2:3'];
    } else if (displayType === '16:9') {
      gridTileLayoutClass = classes['gridTileLayout16:9'];
    }

    let tileContainerLayout;
    if (collection.displayType === '2:3') {
      tileContainerLayout = tileContainerClasses['tileContainerLayout2:3'];
    } else if (collection.displayType === '16:9') {
      tileContainerLayout = tileContainerClasses['tileContainerLayout16:9'];
    }

    return (
      <div>
        <div className={clsx(classes.gridContainer, gridTileLayoutClass)}>
          {
              collection.items.map((collectionItem, index) => {
                const itemRef = itemRefs.current[index];
                const shelfLocation = `grid:${collection.title}:1:${index + 1}`;

                const tileProps = {
                  ref: itemRef,
                  displayType,
                  className: tileContainerLayout,
                  classes: {
                    link: classes.link,
                  },
                  onActivate: () => {
                    activate(index);
                  },
                  onDeactivate: () => {
                    deactivate(index);
                  },
                  onClick() {
                    if (onClick) {
                      onClick();
                    }
                    DataLayer.setClickSource(gridTitle, 'grid', 1, index + 1);
                  },
                  onKeyPress(e: KeyboardEvent) {
                    if (e.key === 'Enter') {
                      DataLayer.setClickSource(gridTitle, 'grid', 1, index + 1);
                    }
                  },
                  onFavourited() {
                    DataLayer.events.addToFavouritesV2(collectionItem, shelfLocation);
                  },
                  onUnfavourited() {
                    DataLayer.events.removeFromFavouritesV2(collectionItem, shelfLocation);
                  },
                };

                return (
                  <Fragment key={collectionItem.id}>
                    <CollectionItemTile item={collectionItem} props={tileProps}/>
                  </Fragment>
                );
              })
            }
        </div>
        {
          hasMoreItems && (
            <Box mt={6} textAlign="center">
              <Button
                buttonType="secondary"
                onClick={() => {
                  loadMoreItems();
                }}
                disabled={isLoadingMoreItems}
              >
                {!isLoadingMoreItems && t('navigation.viewMore')}
                {isLoadingMoreItems && t('navigation.loading')}
              </Button>
            </Box>
          )
        }
      </div>
    );
  }

  return null;
};

export default GridV2;
