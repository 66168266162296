import { common } from '@material-ui/core/colors';
import { createTheme, SimplePaletteColorOptions, ThemeOptions } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import transitions from '@material-ui/core/styles/transitions';

import breakpoints from '../styles/breakpoints';
import blue from '../styles/colors/blue';
import grey from '../styles/colors/grey';
import orange from '../styles/colors/orange';
import fontFamily from '../styles/typography/fontFamily';

// A custom theme for this app
export function generateThemeOptions() {
  const themeOptions: ThemeOptions & {
    palette: {
      primary: SimplePaletteColorOptions
    }
  } = {
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        disableFocusRipple: true,
        disableElevation: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
    },
    breakpoints: {
      values: breakpoints,
    },
    palette: {
      type: 'dark',
      primary: {
        main: common.white,
        contrastText: common.black,
      },
      secondary: {
        main: orange.darkTangerine,
        contrastText: common.black,
        dark: orange.grandis,
      },
      text: {
        primary: common.white,
        secondary: orange.darkTangerine,
      },
      background: {
        paper: grey.blackpearl,
        default: grey.darkBlackPearl,
      },
      action: {
        disabled: alpha(common.white, 0.38),
        disabledBackground: alpha(common.white, 0.12),
      },
      warning: {
        main: orange.darkTangerine,
      },
      error: {
        main: '#FF3B2D',
      },
      success: {
        main: '#1fcf40',
      },
    },
    typography: {
      h1: {
        fontSize: '3.25rem',
        fontWeight: 700,
        letterSpacing: 'normal',
        // font family is in the themeOptions.overrides.MuiTypography
      },
      h2: {
        fontSize: '1.25rem',
        fontFamily: fontFamily.secondary,
        fontWeight: 700,
        letterSpacing: 'normal',
      },
      body1: {
        letterSpacing: 'normal',
      },
      body2: {
        lineHeight: 1.5,
        fontFamily: fontFamily.primary,
        letterSpacing: 'normal',
      },
      button: {
        fontSize: '1.125rem',
        textTransform: 'none',
        // font family in overrides.MuiButton.root
        fontWeight: 700,
      },
    },
  };

  themeOptions.overrides = {
    MuiButton: {
      root: {
        transition: transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
          duration: transitions.duration.short,
        }),
        padding: '5px 15px',
        // pick a large border radius to make rounded button
        borderRadius: '50rem',
        // keyboard focus style
        '&.Mui-focusVisible': {
          outline: `3px solid ${blue.navy} !important`,
          borderRadius: '50rem',
        },
        fontFamily: fontFamily.secondary,
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: '1rem',
        },
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: '1.375rem',
        },
      },
      iconSizeLarge: {
        '& > *:first-child': {
          fontSize: '1.5rem',
        },
      },
      outlined: {
        padding: '3px 15px',
        backgroundColor: alpha(common.black, 0.40),
        '&.Mui-disabled': {
          '&, &:hover': {
            color: themeOptions.palette.action.disabled,
            border: `2px solid ${themeOptions.palette.action.disabledBackground}`,
          },
        },
      },
      outlinedPrimary: {
        border: `2px solid ${themeOptions.palette.primary.main}`,
        '&:hover': {
          // clicking the button on touch devices
          border: `2px solid ${themeOptions.palette.primary.main}`,
          '@media (hover: hover)': {
            color: themeOptions.palette.primary.contrastText,
            backgroundColor: themeOptions.palette.primary.main,
          },
        },
      },
      outlinedSizeSmall: {
        padding: '5px 12px',
        border: `1px solid ${themeOptions.palette.primary.main}`,
        '&:hover': {
          border: `1px solid ${themeOptions.palette.primary.main}`,
        },
        '&.Mui-disabled': {
          '&, &:hover': {
            color: themeOptions.palette.action.disabled,
            border: `1px solid ${themeOptions.palette.action.disabledBackground}`,
          },
        },
      },
      outlinedSizeLarge: {
        padding: '7px 20px',
      },
      containedSizeSmall: {
        padding: '6px 12px',
      },
      containedSizeLarge: {
        padding: '9px 20px',
      },
      sizeSmall: {
        fontSize: '0.875rem',
      },
      sizeLarge: {
        fontSize: '1.125rem',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 52,
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: fontFamily.secondary,
      },
      body1: {
        fontFamily: fontFamily.primary,
      },
      button: {
        fontFamily: fontFamily.secondary,
      },
    },
    MuiBadge: {
      dot: {
        height: 10,
        minWidth: 10,
        borderRadius: 5,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: fontFamily.secondary,
      },
    },
  };

  return themeOptions;
}

const dark = createTheme(generateThemeOptions());
export default dark;
