import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

/**
 * Return the user's local time zone
 */
const useLocalTimeZone = () => {
  const defaultTz = 'Australia/Sydney';
  const [zone, setZone] = useState(defaultTz);

  useEffect(() => {
    const localZone = DateTime.local().zone;
    setZone(localZone.name);
  }, []);

  return zone;
};

export default useLocalTimeZone;
