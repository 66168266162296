import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DateTime, Duration } from 'luxon';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getShortLocaleCode } from '../../i18n';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      color: orange.darkTangerine,
    },
    tooltip: {
      backgroundColor: grey.white,
      color: grey.black,
      fontSize: '1rem',
    },
    tooltipArrow: {
      color: grey.white,
      fontSize: '0.5rem',
    },
  });
});

export interface ExpirationDateProps {
  date: string;
}

export const canBeDisplayed = (duration) => {
  return parseInt(duration.as('months'), 10) < 3 && parseInt(duration.as('seconds'), 10) > 0;
};

const ExpirationDate: FunctionComponent<ExpirationDateProps> = (props) => {
  const { date } = props;
  const { t, i18n: { language } } = useTranslation('common');
  const classes = useStyles({});

  const defaultTz = 'Australia/Sydney';
  const [zone, setZone] = useState(defaultTz);
  const dateTime = DateTime.fromISO(date).setZone(zone).setLocale(getShortLocaleCode(language, '-'));
  const duration = dateTime.diffNow();

  const formattedDate = dateTime.toLocaleString({
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });

  // update the zone if local zone offset is not the same as the default zone offset
  useEffect(() => {
    const localZone = DateTime.local().zone;
    const localFormattedDate = DateTime.fromISO(date).setZone(localZone.name).setLocale(getShortLocaleCode(language, '-')).toLocaleString(DateTime.DATE_FULL);

    if (formattedDate !== localFormattedDate) {
      setZone(localZone.name);
    }
  }, [language, date, formattedDate]);

  const expirationDateToWords = useCallback(() => {
    if (!date) {
      return null;
    }

    let timeLeft;
    if (duration.as('weeks') > 1 && duration.as('weeks') <= 4) {
      timeLeft = Duration.fromObject({ weeks: Math.floor(duration.as('weeks')) }).as('seconds');
    } else {
      timeLeft = duration.as('seconds');
    }

    return t('dateAndTime.expiresIn', {
      duration: timeLeft,
      formatParams: {
        duration: {
          units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'],
          largest: 1,
        },
      },
    });
  }, [t, date, duration]);

  if (!canBeDisplayed(duration)) {
    return null;
  }

  return (
    <Tooltip
      title={formattedDate}
      arrow
      placement="top"
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
    >
      <span className={classes.root}>
        { expirationDateToWords() }
      </span>
    </Tooltip>
  );
};

export default ExpirationDate;
