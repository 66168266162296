import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';

export const messageBannerStyles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontWeight: 'normal',
      fontSize: '1rem',
      backgroundColor: orange.darkTangerine,
      borderRadius: 4,
      margin: 8,
      color: grey.black,
      alignItems: 'center',
      position: 'fixed',
      zIndex: 100,
      width: 'calc(100% - 16px)',
      bottom: -230,
      left: 0,
      transition: 'bottom 250ms ease-in-out',
      '&.show': {
        bottom: 0,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'normal',
      },
    },
    notificationTexts: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        padding: '24px',
        alignItems: 'center',
      },
    },
    notificationTitleContainer: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        backgroundColor: orange.orange,
        padding: '20px 24px',
        borderRadius: '4px 4px 0 0',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '14px 20px',
      },
    },
    bannerIcon: {
      marginRight: 12,
    },
    bannerTitle: {
      display: 'flex',
      flexDirection: 'row',
      fontFamily: fontFamily.secondary,
      fontWeight: 'bold',
      fontSize: '1.125rem',
      marginRight: 24,
    },
    notificationDescription: {
      color: alpha(common.black, 0.9),
      opacity: 0.7,
      [theme.breakpoints.down('md')]: {
        display: 'block',
        padding: '16px 24px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: '16px 20px',
      },
    },
    notificationCta: {
      padding: 24,
      flexShrink: 0,
      '& a': {
        display: 'inline',
        color: alpha(common.black, 0.9),
        textDecoration: 'underline',
        '&:focus': {
          outline: `3px solid ${blue.navy}`,
        },
      },
      '& a:first-child': {
        marginRight: 40,
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        padding: '0 24px 30px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        padding: '0 20px 22px',
      },
    },
    actionButton: {
      position: 'absolute',
      alignSelf: 'center',
      right: 5,
      padding: 10,
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
      [theme.breakpoints.down('xs')]: {
        top: 2,
      },
    },
    closeButton: {
      '& svg': {
        width: '1.35em',
        height: '1.35em',
      },
      '& path.close_svg__circle': {
        display: 'none',
      },
      '& path.close_svg__x': {
        color: 'black',
        fill: 'black',
      },
    },
  });
};
const useStyles = makeStyles<Theme>(messageBannerStyles);

export interface MessageBannerProps {
  title: string;
  description: string;
  icon?: ReactNode;
  ctaLinks?: any;
  onClose?: (e?: Event) => void;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>
}

const MessageBanner: FunctionComponent<MessageBannerProps> = (props) => {
  const {
    title, description, ctaLinks = null,
    onClose = null, icon = null,
  } = props;
  const classes = useStyles(props);
  const [show, setShow] = useState(false);
  const { t } = useTranslation('common');

  const handleCloseClick = useCallback((event) => {
    // Will trigger CSS animation
    setShow(false);

    // Allow CSS animation to complete
    setTimeout(() => {
      if (onClose) {
        onClose(event);
      }
    }, 350);
  }, [onClose]);

  // Starts the show animation
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <div
      className={clsx(classes.root, { show })}
      data-testid="notification-banner"
    >
      <div className={classes.notificationTexts}>
        <div className={classes.notificationTitleContainer}>
          <div className={classes.bannerTitle}>
            {icon && <span className={classes.bannerIcon}>{icon}</span>}
            {title}
          </div>
          <IconButton
            title="Close message"
            aria-label={t('messageBanner.close')}
            className={clsx(classes.actionButton, classes.closeButton)}
            data-testid="bannerCloseBtn"
            onClick={handleCloseClick}
            tabIndex={0}
          >
            <CloseIcon/>
          </IconButton>
        </div>
        <div className={classes.notificationDescription}>{description}</div>
      </div>
      {ctaLinks && (
        <div className={classes.notificationCta}>
          {ctaLinks}
        </div>
      )}
    </div>
  );
};

export default MessageBanner;
