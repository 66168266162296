import { useEffect, useState } from 'react';

import breakpoints from '@@styles/breakpoints';

/**
 * Calculates progressive opacity relative to the page vertical scroll
 * @param minOpacity lowest opacity to be returned
 * @param minOpacityThreshold how far, in pixels, the page needs to be scrolled down to reach the minOpacity
 */
const useGetOpacityFromVerticalScroll = (minOpacity: number = 0, minOpacityThreshold: number = 800) => {
  const [opacity, setOpacity] = useState<number>(1);
  const [effectiveThreshold, setEffectiveThreshold] = useState<number>(minOpacityThreshold);

  // Setting initial effective threshold
  useEffect(() => {
    if (window.innerWidth < breakpoints.sm) {
      setEffectiveThreshold(minOpacityThreshold / 2);
    }
  }, [minOpacityThreshold]);

  // Handling resize and scroll
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < breakpoints.sm) {
        setEffectiveThreshold(minOpacityThreshold / 2);
      } else {
        setEffectiveThreshold(minOpacityThreshold);
      }
    };
    window.addEventListener('resize', handleWindowResize);

    const handleScroll = () => {
      setOpacity(Math.max(minOpacity, 1 - window.scrollY / effectiveThreshold));
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [minOpacityThreshold, minOpacity, setOpacity, effectiveThreshold]);

  return opacity;
};

export default useGetOpacityFromVerticalScroll;
