import { Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Episodes from '@@icons/Episodes';
import Announcement from '@@src/components/Content/Announcement';
import { durationShortFormatParams } from '@@src/i18n';
import DataLayer from '@@utils/DataLayer';

import OnDemand from '../../@types/OnDemand';
import { generatePathFromLinkProps, OdLink } from '../../routes';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import Button from '../Inputs/Button';
import ExpirationDate, { canBeDisplayed } from '../Utils/ExpirationDate';
import VideoPlayWidget from '../Video/VideoPlayWidget';
import ContentSummary, { ContentSummaryCtaButton } from './ContentSummary';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tileBody: {
      fontSize: '1.125rem',
      lineHeight: 1.56,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      marginBottom: theme.spacing(3),
      flex: 'none',
    },
    playWidget: {
      marginBottom: 18,
    },
    liveStatus: {
      fontFamily: fontFamily.secondary,
      fontSize: '4.5rem',
      lineHeight: 1,
      fontWeight: 'bold',
      color: orange.darkTangerine,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.125rem',
      },
    },
    seriesTitle: {
      fontSize: '2.5rem',
      lineHeight: 1,
      marginBottom: theme.spacing(1),
      '& a:hover': {
        color: orange.darkTangerine,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.375rem',
      },
    },
  });
});

export interface VideoSummaryProps {
  video: OnDemand.Video;
}

const VideoSummary: FunctionComponent<VideoSummaryProps> = (props) => {
  const { video } = props;
  const classes = useStyles({});
  const { t } = useTranslation(['page', 'common']);

  const allEpisodesClick = useCallback(() => {
    DataLayer.setClickSource('All Episodes', 'button', 1, 1);
  }, []);

  const buildMetadata = useCallback(() => {
    const bottomMetadataList = [];
    let topMetadata: any = {
      list: [],
      badges: {
        classification: video.classification,
        hasClosedCaption: video.hasClosedCaption,
        isHighDefinition: video.isHighDefinition,
        hasAudioDescription: video.hasAudioDescription,
      },
    };

    const { available } = video;

    if (!video.isLiveStream) {
      topMetadata = {
        ...topMetadata,
        list: [
          {
            id: 'duration',
            value: t('common:video.duration', {
              duration: video.duration,
              formatParams: {
                duration: durationShortFormatParams,
              },
            }),
          },
          { id: 'publicationYear', value: video.publicationYear },
        ],
      };

      if (video.languages.length > 0) {
        topMetadata.list.push({ id: 'language', value: video.languages[0] });
      }

      if (available) {
        const dateTime = DateTime.fromISO(video.expiredDate);
        const duration = dateTime.diffNow();
        if (canBeDisplayed(duration)) {
          topMetadata.list.push({ id: 'expirationDate', value: <ExpirationDate date={video.expiredDate}/> });
        }
      }

      if (!video.isLiveStream && video.languages.length > 1) {
        bottomMetadataList.push({ languages: video.languages });
      }
    }

    return {
      topMetadata,
      bottomMetadataList,
    };
  }, [t, video]);

  const { topMetadata, bottomMetadataList } = buildMetadata();

  // show add to favourite if video is not a live stream or episode
  const showAddToFavourite = !video.isLiveStream && video.type !== 'Episode';

  const ctaButtons: ContentSummaryCtaButton[] = video.type === 'Episode'
    ? [{
      key: 'allEpisodesButton',
      component: (
        <Box ml={-1}>
          <Button
            buttonType="text"
            onClick={allEpisodesClick}
            size="large"
            startIcon={<Episodes/>}
            to={generatePathFromLinkProps(video.episodeData.seriesRoute)}
          >
            {t('detailsPage.allEpisodes')}
          </Button>
        </Box>
      ),
    }]
    : [];

  const getTitle = useCallback(() => {
    let { title }: { title: any } = video;

    if (video.type === 'Episode') {
      const seriesTitle = video.episodeData.programName;

      const seasonTitle = t('page:detailsPage.seasonTitle', {
        seasonNumber: video.episodeData.seasonNumber,
      });

      const episodeTitle = t('page:detailsPage.episodeTitle', {
        episodeNumber: video.episodeData.episodeNumber,
      });

      const seasonEpisodeTitle = `: ${seasonTitle} ${episodeTitle}`;

      title = (
        <div>
          <div className={classes.seriesTitle}>
            <span>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <OdLink {...video.episodeData.seriesRoute}>{seriesTitle}</OdLink>
            </span>
            {
                seasonEpisodeTitle
              && (
                <span>{seasonEpisodeTitle}</span>
              )
            }
          </div>
          <div>{title}</div>
        </div>
      );
    }

    return title;
  }, [t, classes.seriesTitle, video]);

  const title = getTitle();

  const subtitles = get(video, 'subtitles');
  const captions = get(video, 'closedCaptions');

  return (
    <div>
      <ContentSummary
        item={video}
        title={title}
        topMetadata={topMetadata}
        bottomMetadata={[...bottomMetadataList,
          {
            subtitles: [
              ...(captions ? video.closedCaptions.map((caption) => { return `${caption} (CC)`; }) : []),
              ...(subtitles || []),
            ],
          },
          ...(!video.isLiveStream ? [{ countries: video.countries }, { directors: video.directors }, { cast: video.cast }] : []),
          { advice: video.consumerAdviceTexts },
        ]}
        showAddToFavourite={showAddToFavourite}
        ctaButtons={ctaButtons}
        sponsorship={video.sponsorship}
      >
        <Typography gutterBottom variant="body2" className={classes.tileBody}>
          {video.description}
        </Typography>
        {
          video.announcement && <Announcement announcement={video.announcement}/>
        }
        <VideoPlayWidget
          video={video}
          classes={{ root: classes.playWidget }}
        />
      </ContentSummary>
    </div>
  );
};

export default VideoSummary;
