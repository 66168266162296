import { get } from 'lodash';

import fetch from '../apis/FeedApi';

/**
 * Fetch items from the feedUrl and transform it to catalog items
 * @param feedUrl
 * @param transformFunction
 * @param requireLogin
 * @param rangeFrom
 * @param rangeTo
 */
export async function getItems(feedUrl, transformFunction, requireLogin = false, rangeFrom = 1, rangeTo = 10) {
  const data = await fetch(feedUrl, requireLogin, rangeFrom, rangeTo);
  const feedItems = get(data, 'itemListElement', []);
  const totalItems = get(data, 'totalNumberOfItems', 0);

  if (feedItems && feedItems.length) {
    const items = [];
    feedItems.forEach((item) => {
      const transformedItem = transformFunction(item);
      if (transformedItem) {
        items.push(transformedItem);
      }
    });
    return {
      items,
      totalItems,
    };
  }
  return {
    items: [],
    totalItems: 0,
  };
}

export default {
  getItems,
};
