import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { take } from 'lodash';

import { getSeriesBySlug } from '@@src/apis/CatalogueApi';
import OnDemand from '@@types/OnDemand';

import type { AppThunk } from '../store';

export interface SeriesStoreState {
  [key: string]: OnDemand.TvSeries;
}

const initialState: SeriesStoreState = {};

const seriesStore = createSlice({
  name: 'SeriesStore',
  initialState,
  reducers: {
    addSeries(state, action: PayloadAction<OnDemand.TvSeries>) {
      // remove some objects when we have more than 20 in the store to keep the store memory down
      if (Object.keys(state).length >= 20) {
        take(Object.keys(state), 11).forEach((key) => {
          delete state[key];
        });
      }

      state[action.payload.slug] = action.payload;
    },
  },
});

/**
 * Get a series
 * If it exists in the store, return it from the store
 * Otherwise, get it from the API and add it to the store
 * @param seriesType
 * @param slug
 * @param language
 */
export function getSeries(seriesType, slug, language): AppThunk<Promise<OnDemand.TvSeries | null>> {
  return (dispatch, getState) => {
    const state = getState();

    if (slug in state.series) {
      return Promise.resolve(state.series[slug]);
    }

    return getSeriesBySlug(seriesType, slug, language)
      .then((series) => {
        dispatch(seriesStore.actions.addSeries(series));
        return series;
      })
      .catch(() => {
        return null;
      });
  };
}

export default seriesStore;
