import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PlayIcon from '@@icons/Play';
import OdFavouriteButton from '@@src/components/Buttons/OdFavouriteButton';
import usePersonalisation from '@@src/hooks/usePersonalisation';
import DataLayer from '@@utils/DataLayer';

import OnDemand from '../../@types/OnDemand';
import { generatePathFromLinkProps, OdLink } from '../../routes';
import orange from '../../styles/colors/orange';
import fontFamily from '../../styles/typography/fontFamily';
import Sponsorship from '../Advert/Sponsorship';
import Image from '../Html/Image';
import Button from '../Inputs/Button';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        height: 700,
      },
      [theme.breakpoints.down('xs')]: {
        height: 365,
      },
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
    tagLine: {
      fontSize: '1.5rem',
      marginBottom: '-2px',
      flex: 'none',
      fontWeight: 500,
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      fontFamily: fontFamily.primary,
    },
    tileTitleContainer: {
      margin: '8px 0 20px',
      [theme.breakpoints.down('sm')]: {
        margin: '6px 0 16px',
      },
    },
    heroTitleLink: {
      margin: '0',
      padding: '0',
      display: 'inline',
      position: 'relative',
      zIndex: 2,
      transition: 'color 0.2s ease-in-out 0.2s, background-size .5s',
      '&.active': {
        color: 'black',
      },
      backgroundImage: `linear-gradient(${orange.darkTangerine}, ${orange.darkTangerine})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0 100%',
      '$tileTitleContainer:hover &': {
        [theme.breakpoints.up('md')]: {
          backgroundSize: '100% 100%',
          color: 'black',
        },
      },
    },
    tileTitle: {
      overflow: 'hidden',
      fontSize: '4.5rem',
      // specific line height required for the highlight animation on 2 lines or more
      lineHeight: '1.13',
      flex: 'none',
      position: 'relative',
      margin: '0',
      padding: '0',
      display: 'inline',
      [theme.breakpoints.down('lg')]: {
        fontSize: '3.75rem',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '3.25rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
      fontFamily: fontFamily.ubuntu,
    },
    tileBody: {
      fontSize: '1.125rem',
      lineHeight: 1.25,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        lineHeight: 1.5,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      marginBottom: 20,
      flex: 'none',
    },
    ctaBar: {
      marginTop: 8,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        '@media only screen and (max-height: 500px)': {
          display: 'none',
        },
      },
    },
    heroImage: {
      bottom: 0,
      [theme.breakpoints.down('md')]: {
        '&, & *': {
          height: '100%',
        },
        '& img': {
          objectFit: 'cover',
        },
      },
    },
    heroImageTag: {
      width: '100%',
      '& img': {
        [theme.breakpoints.down('sm')]: {
          height: 700,
        },
        [theme.breakpoints.down('xs')]: {
          height: 365,
        },
      },
    },
    heroImageVignetteBottom: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(to bottom, rgba(11, 13, 15, 0), rgba(11, 13, 15, 0.4) 80%, rgba(11, 13, 15, 1))',
      pointerEvents: 'none',
      [theme.breakpoints.down('xs')]: {
        height: '20%',
      },
    },
    heroImageVignetteLeft: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(40deg, rgba(0, 0, 0, 0.8) 29%, rgba(119, 119, 119, 0) 82%)',
      pointerEvents: 'none',
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    cardContent: {
      animation: '$fadeIn 1200ms ease-in-out',
      textAlign: 'left',
      backgroundColor: 'transparent',
      bottom: '21vw',
      left: '8%',
      maxWidth: 980,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginLeft: '0',
      position: 'absolute',
      color: 'white',
      padding: '0',
      [theme.breakpoints.down('md')]: {
        maxWidth: 850,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 600,
        bottom: 145,
        '@media only screen and (max-height: 720px)': {
          bottom: 'min(380px, calc(855px - min(670px, 100vh)))',
        },
      },
      [theme.breakpoints.down('xs')]: {
        left: 0,
        paddingLeft: 30,
        bottom: 90,
      },
    },
    favouriteButton: {
      fontSize: 44,
    },
    playIcon: {
      marginRight: 8,
    },
  });
});

interface TileHeroProps extends OnDemand.Hero {
  rowIndex: number;
  tileIndex: number;
  onClick?: (e: Event) => void;
  clickSource?: string;
  shelfLocation?: string;
}

/**
 * note this component is encapsulated to prevent passing incorrect types to usePersonalisation
 */
const TileHeroFavButton = ({ video, shelfLocation, classes }: { video: OnDemand.Video | OnDemand.TvSeries, shelfLocation: string, classes: any }) => {
  const personalisation = usePersonalisation(video);

  const onAddFavourite = useCallback(() => {
    DataLayer.events.addToFavourites(video, shelfLocation, personalisation);
  }, [video, personalisation, shelfLocation]);

  const onRemoveFavourite = useCallback(() => {
    DataLayer.events.removeFromFavourites(video, shelfLocation, personalisation);
  }, [video, personalisation, shelfLocation]);

  return (
    <OdFavouriteButton
      item={video}
      classes={classes}
      onClickAdd={onAddFavourite}
      onClickRemove={onRemoveFavourite}
    />
  );
};

const TileHero: FunctionComponent<TileHeroProps> = (props) => {
  const {
    item,
    title,
    imageProps,
    tagLine,
    body,
    onClick,
    clickSource,
    shelfLocation,
    rowIndex,
    tileIndex,
  } = props;
  const { t, i18n: { language } } = useTranslation('common');
  const classes = useStyles({});
  const primaryButtonText = t('heroCarousel.play');
  let secondaryButtonText;

  switch (item.type) {
    case 'TVSeries':
      secondaryButtonText = t('heroCarousel.moreEpisodes');
      break;

    case 'Page':
    case 'Collection':
      secondaryButtonText = t('heroCarousel.explore');
      break;

    case 'Clip':
      // Do nothing
      break;

    default:
      secondaryButtonText = t('heroCarousel.moreInfo');
      break;
  }

  let videoId;

  if (item.type === 'TVSeries') {
    videoId = get(item, 'featuredVideo.video.id', get(item, 'featuredEpisode.id'));
  } else if (item.type === 'Movie' || item.type === 'Clip' || item.type === 'Episode' || item.type === 'OneOff') {
    videoId = item.id;
  }

  // For TVSeries, since we don't have data on the availability of the featured video, we will go to the watch page
  // and let it decide what to do next
  const showPlayButton = videoId && (item.type === 'TVSeries' || ('available' in item && item.available));

  const handleClick = useCallback((event) => {
    if (clickSource) {
      DataLayer.setClickSource('Hero', clickSource, rowIndex + 1, tileIndex + 1);
    }

    if (onClick) {
      onClick(event);
    }
  }, [clickSource, onClick, rowIndex, tileIndex]);

  let itemRoute = item.route;
  if (item.type === 'Clip') {
    itemRoute = {
      name: 'watch',
      params: {
        id: videoId,
      },
    };
  }

  const hasSponsorship = 'sponsorship' in item && item.sponsorship;

  return (
    <Card className={classes.card} data-testid="tileHero">
      <div className={classes.heroImage}>
        <OdLink
          {...itemRoute}
          className="block"
          onClick={handleClick}
          onKeyPress={handleClick}
          aria-label={title}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Image classes={{ image: classes.heroImageTag }} {...imageProps}/>
        </OdLink>
        <div className={classes.heroImageVignetteLeft}/>
        <div className={classes.heroImageVignetteBottom}/>
      </div>
      <CardContent className={classes.cardContent}>
        {hasSponsorship && (
          <Sponsorship
            type="inline"
            sponsorship={item.sponsorship}
          />
        )}
        {!hasSponsorship && (
          <Typography gutterBottom variant="body1" className={classes.tagLine}>
            {tagLine}
          </Typography>
        )}

        <div className={classes.tileTitleContainer}>
          <OdLink
            {...itemRoute}
            tabIndex={-1}
            onClick={handleClick}
            onKeyPress={handleClick}
          >
            <Typography component="div" variant="h1" className={`prefers-reduced-motion ${classes.heroTitleLink} ${classes.tileTitle}`}>
              {title}
            </Typography>
          </OdLink>
        </div>

        <Typography gutterBottom variant="body2" className={classes.tileBody}>
          {body}
        </Typography>

        <Grid container spacing={1} className={classes.ctaBar} alignItems="center">
          {
            showPlayButton
            && (
              <Grid item>
                <Button
                  startIcon={<PlayIcon/>}
                  onClick={handleClick}
                  to={generatePathFromLinkProps({
                    name: 'watch',
                    params: {
                      id: videoId,
                    },
                  })}
                >
                  {primaryButtonText}
                </Button>
              </Grid>
            )
          }

          {secondaryButtonText && (
            <Grid item>
              <Button buttonType="secondary" onClick={handleClick} to={generatePathFromLinkProps(item.route)}>
                { secondaryButtonText }
              </Button>
            </Grid>
          )}
          {
            (!('isLiveStream' in item) || !item.isLiveStream)
            && item.type !== 'Page'
            && item.type !== 'Collection'
            && item.type !== 'Clip'
            && item.type !== 'Episode'
            && (
              <Grid item>
                <TileHeroFavButton
                  video={item as OnDemand.Video | OnDemand.TvSeries}
                  classes={{ root: classes.favouriteButton }}
                  shelfLocation={shelfLocation}
                />
              </Grid>
            )
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TileHero;
