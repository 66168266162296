import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, indexOf, pull, uniq } from 'lodash';

import FavouriteApi from '@@src/apis/FavouritesApi';
import { RootState } from '@@src/store';
import OnDemand from '@@types/OnDemand';
import OnDemand2 from '@@types/OnDemand2';

interface SnackPack {
  type: string;
  key: string;
}

export interface FavouritesStoreState {
  videos: string[];
  series: string[];
  snackPack: SnackPack[];
  loading: boolean;
}

const initialState: FavouritesStoreState = {
  videos: [],
  series: [],
  snackPack: [],
  loading: false,
};

interface Args {
  entityId: string;
}

interface ThunkConfig {
  state: RootState
  rejectValue: string // reject entityId to add/remove from store on failed requests
}

export const addFavouriteMovieAsyncThunk = createAsyncThunk<string, Args, ThunkConfig>('favourites/addMovie', async (args, thunkConfig) => {
  try {
    await FavouriteApi.add('movie', args.entityId);
    return args.entityId;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

export const addFavouriteProgramAsyncThunk = createAsyncThunk<string, Args, ThunkConfig>('favourites/addProgram', async (args, thunkConfig) => {
  try {
    await FavouriteApi.add('program', args.entityId);
    return args.entityId;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

export const removeFavouriteMovieAsyncThunk = createAsyncThunk<string, Args, ThunkConfig>('favourites/removeMovie', async (args, thunkConfig) => {
  try {
    await FavouriteApi.remove('movie', args.entityId);
    return args.entityId;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

export const removeFavouriteProgramAsyncThunk = createAsyncThunk<string, Args, ThunkConfig>('favourites/removeProgram', async (args, thunkConfig) => {
  try {
    await FavouriteApi.remove('program', args.entityId);
    return args.entityId;
  } catch (e) {
    return thunkConfig.rejectWithValue(args.entityId);
  }
});

export const listFavouritesAsyncThunk = createAsyncThunk<{ videos: string[], series: string[] }, undefined, ThunkConfig>('favourites/list', async (_args, thunkConfig) => {
  try {
    return await FavouriteApi.list();
  } catch (e) {
    return thunkConfig.rejectWithValue(undefined);
  }
});

const favouritesStore = createSlice({
  name: 'FavouritesStore',
  initialState,
  reducers: {
    addSnackBarToPack(state, action: PayloadAction<string>) {
      state.snackPack = [...state.snackPack, { type: action.payload, key: new Date().getTime().toString() }];
    },
    setSnackPack(state, action: PayloadAction<SnackPack[]>) {
      state.snackPack = action.payload;
    },
    clear(state) {
      state.videos = [];
      state.series = [];
      state.snackPack = [];
    },
  },
  extraReducers: (builder) => {
    /**
     * list favourites
     */
    builder.addCase(listFavouritesAsyncThunk.pending, (state, _action) => {
      state.loading = true;
    });
    builder.addCase(listFavouritesAsyncThunk.fulfilled, (state, action) => {
      state.videos = uniq([...state.videos, ...action.payload.videos]);
      state.series = uniq([...state.series, ...action.payload.series]);
      state.loading = false;
    });
    builder.addCase(listFavouritesAsyncThunk.rejected, (state, _action) => {
      state.loading = false;
    });
    /**
     * add movie (video)
     */
    builder.addCase(addFavouriteMovieAsyncThunk.pending, (state, action) => {
      state.videos = uniq([...state.videos, action.meta.arg.entityId]);
    });
    builder.addCase(addFavouriteMovieAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'added', key: new Date().getTime().toString() }];
    });
    builder.addCase(addFavouriteMovieAsyncThunk.rejected, (state, action) => {
      state.videos = pull(state.videos, action.meta.arg.entityId);
    });
    /**
     * remove movie (video)
     */
    builder.addCase(removeFavouriteMovieAsyncThunk.pending, (state, action) => {
      state.videos = pull(state.videos, action.meta.arg.entityId);
    });
    builder.addCase(removeFavouriteMovieAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'removed', key: new Date().getTime().toString() }];
    });
    builder.addCase(removeFavouriteMovieAsyncThunk.rejected, (state, action) => {
      state.videos = uniq([...state.videos, action.meta.arg.entityId]);
    });
    /**
     * add program (series)
     */
    builder.addCase(addFavouriteProgramAsyncThunk.pending, (state, action) => {
      state.series = uniq([...state.series, action.meta.arg.entityId]);
    });
    builder.addCase(addFavouriteProgramAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'added', key: new Date().getTime().toString() }];
    });
    builder.addCase(addFavouriteProgramAsyncThunk.rejected, (state, action) => {
      state.series = pull(state.series, action.meta.arg.entityId);
    });
    /**
     * remove program (series)
     */
    builder.addCase(removeFavouriteProgramAsyncThunk.pending, (state, action) => {
      state.series = pull(state.series, action.meta.arg.entityId);
    });
    builder.addCase(removeFavouriteProgramAsyncThunk.fulfilled, (state, _action) => {
      state.snackPack = [...state.snackPack, { type: 'removed', key: new Date().getTime().toString() }];
    });
    builder.addCase(removeFavouriteProgramAsyncThunk.rejected, (state, action) => {
      state.series = uniq([...state.series, action.meta.arg.entityId]);
    });
  },
});

export default favouritesStore;
export const { setSnackPack, addSnackBarToPack, clear } = favouritesStore.actions;

export const allFavourites = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (favourites) => {
    return favourites;
  },
);

export const isInFavourite = createSelector(
  (rootState: RootState) => {
    return rootState.favourites;
  },
  (_favourites, content) => {
    return content;
  },
  (favourites, content: Partial<OnDemand.Video | OnDemand.TvSeries> | OnDemand2.CollectionItem) => {
    if (content.entityType === 'Series' || get(content, 'type') === 'TVSeries') {
      return indexOf(get(favourites, 'series', []), content.id) !== -1;
    }

    if (content.entityType === 'Episode') {
      return indexOf(get(favourites, 'series', []), get(content, 'seriesId')) !== -1;
    }

    if (get(content, 'type') === 'Episode') {
      return indexOf(get(favourites, 'series', []), get(content, 'episodeData.seriesId')) !== -1;
    }

    if (content.entityType === 'Movie' || content.entityType === 'Program') {
      return indexOf(get(favourites, 'videos', []), get(content, 'mpxMediaId')) !== -1;
    }

    if (get(content, 'type') === 'Movie' || get(content, 'type') === 'OneOff' || get(content, 'type') === 'Clip') {
      return indexOf(get(favourites, 'videos', []), content.id) !== -1;
    }

    return null;
  },
);
