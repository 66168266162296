import { get } from 'lodash';

import OnDemand2 from '@@src/@types/OnDemand2';
import CatalogApiEpisodeTransformer from '@@src/transformers/CatalogApiEpisodeTransformer';
import CatalogApiMovieOrTvProgramTransformer from '@@src/transformers/CatalogApiMovieOrTvProgramTransformer';
import CatalogApiSeriesTransformer from '@@src/transformers/CatalogApiSeriesTransformer';
import { paths } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import { createHttpClient, handleHttpError } from '@@utils/HttpClient';
import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';

import {
  transformRecommendedItems,
} from '../transformers/CatalogApiCollectionTransformer';

type Path = keyof paths;

export function moreLikeThis(uuid: string, imageRatio: '16:9' | '2:3'): Promise<OnDemand2.CollectionItem[]> {
  const path: Path = '/recs/you-may-like/{id}';
  type ReturnType = paths['/recs/you-may-like/{id}']['get']['responses']['200']['content']['application/json'];

  const url = path.replace('{id}', uuid);

  const httpClient = createHttpClient({
    baseURL: process.env.BVAR_CATALOGUE_API_HOST,
  });

  httpClient.interceptors.request.use(async (axiosConfig) => {
    const localConfig = { ...axiosConfig };
    const sbsLogin = await getSbsLoginInstance();
    return sbsLogin.getAccessToken()
      .then((accessToken) => {
        if (accessToken) {
          localConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return localConfig;
      });
  }, (error) => {
    return Promise.reject(error);
  });

  return httpClient.get<ReturnType>(url)
    .then(({ data: { recommendationID, recommendationVariant, recommendedItems } }) => {
      return transformRecommendedItems(recommendedItems, imageRatio, recommendationID, recommendationVariant);
    })
    .catch((e) => {
      return handleHttpError(e, new Error('Error on moreLikeThis'), {}, [404]);
    });
}

export function recommendedEndCard(uuid: string): Promise<OnDemand.Video> {
  const path: Path = '/recs/recommended-end-card/{id}';
  type ReturnType = paths['/recs/recommended-end-card/{id}']['get']['responses']['200']['content']['application/json'];

  const url = path.replace('{id}', uuid);

  const httpClient = createHttpClient({
    baseURL: process.env.BVAR_CATALOGUE_API_HOST,
  });

  httpClient.interceptors.request.use(async (axiosConfig) => {
    const localConfig = { ...axiosConfig };
    const sbsLogin = await getSbsLoginInstance();
    return sbsLogin.getAccessToken()
      .then((accessToken) => {
        if (accessToken) {
          localConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
        return localConfig;
      });
  }, (error) => {
    return Promise.reject(error);
  });

  return httpClient.get<ReturnType>(url)
    .then(({ data }) => {
      const recommendationId = get(data, 'recommendationID');
      const recommendationVariant = get(data, 'recommendationVariant');
      const firstRecommendation = get(data, 'recommendedItems[0]', null);
      let recommendedContent = null;

      if (firstRecommendation) {
        if (firstRecommendation.entityType === 'MOVIE' || firstRecommendation.entityType === 'TV_PROGRAM') {
          recommendedContent = CatalogApiMovieOrTvProgramTransformer.transform(firstRecommendation);
        }

        if (firstRecommendation.entityType === 'TV_SERIES') {
          const recommendedSeries = CatalogApiSeriesTransformer.transform(firstRecommendation);
          recommendedContent = ({
            ...CatalogApiEpisodeTransformer.transform({
              ...get(firstRecommendation, 'seasons.0.episodes.0'),

              // get these attributes from the series object as they are not available in the episode object
              countries: firstRecommendation.countries,
              casts: firstRecommendation.casts,
              crews: firstRecommendation.crews,
              qualities: firstRecommendation.qualities,

              // ibmsTitleID/pilatId is not returned in episode object of TvSeries response
              ibmsTitleID: '',
            }),
            // extra attributes needed for the Rec End Card
            numberOfSeasons: recommendedSeries.numberOfSeasons,
            languages: recommendedSeries.languages,
            // use the series key art instead of episode key art
            odImageId: recommendedSeries.odImageId,
          });
        }
      }

      if (!recommendedContent) {
        return null;
      }

      return {
        ...recommendedContent,
        recommendationId,
        recommendationVariant,
      };
    })
    .catch((e) => {
      return handleHttpError(e, new Error('Error on recommendedEndCard'), {}, [404]);
    });
}

export default {
  moreLikeThis,
  recommendedEndCard,
};
