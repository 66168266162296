import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LiveBadge from '@@src/components/DataDisplay/Badge/LiveBadge';
import AvailableDateTimeLabel from '@@src/components/Utils/AvailableDateTimeLabel';
import LiveInLabel from '@@src/components/Utils/LiveInLabel';
import { useStyles, VideoPlayWidgetProps } from '@@src/components/Video/VideoPlayWidget';
import { getShortLocaleCode } from '@@src/i18n';
import { ReactComponent as PlayIcon } from '@@src/images/icons/play-circle.svg';

const AvailableSoonVideoPlayWidget: FunctionComponent<VideoPlayWidgetProps> = (props) => {
  const {
    video,
  } = props;

  const classes = useStyles(props);
  const { t, i18n: { language } } = useTranslation('common');

  const defaultTz = 'Australia/Sydney';
  const [zone, setZone] = useState(defaultTz);
  const availableDateTime = DateTime.fromISO(video.availableDate).setZone(zone).setLocale(getShortLocaleCode(language, '-'));
  const formattedAvailableDate = availableDateTime.toLocaleString(DateTime.DATE_FULL);
  const [durationToAvailable, setDurationToAvailable] = useState(Math.floor(availableDateTime.diffNow().as('seconds')));

  useEffect(() => {
    if (durationToAvailable > 0) {
      const timeout = durationToAvailable > 3600 ? 60 : 1;
      setTimeout(() => {
        setDurationToAvailable(Math.floor(availableDateTime.diffNow().as('seconds')));
      }, timeout * 1000);
    }
  }, [video, durationToAvailable, availableDateTime]);

  // update the zone if local zone offset is not the same as the default zone offset
  useEffect(() => {
    const localZone = DateTime.local().zone;
    const localFormattedDate = DateTime.fromISO(video.availableDate)
      .setZone(localZone.name)
      .setLocale(getShortLocaleCode(language, '-'))
      .toLocaleString(DateTime.DATE_FULL);

    if (formattedAvailableDate !== localFormattedDate) {
      setZone(localZone.name);
    }
  }, [language, video, formattedAvailableDate]);

  let availabilityLabel;
  if (video.isLiveStream) {
    availabilityLabel = (
      <LiveInLabel
        durationToAvailable={durationToAvailable}
        shortUnits={durationToAvailable < 86340}
      />
    );
  } else {
    const shortUnits = durationToAvailable < 86340;
    availabilityLabel = t('video.availableInRemainingTime', {
      duration: durationToAvailable,
      formatParams: {
        duration: {
          units: ['mo', 'w', 'd', 'h', 'm', 's'],
          largest: durationToAvailable < 86400 && durationToAvailable > 3600 ? 2 : 1,
          delimiter: ' ',
          shortUnits,
          spacer: shortUnits ? '' : ' ',
        },
      },
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.playButtonContainer}>
        <Button
          className={clsx(classes.playButton)}
          disabled
          aria-disabled
          data-testid="video-play-widget-play-button"
          disableElevation
          disableRipple
          disableFocusRipple
        >
          <PlayIcon/>
        </Button>
      </div>
      <div className={classes.centeredContent}>
        <div className={classes.availabilityLabel}>{availabilityLabel}</div>
        <LiveBadge classes={{ root: classes.badge }} color="warning" overlap="rectangular">
          <Box>
            <div className={classes.watchLabel}>
              <AvailableDateTimeLabel start={video.availableDate}/>
            </div>
          </Box>
        </LiveBadge>
      </div>
    </div>
  );
};

export default AvailableSoonVideoPlayWidget;
