import { AxiosResponse } from 'axios';
import { get, transform } from 'lodash';

import { httpClientWithAuth } from '@@utils/HttpClient';
import { PROGRESS_GETALL_PATH, PROGRESS_RECORD_PATH, PROGRESS_REMOVE_FROM_CONTINUE_WATCHING_PATH } from '@@utils/constants';

import { Progresses } from '../services/ProgressService';

interface RemoveFavouriteResponse {
  removefromcontinuewatching: {
    response: {
      videoid: string;
      removed: boolean;
    };
    status: boolean;
  };
}

function removeFromContinueWatching(videoId: string) {
  const formData = new FormData();
  formData.append('videoid', videoId);

  return httpClientWithAuth.post(PROGRESS_REMOVE_FROM_CONTINUE_WATCHING_PATH, formData)
    .then((response: AxiosResponse<RemoveFavouriteResponse>) => {
      return get(response, 'data.removefromcontinuewatching.response.removed', false);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

interface RecordProgressResponse {
  record: {
    response: any;
    status: boolean;
  };
}

export function record(videoId: string, position: number): Promise<boolean> {
  const formData = new FormData();
  formData.append('videoid', videoId);
  formData.append('seconds', position.toFixed(2));

  return httpClientWithAuth.post(PROGRESS_RECORD_PATH, formData)
    .then((response: AxiosResponse<RecordProgressResponse>) => {
      return response.data.record.response;
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

interface GetAllProgressResponse {
  all: {
    response: {
      [key: string]: {
        seconds: number;
        percent: number;
        completed: 0 | 1;
      };
    };
    status: boolean;
  };
}

export function getAll(): Promise<Progresses> {
  return httpClientWithAuth.post(PROGRESS_GETALL_PATH)
    .then(({ data: { all: { response: progresses } } }: AxiosResponse<GetAllProgressResponse>) => {
      return transform(progresses, (result, value, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = {
          ...value,
          // transform completed to boolean
          completed: value.completed === 1,
        };
      }, {});
    }).catch((error) => {
      if (error.response.status === 401) {
        throw new Error('Unauthorised');
      }
      throw error;
    });
}

export default {
  removeFromContinueWatching,
  record,
  getAll,
};
