import { useCallback, useEffect } from 'react';

import OdFavouriteButtonPresenter from '@@src/components/Buttons/OdFavouriteButtonPresenter';
import { dispatchOpenSignIn } from '@@src/components/Login/LoginForm';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import {
  addFavouriteMovieAsyncThunk,
  addFavouriteProgramAsyncThunk,
  isInFavourite,
  removeFavouriteMovieAsyncThunk,
  removeFavouriteProgramAsyncThunk,
} from '@@stores/FavouritesStore';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';
import OnDemand2 from '@@types/OnDemand2';

interface ContainerProps {
  item: OnDemand2.CollectionItem
  autoAdd?: boolean;
  tabIndex?: number;
  showLabel?: boolean;
  buttonType?: 'favourite' | 'close';
  onClick?: (item: OnDemand2.CollectionItem) => void
  onClickAdd?: () => void;
  onClickRemove?: () => void;
}
const OdFavouriteButtonV2 = ({
  item,
  autoAdd = false,
  tabIndex = 0,
  showLabel = false,
  onClick,
  buttonType = 'favourite',
  onClickAdd,
  onClickRemove,
}: ContainerProps) => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(getIsLoggedIn);
  const loginStatusIsReady = useAppSelector(getIsReady);

  const inFavourite = useAppSelector((state) => {
    return isInFavourite(state, item);
  });

  const handleOnFavourite = useCallback((forcedAdd: boolean = false) => {
    let action;
    let entityId: string;
    let entityType: 'movie' | 'program';

    if (item.entityType === 'Episode') {
      entityId = item.seriesId;
      entityType = 'program';
    } else if (item.entityType === 'Series') {
      entityId = item.id;
      entityType = 'program';
    } else if (item.entityType === 'Movie' || item.entityType === 'Program' || item.entityType === 'Clip') {
      entityId = item.mpxMediaId;
      entityType = 'movie';
    }

    if (!inFavourite) {
      if (entityType === 'movie') {
        action = addFavouriteMovieAsyncThunk;
      }

      if (entityType === 'program') {
        action = addFavouriteProgramAsyncThunk;
      }

      if (onClickAdd) {
        onClickAdd();
      }
    }

    if (inFavourite && !forcedAdd) {
      if (entityType === 'movie') {
        action = removeFavouriteMovieAsyncThunk;
      }

      if (entityType === 'program') {
        action = removeFavouriteProgramAsyncThunk;
      }

      if (onClickRemove) {
        onClickRemove();
      }
    }

    if (action) {
      if (!loggedIn) {
        dispatchOpenSignIn(action({ entityId }));
      } else {
        dispatch(action({ entityId }));
      }
    }
  }, [dispatch, inFavourite, item, loggedIn, onClickAdd, onClickRemove]);

  const handleOnClick = () => {
    handleOnFavourite();

    if (onClick) {
      onClick(item);
    }
  };

  useEffect(() => {
    if (loginStatusIsReady && autoAdd) {
      handleOnFavourite(true);
    }
  }, [autoAdd, loginStatusIsReady, handleOnFavourite]);

  return (
    <OdFavouriteButtonPresenter
      isFavourite={inFavourite}
      tabIndex={tabIndex}
      showLabel={showLabel}
      buttonType={buttonType}
      onClick={handleOnClick}
    />
  );
};

export default OdFavouriteButtonV2;
