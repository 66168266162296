import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import { ReactComponent as RightArrowIcon } from '../../images/icons/right-arrow.svg';
import grey from '../../styles/colors/grey';
import padding from '../../styles/padding';

const useScrollButtonStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flexShrink: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '@media (hover: hover)': {
          position: 'absolute',
          height: '100%',
          opacity: 0,
          width: padding.container.sm + 20,
          '&$right': {
            right: 0,
          },
          '&$left': {
            left: 0,
          },
          '& $handleIcon': {
            backgroundColor: grey.codgrey,
          },
        },
        '@media (hover: none)': {
          display: 'none',
        },
      },
      [theme.breakpoints.only('xs')]: {
        width: padding.container.xs + 30,
      },
      [theme.breakpoints.up('md')]: {
        width: padding.container.md,
      },
      position: 'relative',
      paddingLeft: 4,
      '& $handleIcon': {
        top: 0,
        left: 4,
        right: 4,
        bottom: 0,
        position: 'absolute',
      },
    },
    handleIcon: {
      cursor: 'pointer',
      backgroundColor: grey.blackpearl,
      borderRadius: 5,
      color: 'white',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: grey.codgrey,
      },
    },
    left: {
      transform: 'rotate(180deg)',
    },
    right: {},
    hidden: {
      visibility: 'hidden',
    },
  });
});

interface ScrollButtonProps {
  direction: 'left' | 'right';
  disabled: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ScrollButton: FunctionComponent<ScrollButtonProps> = (props) => {
  const { direction, disabled, onClick } = props;
  const classes = useScrollButtonStyles({});

  return (
    <div className={`OdShortcutBar-scrollButtons ${classes[direction]} ${classes.root} ${disabled ? classes.hidden : ''}`}>
      {/* The <div> element is a navigation, it doesn't need to have keyboard interaction as the items are accessible by keyboard */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className={classes.handleIcon} onClick={onClick}>
        <RightArrowIcon/>
      </div>
    </div>
  );
};

export default ScrollButton;
