import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha, lighten } from '@material-ui/core/styles/colorManipulator';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

import orange from '../../styles/colors/orange';

export interface ProgressBarProps {
  progressPercent: number;
  transparent?: boolean;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>;
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      height: '4px',
      backgroundColor: lighten(common.black, 0.325),
    },
    transparent: {
      backgroundColor: alpha(common.white, 0.4),
    },
    progressBarFill: {
      height: '100%',
      backgroundColor: orange.darkTangerine,
    },
  });
});

const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const classes = useStyles(props);
  const { transparent = false, progressPercent } = props;

  const sanitizedProgressPercent = progressPercent <= 100 ? progressPercent : 100;

  return (
    <div className={`${classes.root} ${transparent ? classes.transparent : ''}`}>
      <div
        data-testid="progress-bar"
        className={clsx(classes.progressBarFill, { completed: sanitizedProgressPercent === 100 })}
        style={{ width: `${sanitizedProgressPercent}%` }}
      />
    </div>
  );
};

export default ProgressBar;
