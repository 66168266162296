/**
 * This component should be kept as simple as possible to avoid any errors.
 */
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import error from '../../../static/images/errors/error.png';
import errorX2 from '../../../static/images/errors/error@2x.png';
import errorX3 from '../../../static/images/errors/error@3x.png';

interface InlineErrorProps {
  homeLink: string;
}

const InlineError: FunctionComponent<InlineErrorProps> = (props) => {
  const { homeLink } = props;
  const { t } = useTranslation(['page', 'common']);

  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      background: '#0b0d0f',
      display: 'flex',
    }}
    >
      <div style={{ width: 345, margin: 'auto', textAlign: 'center' }}>
        <img
          style={{ width: '100%' }}
          alt={t('page:error.title')}
          srcSet={`${error}, ${errorX2} 2x, ${errorX3} 3x`}
          src={error}
        />
        <div style={{
          fontSize: '1.125rem',
          marginTop: 40,
          color: '#fff',
        }}
        >
          {t('error.title')}
        </div>
        <div style={{
          color: 'rgba(255, 255, 255, 0.6)',
          fontSize: '1.125rem',
          marginTop: 16,
        }}
        >
          {t('page:error.body')}
        </div>
        <div
          style={{
            backgroundColor: '#fdb717',
            display: 'inline-block',
            fontSize: '1.125rem',
            minWidth: 182,
            padding: '8px 10px',
            textAlign: 'center',
            marginTop: 56,
            fontFamily: 'Ubuntu, sans-serif',
            fontWeight: 'bold',
            borderRadius: '50rem',
          }}
        >
          <a
            href={homeLink}
            style={{ color: 'rgba(0,0,0,0.87)', textDecoration: 'none' }}
          >
            {t('common:button.backToHome')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InlineError;
