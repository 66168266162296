import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import LiveTileSlider from '@@src/components/Carousel/LiveTileSlider';
import OnDemand from '@@types/OnDemand';

import { EpgGuide } from '../../apis/EpgApi';
import { generatePathFromLinkProps } from '../../routes';
import LiveTvTile from './LiveTvTile';

export const liveChannelStyles = (theme: Theme) => {
  return createStyles({
    sliderRoot: {
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'nowrap',
        '& > div': {
          display: 'inline-block',
          verticalAlign: 'top',
        },
      },
      [theme.breakpoints.up('xl')]: {
        paddingBottom: '28px',
      },
      [theme.breakpoints.down('lg')]: {
        paddingBottom: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
    },
    slider: {
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: 60,
        width: 'calc(100% - 110px)',
      },
    },
    channelLogoContainer: {
      [theme.breakpoints.up('md')]: {
        width: 50,
        transform: 'translateY(175px) translateX(30px)',
      },
    },
    channelLogoImg: {
      height: 34,
      [theme.breakpoints.up('md')]: {
        transform: 'rotate(-90deg) translateX(-50%)',
        transformOrigin: 'center left',
        width: 'auto',
        height: 50,
      },
    },
    tile: {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  });
};
export const useStyles = makeStyles(liveChannelStyles);

export interface LiveTvChannelShelfProps {
  epgGuide: EpgGuide;
}

const LiveTvChannelShelf: FunctionComponent<LiveTvChannelShelfProps> = ({ epgGuide }) => {
  const { channel, programs } = epgGuide;
  const classes = useStyles();

  return (
    <LiveTileSlider
      titleComponent={(
        <div role="heading" aria-level={2} className={classes.channelLogoContainer}>
          <img src={channel.imageUrl} alt={channel.title} title={channel.title} className={classes.channelLogoImg}/>
        </div>
      )}
      getPrevOffset={() => {
        return 0;
      }}
      getNextOffset={(sliderElement: HTMLDivElement) => {
        return sliderElement.scrollWidth - sliderElement.offsetWidth;
      }}
      classes={{
        root: classes.sliderRoot,
        slider: classes.slider,
      }}
    >
      <>
        {programs.map((program, index) => {
          const metadata: (string | {
            type: 'classification';
            value: OnDemand.Classification;
          })[] = [...program.metadata, { type: 'classification', value: program.classification }];

          return (
            <div key={program.id} className={classes.tile}>
              <LiveTvTile
                variant={index === 0 ? 'primary' : 'secondary'}
                title={program.title}
                description={program.description}
                metadata={metadata}
                start={program.start}
                end={program.end}
                imageUrl={program.imageUrl}
                availableForStreaming={program.availableForStreaming}
                clickUrl={
                  index === 0
                    ? generatePathFromLinkProps({
                      name: 'watch',
                      params: {
                        id: channel.mpxMediaID,
                      },
                    })
                    : undefined
                }
                channel={channel.title}
              />
            </div>
          );
        })}
      </>
    </LiveTileSlider>
  );
};

export default LiveTvChannelShelf;
