import 'intl-pluralrules';
import { Location } from 'history';
import humanizeDuration from 'humanize-duration';
import i18n, { InitOptions } from 'i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';

import { BASENAME } from '@@utils/constants';

export const supportedLanguages = {
  en: {
    name: 'english', // used in data layer
    localeCode: 'en_AU', // language & country code
    shortLocaleCode: 'en_AU', // used in places where they only support 2 character language and 2 character country code
    menuLabels: ['English'], // used in menu
    humanizeDurationLangCode: 'en', // used by humanize duration, check the supported language code in https://www.npmjs.com/package/humanize-duration
    urlPrefix: '', // url prefix for the specific language pages
  },
  ar: {
    name: 'arabic',
    localeCode: 'ar_AR',
    menuLabels: ['عربي', 'Arabic'],
  },
  'zh-Hans': {
    name: 'simplified chinese',
    localeCode: 'zh-Hans_CN',
    shortLocaleCode: 'zh_CN',
    menuLabels: ['简体中文', 'Chinese (Simplified)'],
    humanizeDurationLangCode: 'zh_CN',
    urlPrefix: 'zh-hans',
  },
  'zh-Hant': {
    name: 'traditional chinese',
    localeCode: 'zh-Hant_TW',
    shortLocaleCode: 'zh_TW',
    menuLabels: ['繁體中文', 'Chinese (Traditional)'],
    humanizeDurationLangCode: 'zh_TW',
    urlPrefix: 'zh-hant',
  },
  hi: {
    name: 'hindi',
    localeCode: 'hi_IN',
    menuLabels: ['हिन्दी', 'Hindi'],
  },
  ko: {
    name: 'korean',
    localeCode: 'ko_KO',
    menuLabels: ['한국어', 'Korean'],
  },
  vi: {
    name: 'vietnamese',
    localeCode: 'vi_VN',
    menuLabels: ['Tiếng Việt', 'Vietnamese'],
  },
};

export type SupportedLanguage = keyof typeof supportedLanguages;

/**
 * list of language labels used in the menu
 */
export const languageLabels: Record<SupportedLanguage, string[]> = (() => {
  const a = {} as Record<SupportedLanguage, string[]>;
  Object.keys(supportedLanguages).forEach((k: SupportedLanguage) => {
    a[k] = supportedLanguages[k].menuLabels;
  });
  return a;
})();

/**
 * Get the full locale code
 * @param lang
 * @param separator
 */
export function getLocaleCode(lang: string, separator: string = '_'): string {
  if (Object.keys(supportedLanguages).includes(lang)) {
    return supportedLanguages[lang].localeCode.replace('_', separator);
  }
  return '';
}

/**
 * Get short locale code, used in places where it only supports 2 character language & 2 character country code
 * If shortLocaleCode is not defined, we'll return the value from `localeCode`
 * @param lang
 * @param separator
 */
export function getShortLocaleCode(lang: string, separator: string = '_'): string {
  if (Object.keys(supportedLanguages).includes(lang) && supportedLanguages[lang].shortLocaleCode) {
    return supportedLanguages[lang].shortLocaleCode.replace('_', separator);
  }

  return getLocaleCode(lang, separator);
}

/**
 * Get language name, used in data layer
 * @param lang
 */
export function getLanguageNameByCode(lang: string): string {
  if (Object.keys(supportedLanguages).includes(lang)) {
    return supportedLanguages[lang].name;
  }
  return '';
}

/**
 * Get the supported language codes, does not include the country code
 */
export const supportedLangCodes = Object.keys(supportedLanguages) as SupportedLanguage[];

/**
 * Get url prefix, default to the language code if not defined
 * @param lang
 */
export function getUrlPrefix(lang: string): string {
  if (Object.keys(supportedLanguages).includes(lang)) {
    return supportedLanguages[lang].urlPrefix !== undefined ? supportedLanguages[lang].urlPrefix : lang;
  }
  return '';
}

/**
 * Get humanize lang code, default to the language code if not defined
 * @param lang
 */
export function getHumanizeDurationLangCode(lang: string): string {
  if (Object.keys(supportedLanguages).includes(lang)) {
    return supportedLanguages[lang].humanizeDurationLangCode !== undefined ? supportedLanguages[lang].humanizeDurationLangCode : lang;
  }
  return '';
}

export const durationShortFormatParams = {
  spacer: '',
  delimiter: ' ',
  shortUnits: true,
};

export const durationShortFormatParamsWithSeconds = {
  units: ['d', 'h', 'm', 's'],
  spacer: '',
  delimiter: ' ',
  shortUnits: true,
};

export const options: InitOptions = {
  fallbackLng: 'en',
  load: 'currentOnly',
  supportedLngs: supportedLangCodes,
  /**
   * This should include all the namespaces used in the app,
   * otherwise the text will not be translated on ssr and would flicker on the client side when translated
   * There are namespaces that we may not want to include if they rendered only on client side, eg: validation errors
   */
  ns: [
    'page',
    'common',
  ],
  defaultNS: 'common',
  debug: false,
  detection: {
    order: ['htmlTag', 'path'],
    lookupFromPathIndex: BASENAME ? BASENAME.match(/\//g).length : 0,
  },
  react: {
    // using Suspense on client side caused warning "Did not expect server HTML to contain a <div> in <div>" due to html mismatch
    useSuspense: false,
  },
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(initReactI18next)
    .use(BrowserLanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
  i18n.services.formatter.add('humaniseDuration', (value, lng, _options) => {
    const {
      round = true,
      units = ['d', 'h', 'm'],
      largest = 2,
      spacer = undefined,
      delimiter = undefined,
      shortUnits = false,
    } = _options;

    let languages;
    if (shortUnits) {
      languages = {
        en: {
          y() {
            return 'y';
          },
          mo() {
            return 'mo';
          },
          w() {
            return 'w';
          },
          d() {
            return 'd';
          },
          h() {
            return 'h';
          },
          m() {
            return 'm';
          },
          s() {
            return 's';
          },
          ms() {
            return 'ms';
          },
        },
      };
    }

    const humanizeOptions = {
      round,
      units,
      largest,
      ...(spacer !== undefined && { spacer }),
      ...(delimiter !== undefined && { delimiter }),
      ...(languages && { languages }),
      language: getHumanizeDurationLangCode(lng),
      fallbacks: ['en'],
    };

    return humanizeDuration(value * 1000, humanizeOptions);
  });
  i18n.services.formatter.add('timeOnly', (value) => {
    return DateTime.fromISO(value).toLocal().toFormat('h:mma').toLowerCase();
  });
  i18n.services.formatter.add('dateOnly', (value, _lng, _options) => {
    const { showDayOfWeek = false } = _options;
    if (showDayOfWeek) {
      return DateTime.fromISO(value).toLocal().toFormat('cccc d LLLL');
    }

    return DateTime.fromISO(value).toLocal().toFormat('d LLLL');
  });
  i18n.services.formatter.add('dateOnlyShortHand', (value) => {
    // only returns [d] [mon] e.g. 17 Oct
    return DateTime.fromISO(value).toLocal().toFormat('d LLL');
  });
}

export default i18n;

/**
 * Get localised path by appending url prefix to the location pathname
 * @param lang
 * @param location
 */
export const getLocalizedPath = (lang: SupportedLanguage, location: Location): string => {
  let localizedPath = `${BASENAME}${location.pathname}`;
  if (supportedLangCodes.includes(lang) && getUrlPrefix(lang)) {
    localizedPath = `${BASENAME}/${getUrlPrefix(lang)}${location.pathname}`;
  }

  // remove trailing slash if not the root path with the basename, eg: /ondemand/
  // because express adds trailing slash to the root path and we want to avoid the redirection
  if (location.pathname !== '/') {
    localizedPath = localizedPath.replace(/^(.+)\/$/, '$1');
  }

  return localizedPath;
};

export function isRtl(language: string): boolean {
  return language === 'ar';
}
