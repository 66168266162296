import { Box, Grid, Theme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { FunctionComponent, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Logger from '@@utils/logger/Logger';

import errorX1 from '../../../static/images/errors/error.png';
import errorX2 from '../../../static/images/errors/error@2x.png';
import errorX3 from '../../../static/images/errors/error@3x.png';
import { generatePathFromLinkProps } from '../../routes';
import Button from '../Inputs/Button';
import OdContainer from '../Utils/OdContainer';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginTop: 150,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    img: {
      width: 345,
      [theme.breakpoints.down('xs')]: {
        width: 245,
      },
    },
    title: {
      fontSize: '1.125rem',
    },
    body: {
      fontSize: '1.125rem',
      color: alpha(common.white, 0.6),
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
  });
});

interface ErrorProps {
  title: string;
  body: string;
}

const Error: FunctionComponent<ErrorProps> = (props) => {
  const { title, body } = props;
  const classes = useStyles(props);
  const { t } = useTranslation(['common']);
  const images = {
    x1: errorX1,
    x2: errorX2,
    x3: errorX3,
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <OdContainer className={classes.root}>
        <div>
          <img
            srcSet={`${images.x1}, ${images.x2} 2x, ${images.x3} 3x`}
            src={images.x1}
            alt={title}
            className={classes.img}
          />
          <Box mx={2} mt={5}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <div className={classes.title}>{title}</div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.body}>{body}</div>
              </Grid>
              <Grid item xs={12}>
                <Box mt={5}>
                  <Button to={generatePathFromLinkProps({ name: 'home' })}>
                    {t('button.backToHome')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </OdContainer>
    </>
  );
};

export const GenericError = () => {
  const { t } = useTranslation(['page']);
  const location = useLocation();

  useEffect(() => {
    Logger.error('There was an error', {
      path: location.pathname,
    });
  }, [location.pathname]);

  return <Error title={t('error.title')} body={t('error.body')}/>;
};

export const NotFoundError = () => {
  const { t } = useTranslation(['page']);
  const location = useLocation();

  useEffect(() => {
    Logger.info('Not found error', {
      path: location.pathname,
    });
  }, [location.pathname]);

  return <Error title={t('notFound.title')} body={t('notFound.body')}/>;
};

export const VideoLoadingError = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();

  useEffect(() => {
    Logger.error('Video loading error', {
      path: location.pathname,
    });
  }, [location.pathname]);

  return <Error title={t('videoPlayer.genericErrorTitle')} body={t('videoPlayer.genericError')}/>;
};

export default Error;
