import { format } from 'winston';

/**
 * Format error object, return the error.stack as the error message if available, otherwise return the error.message
 */
const formatErrorObject = format((info) => {
  let message = info.message as any;

  if (message instanceof Error) {
    // if message.stack is defined, use that for the message
    message = message.stack || message.message;
  }

  return {
    ...info,
    message,
  };
});

export default formatErrorObject;
