import { createSelector, createSlice } from '@reduxjs/toolkit';
import * as ls from 'local-storage';
import { get } from 'lodash';

import type { RootState } from '../store';

export interface UserPreferencesState {
  language: string;
}

const defaultState: UserPreferencesState = {
  language: 'en',
};

// Loading the preferred language from local storage.
// Note: it will be overridden by the state coming from SSR.
const storedState: UserPreferencesState = ls.get('od.userPreferences');
const initialState: UserPreferencesState = {
  ...defaultState,
  ...(storedState && ls.get('od.userPreferences')),
};

const userPreferencesStore = createSlice({
  name: 'UserPreferenceStore',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
      ls.set('od.userPreferences', state);
    },
  },
});

// selectors
export const getLanguage = createSelector(
  (rootState: RootState) => {
    return rootState.userPreferences;
  },
  (state) => {
    return get(state, 'language', 'en');
  },
);

export default userPreferencesStore;
export const { setLanguage } = userPreferencesStore.actions;
