import * as ls from 'local-storage';
import { pickBy } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { cloneElement, FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { getSbsLoginInstance } from '@@utils/SbsLoginUtils';
import { hash } from '@@utils/helpers';

interface DisplayRule {
  pathname?: string;
  geoblocked?: boolean;
  endDate?: string;
  /**
   * The ISO 8601 duration before the message banner should be displayed again. Leave it null to always display on the next page load.
   */
  closeDuration?: string;
}

export interface BannerConfig {
  name: string;
  component: React.ReactElement,
  displayRules?: DisplayRule[];
}

interface MessageBannerListProps {
  // banners ordered by priority
  bannerConfigs: BannerConfig[];
}

const localStorageKey = 'od.messageBannerExp';
export function clearMessageBannerExpiredValues() {
  ls.backend(localStorage);
  const values = ls.get<Record<string, number>>(localStorageKey) || {};
  ls.set(localStorageKey, pickBy(values, (expiry) => {
    const expiryDate = DateTime.fromMillis(expiry);
    return expiryDate.diffNow().as('seconds') > 0;
  }));
}

const MessageBannerList: FunctionComponent<MessageBannerListProps> = (props) => {
  const { bannerConfigs } = props;
  const [messageBanners, setMessageBanners] = useState([]);
  const [closedBanners, setClosedBanners] = useState<number[]>([]);
  const [selectedBanner, setSelectedBanner] = useState<React.ReactElement>(null);
  const [willBeGeoBlocked, setWillBeGeoBlocked] = useState(null);
  const [index, setIndex] = useState(0);
  const location = useLocation();

  const partiallyMatchesPathname = useCallback((pathname: string, pathnameToMatch: string) => {
    return pathnameToMatch === pathname // Exact path match
      || pathname.startsWith(`${pathnameToMatch}/`); // Or sub path
  }, []);

  useEffect(() => {
    // Update local banner list if configs have changed from the props
    setMessageBanners([...bannerConfigs]);
  }, [bannerConfigs]);

  useEffect(() => {
    setClosedBanners([]);
  }, [messageBanners]);

  useEffect(() => {
    getSbsLoginInstance()
      .then((sbsLogin) => {
        sbsLogin.isInAustralia()
          .then((inAustralia) => {
            return setWillBeGeoBlocked(!inAustralia);
          });
      });
  }, []);

  useEffect(() => {
    // Checks for banner expiry date
    const isBannerActive = (displayRule: DisplayRule) => {
      if (displayRule?.endDate) {
        const now = DateTime.local();
        const endDate = DateTime.fromISO(displayRule.endDate);

        if (now >= endDate) {
          return false;
        }
      }

      return true;
    };

    const shouldBannerBeClosed = (bannerId: string) => {
      ls.backend(localStorage);
      const values = ls.get<Record<string, number>>(localStorageKey) || {};

      if (values[bannerId]) {
        const expiryDate = DateTime.fromMillis(values[bannerId]);

        // Close expiry date reached
        return expiryDate.diffNow().as('seconds') > 0;
      }

      return false;
    };

    if (willBeGeoBlocked !== null) {
      // Find first banner that has matching rule
      messageBanners.some((banner, itemIndex) => {
        if (closedBanners.indexOf(itemIndex) !== -1) {
          return false;
        }

        // The banner contained specified display rules
        if (banner?.displayRules) {
          // If displayRules specified but empty, skip the banner.
          // else then continue and find a matching rule
          if (banner.displayRules.length === 0) {
            return false;
          }

          // Find first matching rule
          return banner.displayRules.some((displayRule) => {
            // Do not show banners in the Watch page
            if (location.pathname.startsWith('/watch')) {
              return false;
            }

            // Geoblock banner
            if (displayRule?.geoblocked && willBeGeoBlocked) {
              setSelectedBanner(banner.component);
              setIndex(itemIndex);
              return true;
            }

            const bannerId = hash(`${banner.name}${displayRule.pathname}`);

            // Website path
            if (partiallyMatchesPathname(location.pathname, displayRule?.pathname)) {
              if (
                // Banner expiry date
                isBannerActive(displayRule)
                // Banner has been previously closed by the user, check if it should stay closed
                && shouldBannerBeClosed(bannerId) === false
              ) {
                setSelectedBanner(banner.component);
                setIndex(itemIndex);
                return true;
              }
            } else if (selectedBanner === banner.component) {
              setSelectedBanner(null);
            }

            // No rule is matching, skip to the next banner
            return false;
          });
        }

        // If the banner has no display rule then just select it.
        setSelectedBanner(banner.component);
        setIndex(itemIndex);
        return true;
      });
    }
  }, [closedBanners, location.pathname, messageBanners, partiallyMatchesPathname, selectedBanner, willBeGeoBlocked]);

  return selectedBanner ? cloneElement(selectedBanner, {
    onClose: () => {
      const closeBannerComponent = () => {
        setClosedBanners([
          ...closedBanners,
          index,
        ]);
        setIndex(index + 1);
        setSelectedBanner(null);
      };

      const persistBannerDismissal = (bannerId: string, duration: string) => {
        ls.backend(localStorage);
        const values = ls.get<Record<string, number>>(localStorageKey) || {};
        ls.set(localStorageKey, {
          ...values,
          [bannerId]: DateTime.now().plus(Duration.fromISO(duration)).toMillis(),
        });
      };

      if (messageBanners[index]?.displayRules) {
        const { displayRules } = messageBanners[index];

        // Handles multiple pathname rules by deleting the current matching one if the banner has been closed.
        if (displayRules.length > 1) {
          const indexToDelete = displayRules.findIndex((displayRule) => {
            return partiallyMatchesPathname(location.pathname, displayRule?.pathname);
          });

          if (indexToDelete !== -1) {
            persistBannerDismissal(
              hash(`${messageBanners[index].name}${displayRules[indexToDelete]?.pathname}`),
              displayRules[indexToDelete]?.closeDuration || 'P30D',
            );

            delete messageBanners[index].displayRules[indexToDelete];
            setSelectedBanner(null);
          }
        } else {
          persistBannerDismissal(
            hash(`${messageBanners[index].name}${displayRules[0]?.pathname}`),
            displayRules[0]?.closeDuration || 'P30D',
          );

          closeBannerComponent();
        }
      } else {
        closeBannerComponent();
      }
    },
  }) : null;
};

export default MessageBannerList;
