import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import Sparkle from '@@icons/Sparkle';
import fontFamily from '@@styles/typography/fontFamily';
import OnDemand from '@@types/OnDemand';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontFamily: fontFamily.secondary,
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 700,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
      display: 'flex',
      columnGap: theme.spacing(1.5),
    },
    announcementIcon: {
      fontSize: '1.5rem',
    },
    announcementTitle: {
      flex: 1,
    },
  });
});

interface AnnouncementProps {
  announcement: OnDemand.Announcement;
}

const Announcement: FunctionComponent<AnnouncementProps> = (props) => {
  const { announcement } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Sparkle className={classes.announcementIcon}/>
      <span className={classes.announcementTitle}>{announcement.title}</span>
    </div>
  );
};

export default Announcement;
