import { IconButton, FormControlLabel, Switch, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, withStyles, useTheme, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import * as ls from 'local-storage';
import { pick } from 'lodash';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';

import { useAppSelector } from '@@src/hooks/store';
import { getIsLoggedIn } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

import newsletterContourSmImage from '../../../static/images/newsletter-contour-sm.png';
import newsletterContourXlImage from '../../../static/images/newsletter-contour-xl.png';
import newsletterPromoXlImage from '../../../static/images/newsletter-promo-xl.jpg';
import newsletterPromoXsImage from '../../../static/images/newsletter-promo-xs.png';
import * as UserProfileApi from '../../apis/UserProfileApi';
import blackRoundedTickImage from '../../images/icons/black-rounded-tick.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import exclamationMarkIcon from '../../images/icons/exclamation-mark.svg';
import switchButtonSpinnerImage from '../../images/icons/switch-button-spinner.svg';
import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import orange from '../../styles/colors/orange';
import OdContainer from '../Utils/OdContainer';

const closeAnimationDelay = 0.5;
const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    '@global': {
      '@keyframes rotation': {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(359deg)',
        },
      },
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: '53px 0 53px 0',
      color: grey.black,
      transition: `max-height ${closeAnimationDelay}s ease-out, opacity ${closeAnimationDelay}s ease-out`,
      maxHeight: 500,

      '&.closed': {
        overflow: 'hidden',
        maxHeight: 0,
        opacity: 0,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: 800,
      },
      '& a': {
        color: grey.black,
        textDecoration: 'underline',
      },
      ':focus-visible': {
        borderRadius: 4,
      },
    },
    title: {
      fontFamily: 'Ubuntu',
      weight: 'bold',
      fontSize: '2.875rem',
      margin: '0 0 20px 0',

      [theme.breakpoints.down('lg')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 'calc(5.072vw)',
      },
    },
    description: {
      marginBottom: 26,
    },
    promo: {
      position: 'relative',
      borderRadius: '4px 0 0 4px',
      padding: '70px 120px 70px 70px',
      fontSize: '1rem',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      maxWidth: 700,
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: orange.darkTangerine,

      '&.closed': {
        overflow: 'hidden',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '35px 43px 90px 43px',
        borderRadius: '4px 4px 0 0',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem',
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 575,
      },
    },
    newsletterBackgroundXl: {
      position: 'relative',
      borderRadius: '0 4px 4px 0',
      backgroundImage: `url(${newsletterPromoXlImage})`,
      backgroundPosition: 'right',
      height: 391,
      maxWidth: 1100,
      [theme.breakpoints.down('lg')]: {
        height: 408,
        maxWidth: 'initial',
      },
      width: '100%',
    },
    newsletterBackgroundXs: {
      borderRadius: '0 0 4px 4px',
      backgroundImage: `url(${newsletterPromoXsImage})`,
      backgroundPosition: '100% -1px',
      width: '100%',
      maxWidth: 575,
      height: 455,
      position: 'relative',
      top: -75,
    },
    newsletterContour: {
      height: 391,
      [theme.breakpoints.down('lg')]: {
        height: 408,
      },
    },
    contour: {
      left: -85,
      position: 'relative',
      height: 391,
      [theme.breakpoints.down('lg')]: {
        height: 408,
      },
    },
    checkedIcon: {
      width: 20,
      borderRadius: 10,
      padding: 3,
      margin: 2,
      backgroundColor: grey.white,
      '&.rotate': {
        animation: 'rotation 2s infinite linear',
      },
    },
    ctaLabel: {
      fontSize: '1.25rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: 'calc(2.225vw + 4px)',
      },
      fontWeight: 'bold',
      fontFamily: 'Ubuntu',
      marginLeft: 10,
      '&.Mui-disabled': {
        color: grey.black,
      },
    },
    tryAgainButton: {
      border: 'none',
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    tips: {
      fontSize: '0.75rem',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      alignSelf: 'center',
      padding: 10,

      '.closed &': {
        display: 'none',
      },
      '& svg': {
        width: '1.35em',
        height: '1.35em',
      },
      '& path.close_svg__circle': {
        fill: '#000000',
        fillOpacity: '0.5',
      },
      '&.mobile path.close_svg__x': {
        fill: grey.blackpearl,
      },
      '&.mobile path.close_svg__circle': {
        display: 'none',
      },
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
    loadingPlaceholder: {
      width: 1800,
      margin: 'auto',
      borderRadius: 4,
      maxWidth: '100%',
      [theme.breakpoints.down('lg')]: {
        width: 575,
      },
    },
  });
});

// https://material-ui.com/components/switches/
interface SwitchBuilderProps {
  classes: any;
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  role?: string;
  'aria-checked'?: boolean;
  disabled?: boolean;
  ref?: any;
}
const switchBuilder: React.FC<SwitchBuilderProps> = ({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  );
};

const IOSSwitch = withStyles((theme) => {
  return {
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),

      '&:focus-within': {
        outline: `3px solid ${blue.navy} !important`,
        borderRadius: 15,
      },
    },
    switchBase: {
      padding: 1,
      backgroundColor: grey.black,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: grey.black,
          opacity: 1,
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: grey.black,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      '.MuiSwitch-colorSecondary.Mui-disabled + &': {
        backgroundColor: grey.black,
        opacity: 1,
      },
    },
    checked: {},
    focusVisible: {},
  };
})(switchBuilder);

interface NewsletterPromoLs {
  dismissUntil: string;
  cs: boolean; // cached subscribed
  cse: string; // cached subscribed expiry
}

const NewsletterPromoBlock: FunctionComponent = (props) => {
  const classes = useStyles(props);
  const checkedIconRef = useRef(null);
  const { t } = useTranslation(['common']);

  const theme = useTheme();
  const mediaMatchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const mediaMatchesLg = useMediaQuery(theme.breakpoints.up('sm'));
  const mediaMatchesXl = useMediaQuery(theme.breakpoints.up('lg'));

  const isLoggedIn = useAppSelector(getIsLoggedIn);

  const [newsletterState, setNewsletterState] = useState(null);
  const [showNewsletterPromo, setShowNewsletterPromo] = useState(false);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [hasError, setHasError] = useState(false);

  const subscriptionSource = 'od_upd_web_prompt';
  const localStorageKey = 'od.newsletterPromo';

  const getUserLocalSettings = useCallback((): NewsletterPromoLs => {
    ls.backend(localStorage);
    return {
      dismissUntil: null,
      cs: null,
      cse: null,
      ...ls.get<NewsletterPromoLs>(localStorageKey),
    };
  }, []);

  const updateUserLocalSettings = useCallback((settings: Partial<NewsletterPromoLs>) => {
    ls.backend(localStorage);
    ls.set(localStorageKey, pick({
      ...getUserLocalSettings(),
      ...settings,
    }, ['dismissUntil', 'cs', 'cse']));
  }, [getUserLocalSettings]);

  const handleSubscription = useCallback((subscribed: boolean) => {
    if (subscribed) {
      setNewsletterState('subscribed');
      Logger.info('User already subscribed to the newsletter promo');
    } else {
      setNewsletterState('unsubscribed');
      setShowNewsletterPromo(true);

      DataLayer.events.newsletterPromo('display');
    }
  }, []);

  useEffect(() => {
    const signOutHandler = () => {
      ls.remove(localStorageKey);
    };
    document.addEventListener('OdWebsite_SignOutSuccess', signOutHandler);

    return () => {
      document.removeEventListener('OdWebsite_SignOutSuccess', signOutHandler);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const {
        dismissUntil, cs: cachedSubscribed, cse: cachedSubscribedExpiry,
      } = getUserLocalSettings();

      if (dismissUntil === null || DateTime.local() >= DateTime.fromISO(dismissUntil)) {
        if (cachedSubscribedExpiry !== null && DateTime.local() < DateTime.fromISO(cachedSubscribedExpiry)) {
          handleSubscription(cachedSubscribed);
        } else {
          setLoadingSubscriptions(true);

          UserProfileApi.getSubscriptions()
            .then((subscriptions) => {
              let subscribed = false;

              Object.keys(subscriptions).forEach((key) => {
                if (key === 'watchlist' || key === 'curatorspick') {
                  subscribed = true;
                }
              });

              handleSubscription(subscribed);

              // cache subscribed status
              updateUserLocalSettings({
                cs: subscribed,
                cse: DateTime.local().plus({ days: 7 }).toISO(),
              });
            })
            .catch((e) => {
              Logger.error('An error occurred while fetching user newsletter subscriptions', {
                error: {
                  message: e.message,
                },
              });
            })
            .finally(() => {
              setLoadingSubscriptions(false);
            });
        }
      } else {
        Logger.info('User previously dismissed the newsletter promo');
      }
    } else {
      setShowNewsletterPromo(false);
    }
  }, [isLoggedIn, getUserLocalSettings, updateUserLocalSettings, handleSubscription]);

  useEffect(() => {
    if (hasError === true) {
      checkedIconRef.current.src = exclamationMarkIcon;
      checkedIconRef.current.classList.remove('rotate');
    }
  }, [hasError, checkedIconRef]);

  if (loadingSubscriptions) {
    return (
      <OdContainer className={classes.root}>
        <Skeleton variant="rect" height={500} className={classes.loadingPlaceholder}/>
      </OdContainer>
    );
  }

  if (showNewsletterPromo === false) {
    return null;
  }

  let ctaLabel = '';
  if (hasError) {
    ctaLabel = t('newsletterPromoBlock.error');
  } else {
    switch (newsletterState) {
      case 'unsubscribed':
        ctaLabel = t('newsletterPromoBlock.subscribeToOd');
        break;

      case 'subscribing':
        ctaLabel = t('newsletterPromoBlock.addingYou');
        break;

      case 'unsubscribing':
        ctaLabel = t('newsletterPromoBlock.removingYou');
        break;

      case 'subscribed':
        ctaLabel = t('newsletterPromoBlock.youAreSubscribed');
        break;

      case 'loading':
      default:
        // Nothing, at this point this component should not be rendered yet
        break;
    }
  }

  const noIcon = (
    <span
      style={{
        width: 20,
        height: 20,
        borderRadius: 10,
        padding: 3,
        margin: 2,
        backgroundColor: grey.white,
      }}
    />
  );

  const errorIcon = (
    <img
      ref={checkedIconRef}
      src={exclamationMarkIcon}
      alt=""
      className={clsx(classes.checkedIcon, 'rotate')}
    />
  );

  const loadingIcon = (
    <img
      ref={checkedIconRef}
      src={newsletterState === 'subscribed' ? blackRoundedTickImage : switchButtonSpinnerImage}
      alt=""
      className={clsx(classes.checkedIcon, { rotate: newsletterState !== 'subscribed' })}
    />
  );

  let uncheckedIcon = noIcon;
  if (hasError) {
    uncheckedIcon = errorIcon;
  } else if (newsletterState === 'unsubscribing') {
    if (checkedIconRef.current) {
      checkedIconRef.current.src = switchButtonSpinnerImage;
      checkedIconRef.current.classList.add('rotate');
    }
    uncheckedIcon = loadingIcon;
  }

  const switchIsChecked = newsletterState === 'subscribed'
    || newsletterState === 'subscribing';

  // react-i18next does not support HTML inside translation files
  // This is to allow flexibility of placement of the TOS and PRIVACY in side the translated text
  let tosAndPrivacy: string | React.ReactNode[] = t('newsletterPromoBlock.tosAndPrivacy');
  tosAndPrivacy = reactStringReplace(tosAndPrivacy, '%%TOS%%', () => {
    return (
      <a href="https://www.sbs.com.au/aboutus/terms-and-conditions" key="0" target="_blank" rel="noreferrer noopener">
        {t('newsletterPromoBlock.termsOfService')}
      </a>
    );
  }) as React.ReactNode[];
  tosAndPrivacy = reactStringReplace(tosAndPrivacy, '%%PRIVACY%%', () => {
    return (
      <a href="https://www.sbs.com.au/privacy-policy" key="1" target="_blank" rel="noreferrer noopener">
        {t('newsletterPromoBlock.privacyPolicy')}
      </a>
    );
  }) as React.ReactNode[];

  const handleChange = (event) => {
    const target = event.currentTarget;

    if (event.target.checked) {
      setNewsletterState('subscribing');
      UserProfileApi.updateSubscriptions(
        {
          source: subscriptionSource,
          subscriptions: {
            curatorspick: true,
            watchlist: true,
          },
        },
      )
        .then(() => {
          checkedIconRef.current.src = blackRoundedTickImage;
          checkedIconRef.current.classList.remove('rotate');
          setNewsletterState('subscribed');
          setHasError(false);

          // update cached subscribe status to true
          updateUserLocalSettings({
            cs: true,
            cse: DateTime.local().plus({ days: 7 }).toISO(),
          });

          DataLayer.events.newsletterPromo('subscribe');
        })
        .catch((e) => {
          setHasError(true);
          Logger.error('The newsletter subscribe API returned false', {
            error: {
              message: e.message,
            },
          });
        });
    } else {
      setNewsletterState('unsubscribing');
      UserProfileApi.updateSubscriptions(
        {
          source: subscriptionSource,
          subscriptions: {
            curatorspick: false,
            watchlist: false,
          },
        },
      )
        .then(() => {
          setNewsletterState('unsubscribed');
          setHasError(false);

          // update cached subscribe status to false
          updateUserLocalSettings({
            cs: false,
            cse: DateTime.local().plus({ days: 7 }).toISO(),
          });

          DataLayer.events.newsletterPromo('unsubscribe');
          target.focus();
        })
        .catch((e) => {
          setHasError(true);
          Logger.error('An error occurred while trying to unsubscribe to newsletter', {
            error: {
              message: e.message,
            },
          });
          target.focus();
        });
    }
  };

  const handleTryAgainClick = () => {
    setHasError(false);

    if (newsletterState === 'subscribing') {
      setNewsletterState('unsubscribed');
      uncheckedIcon = noIcon;
    } else if (newsletterState === 'unsubscribing') {
      setNewsletterState('subscribed');
      checkedIconRef.current.src = blackRoundedTickImage;
    }
  };

  const handleCloseClick = (event) => {
    const { target } = event;
    const container = target.closest('.MuiContainer-root');
    if (container) {
      container.classList.add('closed');
    }

    updateUserLocalSettings({
      dismissUntil: DateTime.local().plus({ months: 2 }).toISO(),
    });

    DataLayer.events.newsletterPromo('dismiss');

    setTimeout(() => {
      setShowNewsletterPromo(false);
    }, closeAnimationDelay * 1000);
  };

  return (
    <OdContainer className={classes.root} data-testid="newsletter-promo">
      <div className={classes.promo}>

        <h2 className={classes.title}>{t('newsletterPromoBlock.title')}</h2>
        <p className={classes.description}>{t('newsletterPromoBlock.description')}</p>
        <FormControlLabel
          classes={{
            label: classes.ctaLabel,
          }}
          control={(
            <IOSSwitch
              disabled={newsletterState !== 'subscribed' && newsletterState !== 'unsubscribed'}
              checked={switchIsChecked}
              onChange={handleChange}
              icon={uncheckedIcon}
              checkedIcon={loadingIcon}
              role="switch"
              aria-checked={switchIsChecked}
              data-testid="newsletterpromo-switch"
              data-newsletterstate={newsletterState}
              aria-live="polite"
            />
          )}
          label={ctaLabel}
        />
        <p className={classes.tips}>
          {newsletterState === 'unsubscribed' && tosAndPrivacy}
          {hasError && (
            <button
              data-testid="try-again-btn"
              className={classes.tryAgainButton}
              type="button"
              onClick={handleTryAgainClick}
            >
              {t('newsletterPromoBlock.tryAgain')}
            </button>
          )}
        </p>

        {mediaMatchesSm && (
          <IconButton
            title={t('newsletterPromoBlock.close')}
            aria-label={t('newsletterPromoBlock.close')}
            className={clsx(classes.actionButton, classes.closeButton, { mobile: mediaMatchesSm })}
            onClick={handleCloseClick}
            tabIndex={0}
          >
            <CloseIcon/>
          </IconButton>
        )}
      </div>

      {mediaMatchesSm && <div className={classes.newsletterBackgroundXs}/>}

      {!mediaMatchesSm && (
        <div className={classes.newsletterBackgroundXl}>
          {!mediaMatchesXl
          && mediaMatchesLg
          && <img src={newsletterContourSmImage} className={classes.contour} alt=""/>}
          {mediaMatchesXl && <img src={newsletterContourXlImage} className={classes.contour} alt=""/>}
          {!mediaMatchesSm && (
            <IconButton
              title="Close"
              aria-label={t('newsletterPromoBlock.close')}
              className={clsx(classes.actionButton, classes.closeButton, { mobile: mediaMatchesSm })}
              onClick={handleCloseClick}
              tabIndex={0}
            >
              <CloseIcon/>
            </IconButton>
          )}
        </div>
      )}
    </OdContainer>
  );
};

export default NewsletterPromoBlock;
