import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';

import blue from '../../styles/colors/blue';
import ellipsis from '../../styles/ellipsis';
import fontFamily from '../../styles/typography/fontFamily';
import darkTheme from '../../themes/dark';

const styles = makeStyles(() => {
  return createStyles({
    '@global': {
      'html, body': {
        height: '100%',
      },
      '#root': {
        minHeight: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        '.mobile &': {
          width: 'auto !important',
          // to make the filter bar sticky on mobile
          '@supports not (overflow: clip)': {
            overflowX: 'hidden!important',
          },
          '@supports (overflow: clip)': {
            overflowX: 'clip!important',
          },
        },
      },
      a: {
        textDecoration: 'none',
        color: darkTheme.palette.text.primary,
        '&.block': {
          display: 'block',
        },
      },
      '.MuiInputBase-input': {
        '&::placeholder': {
          opacity: '0.6 !important',
        },
        '&::-webkit-input-placeholder': {
          opacity: '0.6 !important',
        },
      },
      '::placeholder': ellipsis,
      '::-webkit-input-placeholder': ellipsis,
      // disable animation on prefers reduced motion
      '@media (prefers-reduced-motion: reduce)': {
        '.prefers-reduced-motion, .prefers-reduced-motion *': {
          animationDuration: '0.001ms !important',
          animationIterationCount: '1 !important',
          transitionDuration: '0.001ms !important',
        },
      },
      '.MuiIconButton-root.Mui-disabled': {
        color: alpha(common.white, 0.6),
      },
      '.hide': {
        display: 'none !important',
      },
      '.transparent': {
        opacity: 0,
      },
      '.noscroll': {
        overflow: 'hidden',
      },
      // truncate one line text with ellipsis
      '.ellipsis': ellipsis,
      'body.using-mouse :focus': {
        outline: 'none',
      },
      ':focus-visible': {
        outline: `3px solid ${blue.navy}`,
        borderRadius: 4,
      },
      /** This will hide the focus indicator if the element receives focus via the mouse,
           but it will still show up on keyboard focus.
      */
      ':focus:not(.focus-visible)': {
        outline: 'none',
      },
      '.noselect': {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        'user-select': 'none',
      },
      // add padding at the top of the page when there is no full bleed background image
      '.menu-offset': {
        paddingTop: 80,
      },
      '.ff-ubuntu': {
        fontFamily: fontFamily.ubuntu,
      },
    },
  });
});

const GlobalStyles: FunctionComponent = () => {
  if (
    typeof window !== 'undefined'
    && typeof document.body !== 'undefined'
  ) {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', () => {
      document.body.classList.remove('using-mouse');
    });

    if (isMobile) {
      document.documentElement.classList.add('mobile');
    }
  }
  styles({});
  return null;
};

export default GlobalStyles;
