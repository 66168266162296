import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { focusElement, scrollIntoView } from 'oaf-side-effects';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Inputs/Button';

const useSkipToContentButtonStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '41px',
      top: '9px',
      left: '35px',
      position: 'fixed',
      opacity: 0,
      zIndex: 1001,
      transform: 'translate3d(0, 0, 0)',
      pointerEvents: 'none',
      '&.focus': {
        transition: 'transform 0.3s',
        transform: 'translate3d(0, -135%, 0)',
        pointerEvents: 'auto',
        '&.focusAnimateIn': {
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        },
        '&.focusAnimateOut': {
          transform: 'translate3d(0, -135%, 0)',
          opacity: 1,
        },
      },
      // Some versions of Safari (15.4 and below) do not support focus-visible, so need to split it out
      '&:focus-visible': {
        transition: 'transform 0.3s',
        transform: 'translate3d(0, -135%, 0)',
        pointerEvents: 'auto',
        '&.focusAnimateIn': {
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        },
        '&.focusAnimateOut': {
          transform: 'translate3d(0, -135%, 0)',
          opacity: 1,
        },
      },
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: `${theme.palette.background.default}`,
        },
      },
    },
  });
});

/**
 * Button to focus on the main content for accessibility
 * Press Tab on keyboard to reveal the button
 */
const SkipToContentButton: FunctionComponent = () => {
  const classes = useSkipToContentButtonStyles({});
  const { t } = useTranslation('common');
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  const [focusAnimateIn, setFocusAnimateIn] = useState(false);
  const [focusAnimateOut, setFocusAnimateOut] = useState(false);

  const handleFocus = useCallback(() => {
    setFocused(true);
    setFocusAnimateOut(false);
    setTimeout(() => {
      setFocusAnimateIn(true);
    }, 100);

    focusElement(ref.current, true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocusAnimateIn(false);
    setFocusAnimateOut(true);

    setTimeout(() => {
      setFocusAnimateOut(false);
      setFocused(false);
    }, 350);
  }, []);

  const handleClick = useCallback(() => {
    const main = document.getElementById('main');
    focusElement(main).then(() => {
      scrollIntoView(main, true);
    });
  }, []);

  const handleMouseDown = useCallback((e) => {
    // This is to prevent the blur that occurs before click event on Safari only :(
    e.preventDefault();
  }, []);

  return (
    <Button
      className={clsx(classes.root, {
        focus: focused,
        focusAnimateIn,
        focusAnimateOut,
      })}
      buttonType="secondary"
      onFocus={handleFocus}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
      onClick={handleClick}
      ref={ref}
    >
      {t('button.skipToMainContent')}
    </Button>
  );
};

export default SkipToContentButton;
