import { get } from 'lodash';
import { Duration } from 'luxon';
import slugify from 'slugify';

import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';

import {
  transformEntityTypeToSeriesType,
  transformAvailability,
  transformCasts,
  transformConsumerAdvices,
  transformCrewToDirectors,
  transformLanguages,
  transformTextTracksToSubtitles,
  transformClassification, transformAnnouncement, getImageIdByProperties,
  transformSponsorship,
} from './CatalogApiCommonTransformer';

type SeriesData = components['schemas']['tvSeries'] | components['schemas']['newsSeries'] | components['schemas']['sportsSeries'];
type Season = SeriesData['seasons'][number];
type Episode = SeriesData['seasons'][number]['episodes'][number];

const transformEpisodes = (episode: Episode, seriesData: SeriesData, season?: Season): OnDemand.SlimEpisode => {
  const seriesType = transformEntityTypeToSeriesType(seriesData.entityType);
  const episodeAvailability = transformAvailability(episode.availability);
  const distributors = get(seriesData, 'distributors', []) as OnDemand.Distributor[];

  return {
    id: episode.mpxMediaID.toString(),
    cdpTitle: episode.cdpTitle,
    type: 'Episode',
    title: episode.title,
    description: episode.description,
    shortDescription: episode.description,
    duration: episode.duration ? Duration.fromISO(episode.duration).as('seconds') : undefined,
    distributors,
    classification: 'classificationID' in episode ? transformClassification(episode.classificationID) : null,
    consumerAdvices: transformConsumerAdvices(episode.consumerAdvices),
    consumerAdviceTexts: episode.consumerAdviceTexts,
    episodeNumber: episode.episodeNumber,
    route: {
      name: 'seriesEpisode',
      params: {
        seriesType,
        seriesSlug: seriesData.slug,
        ...(season?.slug && { seasonSlug: season.slug }),
        episodeSlug: episode.slug,
        id: episode.mpxMediaID,
      },
    },
    episodeData: {
      seriesId: seriesData.id,
      seriesSlug: seriesData.slug,
      seasonSlug: season ? season.slug : null,
      programName: seriesData.title,
      ...(season?.seasonNumber > 0 && { seasonNumber: season.seasonNumber }),
      episodeNumber: episode.episodeNumber,
      seriesType,
      seriesEntityType: seriesData.entityType,
      seriesRoute: {
        name: 'series',
        params: {
          seriesType,
          slug: seriesData.slug,
        },
      },
    },
    available: episodeAvailability.available,
    availableDate: episodeAvailability.availableDate,
    expiredDate: episodeAvailability.expiredDate,
    expired: episodeAvailability.expired,
    isLiveStream: false,
    odImageId: get(episode, 'images.0.id'),
  };
};

const transform = (seriesData: SeriesData): OnDemand.TvSeries => {
  const seriesType = transformEntityTypeToSeriesType(seriesData.entityType);

  let seasons: OnDemand.SeriesItemSeason[] = [];
  let episodes: OnDemand.SlimEpisode[] = [];

  if (seriesData.episodes && seriesData.episodes.length > 0) {
    episodes = seriesData.episodes.map((_episodes) => {
      return transformEpisodes(_episodes, seriesData);
    });
  }

  if (seriesData.seasons.length > 0) {
    seasons = seriesData.seasons
      .map((season) => {
        const seasonAvailability = transformAvailability(season.availability);
        return {
          id: season.id,
          name: season.title,
          seasonNumber: season.seasonNumber,
          seasonSlug: season.slug,
          numberOfEpisodesAvailable: season.episodes ? season.episodes.length : 0,
          availabilityStarts: seasonAvailability.availableDate,
          availabilityEnds: seasonAvailability.expiredDate,
          episodes: get(season, 'episodes', [])
            .map((_episodes) => {
              return transformEpisodes(_episodes, seriesData, season);
            })
            .filter((episode) => {
              // filter out episodes that are out of availability window
              return episode.available;
            }),
        };
      })
      .filter((season) => {
        // filter out seasons that don't have any episodes
        return season.episodes.length > 0;
      });

    if (episodes.length > 0) {
      seasons.push({
        id: 'specials',
        name: 'Specials',
        seasonNumber: null,
        seasonSlug: 'specials',
        episodes,
      });
    }
  } else if (episodes && episodes.length > 0) {
    seasons = [{
      id: 'specials',
      name: 'Season 1',
      seasonNumber: null,
      seasonSlug: 'specials',
      episodes,
    }];
  }

  const numberOfSeasons = seasons.length;

  const availability = transformAvailability(seriesData.availability);

  let featuredVideo = null;
  const featured = get(seriesData, 'featured');
  if (featured) {
    featuredVideo = {
      title: featured.tagLine,
      video: {
        id: featured.mpxMediaID.toString(),
        title: featured.title,
        shortDescription: featured.description,
        duration: 'duration' in featured ? Duration.fromISO(featured.duration).as('seconds') : undefined,
        odImageId: get(featured, 'images.0.id'),
      },
    };
  }

  const extras: OnDemand.SeriesItemExtra[] = seriesData.extras
    .map((extra): OnDemand.SeriesItemExtra => {
      const extraAvailability = transformAvailability(extra.availability);
      return {
        id: extra.mpxMediaID.toString(),
        title: extra.title,
        description: extra.description,
        shortDescription: extra.description,
        duration: extra.duration ? Duration.fromISO(extra.duration).as('seconds') : undefined,
        route: {
          name: 'video',
          params: {
            id: extra.mpxMediaID,
            slug: slugify(extra.title, {
              lower: true,
              strict: true,
            }),
          },
        },
        available: extraAvailability.available,
        availableDate: extraAvailability.availableDate,
        expiredDate: extraAvailability.expiredDate,
        expired: extraAvailability.expired,
        odImageId: get(extra, 'images.0.id'),
      };
    })
    .filter((extra) => {
      return extra.expired === false;
    });

  const distributors = get(seriesData, 'distributors', []) as OnDemand.Distributor[];

  const sponsorship = transformSponsorship(seriesData.sponsorships);

  return {
    id: seriesData.id,
    entityType: seriesData.entityType,
    type: 'TVSeries',
    title: seriesData.title,
    pageTitle: seriesData.title,
    slug: seriesData.slug,
    seriesType,
    route: {
      name: 'series',
      params: {
        seriesType,
        slug: seriesData.slug,
      },
    },
    cast: transformCasts(seriesData.casts),
    classification: 'classificationID' in seriesData ? transformClassification(seriesData.classificationID) : null,
    consumerAdvices: transformConsumerAdvices(seriesData.consumerAdvices),
    consumerAdviceTexts: seriesData.consumerAdviceTexts,
    countries: seriesData.countries,
    description: seriesData.description,
    directors: transformCrewToDirectors(seriesData.crews),
    distributors,
    metaDescription: seriesData.description.replace(/[\r\n]+/g, ' '),
    announcement: 'announcements' in seriesData ? transformAnnouncement(seriesData.announcements) : null,
    numberOfSeasons,
    seasons,
    odImageId: getImageIdByProperties(seriesData.images, { ratio: '16:9', type: 'KEY_ART' }),
    ogImageId: getImageIdByProperties(seriesData.images, { ratio: '16:9', type: 'BANNER' }),
    genres: seriesData.genres,
    hasClosedCaption: get(seriesData, 'textTracks').length > 0,
    hasAudioDescription: get(seriesData, 'hasAudioDescription', false),
    isHighDefinition: get(seriesData, 'qualities').includes('HD'),
    languages: transformLanguages(get(seriesData, 'languages')),
    isLiveStream: false,
    availability: {
      availabilityStarts: availability.availableDate,
      availabilityEnds: availability.expiredDate,
    },
    available: availability.available,
    expired: availability.expired,
    featuredVideo,
    trailerId: get(seriesData, 'trailers.0.mpxMediaID'),
    extras,
    subtitles: transformTextTracksToSubtitles(seriesData.textTracks),

    // these attributes not transformed because they are not available, left here because they are still expected in data layer
    subgenres: [],
    sponsorship,
  };
};

export default { transform };
