import { get } from 'lodash';

import { components } from '@@src/@types/CatalogueApi';
import OnDemand2 from '@@src/@types/OnDemand2';
import {
  transformCollectionItemClipV2,
  transformCollectionItemCollectionV2,
  transformCollectionItemEpisodeV2,
  transformCollectionItemMovieV2,
  transformCollectionItemPageV2,
  transformCollectionItemProgramV2,
  transformCollectionItemSeriesV2,
} from '@@src/transformers/CatalogApiCollectionTransformer';
import { operations } from '@@types/ContentSearch';

export function transformSearchResultToGridRow(result: operations['catalogue-search']['responses'][200]['content']['application/json']): OnDemand2.SearchResults {
  const items = result.items.map((item: components['schemas']['collectionItemSeries'] | components['schemas']['collectionItemMovie'] | components['schemas']['collectionItemEpisode'] | components['schemas']['collectionItemClip'] | components['schemas']['collectionItemProgram'] | components['schemas']['collectionItemCollection'] | components['schemas']['collectionItemPage']): OnDemand2.CollectionItem => {
    if (item.entityType === 'TV_SERIES' || item.entityType === 'SPORTS_SERIES' || item.entityType === 'NEWS_SERIES') {
      return transformCollectionItemSeriesV2(item, '16:9');
    }

    if (item.entityType === 'MOVIE') {
      return transformCollectionItemMovieV2(item, '16:9');
    }

    if (item.entityType === 'TV_PROGRAM' || item.entityType === 'SPORTS_PROGRAM' || item.entityType === 'NEWS_PROGRAM') {
      return transformCollectionItemProgramV2(item, '16:9');
    }

    if (item.entityType === 'TV_EPISODE' || item.entityType === 'SPORTS_EPISODE' || item.entityType === 'NEWS_EPISODE') {
      return transformCollectionItemEpisodeV2(item);
    }

    if (item.entityType === 'CLIP') {
      return transformCollectionItemClipV2(item);
    }

    if (item.entityType === 'CURATED_COLLECTION' || item.entityType === 'DYNAMIC_COLLECTION') {
      return transformCollectionItemCollectionV2(item);
    }

    if (item.entityType === 'PAGE') {
      return transformCollectionItemPageV2(item);
    }

    return null;
  });

  const total = get(result, 'meta.total', 0);

  return {
    title: 'Search Results',
    items,
    layout: 'grid',
    displayType: '16:9',
    total,
    nextCursor: result.meta?.nextCursor || null,
  };
}

export default transformSearchResultToGridRow;
