import { FunctionComponent } from 'react';

import OdShortcutBar from '../Buttons/OdShortcutBar';

export interface ShortcutItem {
  name: string;
  ondemandUri: string;
  type: 'OndemandLink';
}

export interface ShortcutShelfProps {
  title: string;
  items: ShortcutItem[];
  rowIndex: number;
}

const ShortcutShelf: FunctionComponent<ShortcutShelfProps> = (props) => {
  const { title, items, rowIndex } = props;

  return (
    <OdShortcutBar
      defaultValue={false}
      rowIndex={rowIndex}
      title={title}
      links={items.map((item) => {
        return {
          label: item.name,
          uri: item.ondemandUri,
        };
      })}
      highlight={false}
    />
  );
};

export default ShortcutShelf;
