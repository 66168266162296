import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Fragment, FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import OnDemand2 from '@@src/@types/OnDemand2';
import { useAppDispatch } from '@@src/hooks/store';
import { getCurrentBreakpoint } from '@@src/styles/breakpoints';
import grey from '@@src/styles/colors/grey';
import padding from '@@src/styles/padding';
import DataLayer from '@@src/utils/DataLayer';
import { getTileSpecV2, tileItemsPerView16x9, tileItemsPerView2x3 } from '@@src/utils/helpers';
import { clearRecommendation, setRecommendation } from '@@stores/RecommendationStore';

import { ReactComponent as RightArrowIcon } from '../../images/icons/right-arrow.svg';
import { tileAnimationConfig } from '../Tiles/TilesV2/BaseTile';
import CollectionItemTile from '../Tiles/TilesV2/CollectionItemTile';
import ShelfTitle from './ShelfTitle';

export interface CarouselShelfV2Props {
  collection: OnDemand2.Collection;
  rowIndex: number;
}

// transition duration & delay for the zoom animation
export const transitionDuration = `${tileAnimationConfig.transitionDuration}ms`;

let tileWidth;

export const sharedTileContainerLayoutStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    'tileContainerLayout2:3': {
      [theme.breakpoints.down('xl')]: {
        minWidth: `${100 / tileItemsPerView2x3.xl}%`,
        flexBasis: `${100 / tileItemsPerView2x3.xl}%`,
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: `${100 / tileItemsPerView2x3.lg}%`,
        flexBasis: `${100 / tileItemsPerView2x3.lg}%`,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: `${100 / tileItemsPerView2x3.md}%`,
        flexBasis: `${100 / tileItemsPerView2x3.md}%`,
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: `${100 / tileItemsPerView2x3.sm}%`,
        flexBasis: `${100 / tileItemsPerView2x3.sm}%`,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: `${100 / tileItemsPerView2x3.xs}%`,
        flexBasis: `${100 / tileItemsPerView2x3.xs}%`,
      },
      flexGrow: 0,
      flexShrink: 0,
      transition: `${transitionDuration} transform cubic-bezier(0.33, 1, 0.68, 1)`,
      '&:focus-visible': {
        outline: 'none',
      },
    },
    'tileContainerLayout16:9': {
      [theme.breakpoints.down('xl')]: {
        minWidth: `${100 / tileItemsPerView16x9.xl}%`,
        flexBasis: `${100 / tileItemsPerView16x9.xl}%`,
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: `${100 / tileItemsPerView16x9.lg}%`,
        flexBasis: `${100 / tileItemsPerView16x9.lg}%`,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: `${100 / tileItemsPerView16x9.md}%`,
        flexBasis: `${100 / tileItemsPerView16x9.md}%`,
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: `${100 / tileItemsPerView16x9.sm}%`,
        flexBasis: `${100 / tileItemsPerView16x9.sm}%`,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: `${100 / tileItemsPerView16x9.xs}%`,
        flexBasis: `${100 / tileItemsPerView16x9.xs}%`,
      },
      flexGrow: 0,
      flexShrink: 0,
      transition: `${transitionDuration} transform cubic-bezier(0.33, 1, 0.68, 1)`,
      '&:focus-visible': {
        outline: 'none',
      },
    },
  });
});

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    shelfContent: {
      display: 'flex',
      flexDirection: 'row',
    },
    shelfContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    slider: {
      padding: `0 ${padding.container.xs}px`,
      position: 'relative',
      transition: `transform ${transitionDuration}`,
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: padding.container.sm,
        paddingRight: padding.container.sm,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: padding.container.md,
        paddingRight: padding.container.md,
        paddingBottom: padding.container.md - 40,
      },
      '&:hover $handle': {
        opacity: 1,
      },
      '&:hover $handleIcon': {
        opacity: 1,
      },
    },
    sliderContent: {
      transition: '500ms transform', // sliding animation
    },
    handle: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 60,
      display: 'flex',
      background: alpha(grey.darkBlackPearl, 0.7),
      cursor: 'pointer',
      fontSize: '3rem',
      zIndex: 1,
      '&:hover $handleIcon, &:focus $handleIcon': {
        opacity: 1,
      },
      border: 0,
      opacity: 1,
      [theme.breakpoints.up('xs')]: {
        marginTop: '30px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '35px',
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: '38px',
      },
    },
    handlePrev: {
      padding: 0,
      left: 0,
      zIndex: 1,
      '& $handleIcon': {
        transform: 'rotate(180deg)',
      },
    },
    handleNext: {
      padding: 0,
      right: 0,
    },
    handleIcon: {
      margin: 'auto',
      opacity: 0,
      transition: '200ms opacity',
      // on non hover media query, the icon is always visible
      '@media (hover:none)': {
        opacity: 0.7,
      },
    },
    shelfTitle: {
      marginBottom: '0.875rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.625rem',
      },
    },
    scrollWrapper: {
      marginLeft: -4,
      marginRight: -4,
      // to show a preview of the first tile on the next page on mobile web view
      paddingRight: isMobile ? `${tileWidth / 4}px` : '0',
      overflowX: isMobile ? 'scroll' : 'unset',
      // to prevent the vertical overflow from being cut off https://stackoverflow.com/a/39554003
      paddingTop: '50px',
      marginTop: '-50px',
      paddingBottom: '30px',
      marginBottom: '-30px',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    link: {
      padding: '0 4px',
    },
  });
});

export function getStartIndex(page, itemsPerView, itemsLength) {
  if (page === 1) return 0;

  let startIndex = (page - 1) * itemsPerView;

  const maxNoOfPages = Math.ceil(itemsLength / itemsPerView);

  // if on the last page
  if (page >= maxNoOfPages) {
    startIndex = itemsLength - itemsPerView;
  }

  return startIndex;
}

export function getEndIndex(page, itemsPerView, itemsLength) {
  let endIndex = page * itemsPerView - 1;

  const maxNoOfPages = Math.ceil(itemsLength / itemsPerView);

  // if on last page
  if (page >= maxNoOfPages) {
    endIndex = itemsLength - 1;
  }

  return endIndex;
}

const CarouselShelfV2: FunctionComponent<CarouselShelfV2Props> = (props) => {
  const {
    collection,
    rowIndex,
  } = props;

  const dispatch = useAppDispatch();
  const breakpoint = getCurrentBreakpoint();
  const { width: windowWidth } = useWindowSize();
  const tileSpec = getTileSpecV2(collection.displayType, windowWidth, breakpoint);
  const {
    width,
    itemsPerView,
    growRatio,
  } = tileSpec;

  tileWidth = width;

  const shelfTitle = collection.title;
  const classes = useStyles(props);
  const tileContainerClasses = sharedTileContainerLayoutStyles(props);
  const { t } = useTranslation('common');

  const sliderContentRef = useRef<HTMLDivElement>();
  const [page, setPage] = useState<number>(1);
  const [showPrevButton, setShowPrevButton] = useState<boolean>(!isMobile && (page > 1));
  const [showNextButton, setShowNextButton] = useState<boolean>(!isMobile && (page * itemsPerView < collection?.items?.length));

  useEffect(() => {
    const sliderContent = sliderContentRef.current;
    const updateShelfButtonDisplay = () => {
      setShowPrevButton(!isMobile && (page > 1));
      setShowNextButton(!isMobile && (page * itemsPerView < collection.items.length));
    };

    if (sliderContent) {
      sliderContent.addEventListener('transitionend', updateShelfButtonDisplay);
    }

    return () => {
      if (sliderContent) {
        sliderContent.removeEventListener('transitionend', updateShelfButtonDisplay);
      }
    };
  }, [collection.items.length, itemsPerView, page]);

  // ensure that the first and last tiles in the view activate in the right direction
  useLayoutEffect(() => {
    if (sliderContentRef.current) {
      Array.from(sliderContentRef.current.children).forEach((child, index) => {
        const activableTile = child.querySelector('.activableTile') as HTMLDivElement;

        const startIndex = getStartIndex(page, itemsPerView, collection.items.length);
        const endIndex = getEndIndex(page, itemsPerView, collection.items.length);

        if (index === startIndex) {
          Object.assign(activableTile.style, { transformOrigin: 'left center' });
        } else if (index === endIndex) {
          Object.assign(activableTile.style, { transformOrigin: 'right center' });
        } else {
          Object.assign(activableTile.style, { transformOrigin: 'center center' });
        }
      });
    }
  }, [collection.items.length, itemsPerView, page, collection.title]);

  // Focusing on the first or last fully visible tile
  const focusOnTileAfterSliding = useCallback((focusOn: 'first' | 'last' = 'first') => {
    const getSliderTiles = () => {
      if (sliderContentRef.current) {
        return Array.from(sliderContentRef.current.querySelectorAll('a'));
      }
      return [];
    };

    const getVisibleTiles = (tiles) => {
      return tiles.filter((tile) => {
        const { left, right } = tile.getBoundingClientRect();
        return left >= 0 && right <= window.innerWidth;
      });
    };

    const getTileIndex = (visibleTiles) => {
      let index = focusOn === 'first' ? 0 : visibleTiles.length - 1;
      const { left, right } = visibleTiles[index].getBoundingClientRect();

      // If tile is partially visible, check the next one
      if (focusOn === 'first' && left < 0) {
        index += 1;
      } else if (focusOn === 'last' && right > window.innerWidth) {
        index -= 1;
      }

      return index;
    };

    const focusTile = (tile) => {
      if (tile) {
        tile.focus();
      }
    };

    const sliderTiles = getSliderTiles();
    const visibleTiles = getVisibleTiles(sliderTiles);

    if (visibleTiles.length > 0) {
      const tileIndex = getTileIndex(visibleTiles);
      focusTile(visibleTiles[tileIndex]);
    }
  }, []);

  const goToPage = useCallback((newPage) => {
    let x = -100 * (newPage - 1);
    const lastPage = newPage * itemsPerView >= collection.items.length;
    if (lastPage) {
      x = -100 * (collection.items.length / itemsPerView - 1);
    }

    sliderContentRef.current.style.transform = `translate3d(${x}%, 0, 0)`;
  }, [collection.items.length, itemsPerView]);

  const snapToNextPage = useCallback(() => {
    goToPage(page + 1);
    setPage(page + 1);
    if (sliderContentRef.current) {
      sliderContentRef.current.addEventListener('transitionend', () => {
        focusOnTileAfterSliding('first');
      });

      sliderContentRef.current.removeEventListener('transitionend', () => {
        focusOnTileAfterSliding('first');
      });
    }
  }, [goToPage, page, focusOnTileAfterSliding]);

  const snapToPreviousPage = useCallback(() => {
    goToPage(page - 1);
    setPage(page - 1);
    if (sliderContentRef.current) {
      sliderContentRef.current.addEventListener('transitionend', () => {
        focusOnTileAfterSliding('last');
      });

      sliderContentRef.current.removeEventListener('transitionend', () => {
        focusOnTileAfterSliding('last');
      });
    }
  }, [goToPage, page, focusOnTileAfterSliding]);

  const handleClickNext = () => {
    snapToNextPage();
  };

  const handleClickPrevious = () => {
    snapToPreviousPage();
  };

  // when items per view changes, change the page
  useEffect(() => {
    const lastPage = Math.floor(collection.items.length / itemsPerView) + 1;
    if (page > lastPage) {
      goToPage(lastPage);
      setPage(lastPage);
    }
  }, [collection.items.length, goToPage, itemsPerView, page]);

  /**
   * Ensure that the tile being activated has a higher z-index than other tiles.
   * Ensure that the surrounding tiles move left/right as specified
   * @param index
   * @returns
   */
  const activate = useCallback((index) => {
    // no zoom in animation for small breakpoints
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return;
    }

    // start and end tile index in the current view's row
    const startIndex = getStartIndex(page, itemsPerView, sliderContentRef.current.children.length);
    const endIndex = getEndIndex(page, itemsPerView, sliderContentRef.current.children.length);

    // wider range to ensure that the start and end tiles do not overlap when tiles in the middle of the shelf are activated
    const startActivationIndex = startIndex - 1;
    const endActivationIndex = endIndex + 1;

    const calculatePercentToMove = (i) => {
      if (i === index) return 0;

      if (index === startIndex && i !== startActivationIndex) {
        // full movement to the right
        return -(1 - growRatio) * 100;
      }
      if (index === endIndex && i !== endActivationIndex) {
        // full movement to the left
        return (1 - growRatio) * 100;
      }
      if (i < index && index !== startIndex) {
        // half movement to the left
        return ((1 - growRatio) / 2) * 100;
      }
      if (i > index && index !== endIndex) {
        // half movement to the right
        return -((1 - growRatio) / 2) * 100;
      }

      return 0;
    };

    const applyTransform = (item, percentToMove) => {
      if (item) {
        Object.assign((item as HTMLElement).style, {
          zIndex: 1,
          transform: `translate3d(${percentToMove}%, 0, 0)`,
        });
      }
    };

    for (let i = startActivationIndex; i <= endActivationIndex; i += 1) {
      const item = sliderContentRef.current.children[i];

      // items around the index
      const percentToMove = calculatePercentToMove(i);
      applyTransform(item, percentToMove);
    }
  }, [breakpoint, growRatio, itemsPerView, page]);

  /**
   * Move tiles back to original positions and scaling.
   * @param index
   */
  const deactivate = (index) => {
    Array.from(sliderContentRef.current.children).forEach((child) => {
      Object.assign((child as HTMLElement).style, {
        transform: 'translate3d(0, 0, 0)',
        zIndex: 0,
      });
    });

    Object.assign((sliderContentRef.current.children[index].firstChild as HTMLElement).style, {
      transform: 'scale(1)',
    });
  };

  if (collection.items && collection.items.length > 0) {
    let tileContainerLayout;
    if (collection.displayType === '2:3') {
      tileContainerLayout = tileContainerClasses['tileContainerLayout2:3'];
    } else if (collection.displayType === '16:9') {
      tileContainerLayout = tileContainerClasses['tileContainerLayout16:9'];
    }

    return (
      <div className={classes.slider}>
        <div className={classes.shelfTitle}>
          <ShelfTitle
            name={collection.title}
            route={collection.route}
          />
        </div>
        <div>
          {
            showPrevButton && (
              <div>
                <button
                  aria-label={t('navigation.prevItemHandleLabel')}
                  type="button"
                  className={clsx(classes.handle, classes.handlePrev)}
                  onClick={handleClickPrevious}
                >
                  <RightArrowIcon className={classes.handleIcon}/>
                </button>
              </div>
            )
          }
          <div className={classes.scrollWrapper}>
            <div className={clsx(classes.shelfContainer, classes.sliderContent, 'prefers-reduced-motion')} ref={sliderContentRef}>
              {
                collection.items.map((collectionItem, index) => {
                  const { displayType } = collection;
                  const shelfLocation = `carousel:${collection.title}:${rowIndex + 1}:${index + 1}`;

                  let canBeFocused = (index < page * itemsPerView) && (index >= (page - 1) * itemsPerView);

                  if (page * itemsPerView >= collection.items.length) {
                    canBeFocused = index >= collection.items.length - itemsPerView;
                  }

                  let personalisation;
                  if (collectionItem?.recommendationId) {
                    personalisation = {
                      recommendationId: collectionItem.recommendationId,
                      recommendationVariantName: collectionItem.recommendationVariant,
                    };
                  }

                  const tileProps = {
                    className: tileContainerLayout,
                    classes: {
                      link: classes.link,
                    },
                    displayType,
                    canBeFocused,
                    onActivate: () => {
                      activate(index);
                    },
                    onDeactivate: () => {
                      deactivate(index);
                    },
                    onClick() {
                      if (collectionItem?.recommendationId) {
                        const {
                          recommendationId, recommendationVariant, entityType, id,
                        } = collectionItem;
                        dispatch(setRecommendation({
                          recommendationId,
                          recommendationVariantName: recommendationVariant,
                          target: {
                            entityType,
                            id,
                          },
                        }));
                      } else {
                        dispatch(clearRecommendation());
                      }
                      DataLayer.setClickSource(shelfTitle, shelfLocation, rowIndex + 1, index + 1);
                    },
                    onKeyPress(e: KeyboardEvent) {
                      if (e.key === 'Enter') {
                        DataLayer.setClickSource(shelfTitle, shelfLocation, rowIndex + 1, index + 1);
                      }
                    },
                    onFavourited() {
                      DataLayer.events.addToFavouritesV2(collectionItem, shelfLocation, personalisation);
                    },
                    onUnfavourited() {
                      DataLayer.events.removeFromFavouritesV2(collectionItem, shelfLocation, personalisation);
                    },
                  };

                  return (
                    <Fragment key={collectionItem.id}>
                      <CollectionItemTile item={collectionItem} props={tileProps}/>
                    </Fragment>
                  );
                })
              }
            </div>
          </div>
          {
            showNextButton && (
              <div>
                <button
                  aria-label={t('navigation.nextItemHandleLabel')}
                  type="button"
                  className={clsx(classes.handle, classes.handleNext)}
                  onClick={handleClickNext}
                >
                  <RightArrowIcon className={classes.handleIcon}/>
                </button>
              </div>
            )
          }
        </div>
      </div>
    );
  }

  return null;
};

export default CarouselShelfV2;
