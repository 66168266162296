import { SupportedLanguage } from '../../i18n';

const roboto = 'Roboto, Helvetica, Arial, sans-serif';
const ubuntu = 'Ubuntu, Helvetica, Arial, sans-serif';

// Primary and secondary can have LOTE fonts
const primary = roboto;
const secondary = ubuntu;

const fonts = {
  primary,
  secondary,
  roboto,
  ubuntu,
};

export function addLanguageSpecificFont(lang: SupportedLanguage) {
  switch (lang) {
    case 'ar':
      fonts.secondary = `Noto Sans Arabic, ${ubuntu}`;
      fonts.primary = `Noto Sans Arabic, ${roboto}`;
      break;
    case 'zh-Hans':
      fonts.secondary = `Noto Sans SC, ${ubuntu}`;
      fonts.primary = `Noto Sans SC, ${roboto}`;
      break;
    case 'zh-Hant':
      fonts.secondary = `Noto Sans TC, ${ubuntu}`;
      fonts.primary = `Noto Sans TC, ${roboto}`;
      break;
    case 'ko':
      fonts.secondary = `Noto Sans KR, ${ubuntu}`;
      fonts.primary = `Noto Sans KR, ${roboto}`;
      break;
    case 'vi':
      fonts.secondary = `Noto Sans, ${ubuntu}`;
      fonts.primary = `Noto Sans, ${roboto}`;
      break;
    case 'hi':
      fonts.secondary = `Noto Sans Devanagari, ${ubuntu}`;
      fonts.primary = `Noto Sans Devanagari, ${roboto}`;
      break;
    case 'en':
    default:
      fonts.secondary = ubuntu;
      fonts.primary = roboto;
      break;
  }
}

export default fonts;
