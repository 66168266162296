import { Box, Card, CardContent, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha, darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, ReactElement, useRef } from 'react';

import fontFamily from '../../styles/typography/fontFamily';
import LiveBadge from '../DataDisplay/Badge/LiveBadge';
import LiveCardMedia from '../Utils/LiveCardMedia';

const useStyles = makeStyles<Theme, LiveTvTilePrimaryProps>((theme: Theme) => {
  return createStyles({
    root: {
      width: 1022,
      height: 410,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: 628,
        height: 536,
      },
      [theme.breakpoints.down('xs')]: {
        width: 294,
        height: 370,
      },
      borderRadius: 4,
    },
    contentContainer: {
      width: 292,
    },
    media: {
      position: 'relative',
      width: 730,
      height: 410,
      [theme.breakpoints.down('sm')]: {
        width: 628,
        height: 355,
      },
      [theme.breakpoints.down('xs')]: {
        width: 294,
        height: 160,
      },
    },
    statusBadge: {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '3px 20px 4px 10px',
      borderRadius: '0 0 18px 0',
    },
    status: {
      fontFamily: fontFamily.secondary,
      fontWeight: 500,
    },
    titleContainer: {
      flexWrap: 'nowrap',
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 500,
        fontSize: '1.125rem',
      },
    },
    time: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      color: alpha(theme.palette.primary.main, 0.7),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
      [theme.breakpoints.only('sm')]: {
        textAlign: 'right',
      },
    },
    muted: {
      opacity: 0.5,
    },
    cardContent: {
      width: '100%',
    },
    metadata: {
      fontSize: '1rem',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    description: {
      color: darken(theme.palette.primary.main, 0.12),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 11,
      '-webkit-box-orient': 'vertical',
      [theme.breakpoints.down('sm')]: {
        '-webkit-line-clamp': 4,
        fontSize: '0.875rem',
        marginTop: 0,
      },
      [theme.breakpoints.down('sm')]: {
        '-webkit-line-clamp': 3,
        fontSize: '0.875rem',
        marginTop: 0,
      },
    },
    classification: {},
    playButton: {
      width: 108,
      height: 109,
      [theme.breakpoints.down('sm')]: {
        width: 48,
        height: 49,
      },
      '$root:hover &': {
        opacity: 0.8,
      },
      '$root:hover &:hover': {
        opacity: 0.9,
      },
    },
    playButtonOverlay: {
      '$root:hover &': {
        opacity: 1,
      },
    },
  });
});

export interface LiveTvTilePrimaryProps {
  /** The title of the live stream. */
  title: string;
  /** The description of the live stream. */
  description: string;
  /** The url for the image. */
  imageUrl: string;
  badgeColor: 'warning' | 'error' | 'success';
  status: string | ReactElement;
  available: boolean;
  time: string;
  metadataComponent: ReactElement;
  progressPercent?: number;
  clickUrl?: string;
  playButtonLabel?: string;
}

const LiveTvTilePrimary: FunctionComponent<LiveTvTilePrimaryProps> = (props) => {
  const classes = useStyles(props);

  const {
    title,
    imageUrl,
    description,
    badgeColor,
    status,
    available,
    time,
    metadataComponent,
    progressPercent,
    clickUrl,
    playButtonLabel,
  } = props;

  const cardEl = useRef<HTMLDivElement>();
  const availability = (
    <Typography variant="body1" component="div" className={classes.time}>
      {time}
    </Typography>
  );

  return (
    <Card className={classes.root} square ref={cardEl}>
      <Grid container spacing={0} className={clsx({ [classes.muted]: !available })}>
        <Grid item xs={12} md>
          <LiveCardMedia
            title={title}
            imageUrl={imageUrl}
            progressPercent={progressPercent}
            clickUrl={available ? clickUrl : undefined}
            classes={{ media: classes.media, playButton: classes.playButton, playButtonOverlay: classes.playButtonOverlay }}
            playButtonLabel={playButtonLabel}
          />
        </Grid>
        <Grid item xs={12} md className={classes.contentContainer}>
          <CardContent classes={{ root: classes.cardContent }}>

            <Grid container className={clsx('ff-ubuntu', classes.titleContainer)}>
              <Grid item xs={12} sm={9} md={12}>
                <Typography variant="h5" component="h3" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              <Hidden xsDown mdUp>
                <Grid item sm={3}>
                  {availability}
                </Grid>
              </Hidden>
            </Grid>

            <Grid>
              <Grid item className={classes.metadata}>
                {metadataComponent}
              </Grid>
              <Hidden only="sm">
                <Grid item>
                  {availability}
                </Grid>
              </Hidden>
              <Grid item>
                <Box mt={1}>
                  <Typography variant="body2" className={classes.description}>
                    {description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

          </CardContent>
        </Grid>
      </Grid>
      {status && (
        <Box className={classes.statusBadge}>
          <LiveBadge color={badgeColor} overlap="rectangular">
            <Typography variant="body2" className={classes.status}>
              {status}
            </Typography>
          </LiveBadge>
        </Box>
      )}
    </Card>
  );
};

export default LiveTvTilePrimary;
