import { IconButton } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronUp from '@@icons/ChevronUp';
import blue from '@@styles/colors/blue';
import Velocity from '@@utils/Velocity';
import { onButtonKeyListener } from '@@utils/helpers';

import { ReactComponent as RightArrowIcon } from '../../images/icons/right-arrow.svg';
import grey from '../../styles/colors/grey';
import OdContainer from '../Utils/OdContainer';

export const liveTileSliderStyles = (theme: Theme) => {
  return createStyles({
    root: {},
    slider: {
      position: 'relative',
      marginTop: theme.spacing(1),
    },
    sliderContent: {
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      overflowY: 'hidden',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& > div': {
        whiteSpace: 'normal',
        marginRight: theme.spacing(2),
      },
    },
    sliderButton: {
      top: 0,
      bottom: 0,
      cursor: 'pointer',
      display: 'flex',
      zIndex: 99,
      position: 'absolute',
      fontSize: '3rem',
      opacity: 0,
      background: 'rgba(11, 13, 15, 0.7)',
      '&:focus': {
        backgroundColor: grey.darkBlackPearl,
        opacity: 0.8,
      },
      '&:focus-visible': {
        outline: `3px solid ${blue.navy} !important`,
        borderRadius: 4,
      },
      '@media (hover: hover)': {
        '$slider:hover &': {
          backgroundColor: grey.darkBlackPearl,
          opacity: 0.7,
        },
        '$slider:hover &:hover': {
          opacity: 0.8,
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        '@media (hover: hover)': {
          display: 'block',
        },
      },
    },
    sliderIcon: {
      position: 'absolute',
      top: 0,
      height: '100%',
      borderRadius: 0,
      transition: 'opacity 200ms',
    },
    prevButton: {
      left: 0,
      '& .MuiIconButton-label': {
        transform: 'rotate(-90deg)',
      },
    },
    nextButton: {
      right: 0,
      '& .MuiIconButton-label': {
        transform: 'rotate(90deg)',
      },
    },
    arrowButton: {
      width: 48,
      padding: 0,
      cursor: 'pointer',
      '&$prevButton .MuiIconButton-label': {
        transform: 'rotate(180deg)',
      },
      '&$nextButton .MuiIconButton-label': {
        transform: 'rotate(0deg)',
      },
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
      },
    },
  });
};
export const useStyles = makeStyles(liveTileSliderStyles);

export interface LiveTileSliderProps {
  children: any;
  getPrevOffset: (sliderRef?: HTMLDivElement) => number;
  getNextOffset: (sliderRef?: HTMLDivElement) => number;
  buttonVariant?: 'chevron' | 'arrow'
  titleComponent?: any;
  classes?: {
    root?: string;
    slider?: string;
    sliderContent?: string;
    sliderButton?: string;
    sliderIcon?: string;
  };
}

const LiveTileSlider: FunctionComponent<LiveTileSliderProps> = (props) => {
  const {
    /* istanbul ignore next */
    children, titleComponent = null, getPrevOffset, getNextOffset, buttonVariant = 'chevron',
  } = props;
  const classes = useStyles(props);
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  const [showPrevButton, setShowPrevButton] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState<boolean>(false);

  const sliderContentEl = useRef<HTMLDivElement>();

  const { t } = useTranslation('common');

  useEffect(() => {
    const _sliderContentEl = sliderContentEl.current;
    function updateSliderButtonShouldDisplay() {
      setShowPrevButton(_sliderContentEl.scrollLeft > 0);
      setShowNextButton(_sliderContentEl.scrollWidth - _sliderContentEl.offsetWidth > _sliderContentEl.scrollLeft);
    }

    updateSliderButtonShouldDisplay();

    window.addEventListener('resize', updateSliderButtonShouldDisplay);
    _sliderContentEl.addEventListener('scroll', updateSliderButtonShouldDisplay);

    return () => {
      window.removeEventListener('resize', updateSliderButtonShouldDisplay);
      _sliderContentEl.removeEventListener('scroll', updateSliderButtonShouldDisplay);
    };
  }, []);

  // Focusing on the first fully visible tile
  /* istanbul ignore next */
  const focusOnTileAfterSliding = useCallback((focusOn: 'first' | 'last' = 'first') => {
    const sliderTiles = Array.from(sliderContentEl.current.querySelectorAll('a'));
    const visibleTiles = sliderTiles.filter((tile) => {
      const tilePositions = tile.getBoundingClientRect();
      return tilePositions.left >= 0 && tilePositions.right <= window.innerWidth;
    });

    if (visibleTiles && visibleTiles.length > 0) {
      let tileIndex;
      if (focusOn === 'first') {
        tileIndex = 0;
      } else {
        tileIndex = visibleTiles.length - 1;
      }
      const tilePositions = visibleTiles[tileIndex].getBoundingClientRect();

      // If tile is partially visible, check next one
      if (focusOn === 'first') {
        if (tilePositions.left < 0) {
          tileIndex += 1;
        }
      } else if (tilePositions.right > window.innerWidth) {
        tileIndex -= 1;
      }

      if (visibleTiles[tileIndex]) {
        visibleTiles[tileIndex].focus();
      }
    }
  }, []);

  const prevButtonHandler = useCallback(() => {
    if (!isAnimated) {
      setIsAnimated(true);
      // scroll all the way to the left
      /* istanbul ignore next */
      Velocity(
        sliderContentEl.current,
        { scrollLeft: `${getPrevOffset(sliderContentEl.current)}px` },
        'ease-in-out',
        () => { focusOnTileAfterSliding('last'); },
      ).then(() => {
        setIsAnimated(false);
      });
    }
  }, [focusOnTileAfterSliding, getPrevOffset, isAnimated]);

  const nextButtonHandler = useCallback(() => {
    if (!isAnimated) {
      setIsAnimated(true);
      // scroll all the way to the right
      /* istanbul ignore next */
      Velocity(
        sliderContentEl.current,
        { scrollLeft: `${getNextOffset(sliderContentEl.current)}px` },
        'ease-in-out',
        () => { focusOnTileAfterSliding('first'); },
      ).then(() => {
        setIsAnimated(false);
      });
    }
  }, [focusOnTileAfterSliding, getNextOffset, isAnimated]);

  let IconComponent;
  if (buttonVariant === 'arrow') {
    IconComponent = RightArrowIcon;
  } else {
    IconComponent = ChevronUp;
  }

  return (
    <OdContainer className={classes.root}>
      {titleComponent}
      <div className={classes.slider} data-testid="live-tile-slider">
        {
          /* istanbul ignore next */
          showPrevButton && (
            <IconButton
              className={clsx(classes.sliderIcon, classes.sliderButton, classes.prevButton, buttonVariant === 'arrow' && classes.arrowButton)}
              aria-label={t('navigation.prevItemHandleLabel')}
              tabIndex={0}
              onKeyPress={onButtonKeyListener(prevButtonHandler)}
              onClick={prevButtonHandler}
            >
              <IconComponent/>
            </IconButton>
          )
        }
        <div className={classes.sliderContent} ref={sliderContentEl}>
          {children}
        </div>
        {
          /* istanbul ignore next */
          showNextButton && (
            <IconButton
              className={clsx(classes.sliderIcon, classes.sliderButton, classes.nextButton, buttonVariant === 'arrow' && classes.arrowButton)}
              aria-label={t('navigation.nextItemHandleLabel')}
              tabIndex={0}
              onKeyPress={onButtonKeyListener(nextButtonHandler)}
              onClick={nextButtonHandler}
            >
              <IconComponent/>
            </IconButton>
          )
        }
      </div>
    </OdContainer>
  );
};

export default LiveTileSlider;
