import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { generatePathFromLinkProps } from '@@src/routes';
import blue from '@@src/styles/colors/blue';
import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

import grey from '../../styles/colors/grey';
import { PromoBlockRow } from '../PageRows/PageRow';
import OdContainer from '../Utils/OdContainer';
import { MrecWithLazyLoad } from './Mrec';

interface PromoBlockProps {
  row: PromoBlockRow;
  rowIndex: number;
}

const useStyles = makeStyles<Theme, PromoBlockProps>((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: '53px 0 53px 0',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    promo: {
      maxWidth: 1468,
      width: '100%',
      overflow: 'hidden',
      boxSizing: 'border-box',
      borderRadius: 4,
    },
    promoImage: {
      width: '100%',
      height: 250,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      transition: 'all .5s',
      backgroundSize: '1468px 250px',
      backgroundImage: (props) => {
        return `url("${props.row.promo.images.md}")`;
      },
      [theme.breakpoints.down('sm')]: {
        backgroundSize: '923px 250px',
        backgroundImage: (props) => {
          return `url("${props.row.promo.images.sm}")`;
        },
      },
      [theme.breakpoints.down('xs')]: {
        backgroundSize: '718px 250px',
        backgroundImage: (props) => {
          return `url("${props.row.promo.images.xs}")`;
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          transform: 'scale(1.075)',
        },
      },
    },
    adBlock: {
      width: 300,
      height: 250,
      marginLeft: theme.spacing(1),
      backgroundColor: grey.blackpearl,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginTop: 48,
      },
    },
    promoLink: {
      width: '100%',
      height: '100%',
      display: 'block',
      boxSizing: 'border-box',
      '&:focus-visible': {
        outline: `3px solid ${blue.navy}`,
        outlineOffset: '-3px',
      },
    },
  });
});

const PromoBlock: FunctionComponent<PromoBlockProps> = (props) => {
  const classes = useStyles(props);
  const { row, rowIndex } = props;
  const { ad } = row;
  const hasImage = !Object.values(row.promo.images).every((o) => { return o === null; });
  if (!hasImage) {
    Logger.error('Promo block has no image');
    return null;
  }

  const { route } = row.promo;
  let promoPath;

  if (route) {
    promoPath = generatePathFromLinkProps(route);
  } else {
    promoPath = get(row, 'promo.click.url', '');
    if (promoPath) {
      promoPath = promoPath.replace('https://www.sbs.com.au/ondemand', '');
    }
  }

  if (!promoPath) {
    Logger.error('Promo block click URL empty or null');
    return null;
  }

  const handleClick = () => {
    DataLayer.setClickSource('promo block', 'promo block', rowIndex + 1, 1);
  };

  return (
    <OdContainer className={classes.root}>
      <div className={classes.promo}>
        <Link
          to={promoPath}
          className={classes.promoLink}
          tabIndex={0}
          onClick={handleClick}
        >
          <div
            className={classes.promoImage}
            data-testid="promo-block-image"
            aria-label={row.promo.name}
          />
        </Link>
      </div>
      {
        ad && (
          <div className={classes.adBlock}>
            <MrecWithLazyLoad targeting={ad.targeting} pos={ad.pos} pageSlug={ad.pageSlug}/>
          </div>
        )
      }
    </OdContainer>
  );
};

export default PromoBlock;
