import { useEffect, useState } from 'react';

import { useAppSelector } from '@@src/hooks/store';
import { getRecommendation, RecommendationData } from '@@stores/RecommendationStore';
import OnDemand from '@@types/OnDemand';
import { PersonalisationData } from '@@utils/DataLayer';

const usePersonalisation = (video: OnDemand.Video | OnDemand.TvSeries) => {
  const [personalisation, setPersonalisation] = useState<PersonalisationData>({
    recommendationId: '',
    recommendationVariantName: '',
  });

  const recommendation = useAppSelector<RecommendationData>(getRecommendation);

  useEffect(() => {
    if (!recommendation) {
      return;
    }

    if (('catalogueId' in video && recommendation.target.id === video.catalogueId) || recommendation.target.id === video.id) {
      setPersonalisation({
        recommendationId: recommendation.recommendationId,
        recommendationVariantName: recommendation.recommendationVariantName,
      });
    }
  }, [recommendation, video]);

  return personalisation;
};

export default usePersonalisation;
