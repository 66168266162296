import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

/**
 * Calculate the progress based on start date, end date and the current date/time for livestreams
 * @param startDateTime - start date UTC ISO string
 * @param endDateTime - end date UTC ISO string
 */
const useCalculateProgress = (start: string, end: string) => {
  const startDateTime = DateTime.fromISO(start);
  const endDateTime = DateTime.fromISO(end);

  const [progressPercent, setProgressPercent] = useState(0);
  useEffect(() => {
    let progressTimeout;
    let percentProgress;
    let diffNow = startDateTime.diffNow().as('milliseconds');
    if (diffNow <= 0) {
      const streamDuration = endDateTime.toMillis() - startDateTime.toMillis();
      if (streamDuration > 0) {
        // Calculate the update interval so that each update increases the progress by 1%.
        // This ensure we are updating the progress bar if there is a visual change to it.
        const progressUpdateFrequency = Math.floor(streamDuration / 100);

        percentProgress = Math.round((Math.abs(diffNow) * 100) / streamDuration);
        setProgressPercent(percentProgress);

        if (Math.abs(diffNow) <= streamDuration) {
          progressTimeout = setTimeout(() => {
            diffNow = startDateTime.diffNow().as('milliseconds');

            percentProgress = Math.round((Math.abs(diffNow) * 100) / streamDuration);
            setProgressPercent(percentProgress);
          }, progressUpdateFrequency);
        }
      } else {
        percentProgress = 0;
        setProgressPercent(0);
      }
    }
    return () => {
      if (progressTimeout) {
        clearTimeout(progressTimeout);
      }
    };
  }, [startDateTime, endDateTime]);

  return progressPercent;
};

export default useCalculateProgress;
