import { AxiosResponse } from 'axios';
import { forEach, isEmpty } from 'lodash';

import { Suggestion } from '@@src/services/SearchService';
import { transformPopularSearches } from '@@src/transformers/CatalogApiCommonTransformer';
import { paths } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';
import {
  catalogueHttpClient,
  catalogueHttpClientWithAuth,
  handleHttpError,
} from '@@utils/HttpClient';
import {
  CATALOGUE_MEDIA_BY_MPX_ID, CATALOGUE_NEWS_SERIES_BY_SLUG, CATALOGUE_PAGE_BY_SLUG, CATALOGUE_SPORTS_SERIES_BY_SLUG,
  CATALOGUE_TV_SERIES_BY_SLUG, COLLECTION_BY_SLUG, CATALOGUE_DOCUMENTS,
} from '@@utils/constants';
import { queryString } from '@@utils/helpers';

import { transformCollectionToCollectionPage } from '../transformers/CatalogApiCollectionTransformer';
import CatalogApiEpisodeTransformer from '../transformers/CatalogApiEpisodeTransformer';
import CatalogApiMovieOrProgramTransformer from '../transformers/CatalogApiMovieOrTvProgramTransformer';
import CatalogApiPageTransformer from '../transformers/CatalogApiPageTransformer';
import CatalogApiSeriesTransformer from '../transformers/CatalogApiSeriesTransformer';

type Path = keyof paths;

const getVideoPromises: Record<string, Promise<OnDemand.Movie | OnDemand.OneOff | OnDemand.Episode>> = {};

export function getMpxMediaById(mpxId: string, language: string): Promise<OnDemand.Movie | OnDemand.OneOff | OnDemand.Episode | null> {
  const path: Path = CATALOGUE_MEDIA_BY_MPX_ID;
  type ReturnType = paths[typeof path]['get']['responses']['200']['content']['application/json'];

  const url = path.replace('{mpxMediaID}', mpxId);
  const cacheKey = `${mpxId}${language}`;

  if (!(cacheKey in getVideoPromises) || !(getVideoPromises[cacheKey] instanceof Promise)) {
    getVideoPromises[cacheKey] = catalogueHttpClient(language).get<ReturnType>(url)
      .then(({ data }) => {
        getVideoPromises[cacheKey] = undefined;
        if (data.entityType === 'TV_EPISODE' || data.entityType === 'NEWS_EPISODE' || data.entityType === 'SPORTS_EPISODE') {
          return CatalogApiEpisodeTransformer.transform(data);
        }

        return CatalogApiMovieOrProgramTransformer.transform(data);
      })
      .catch((e) => {
        getVideoPromises[cacheKey] = undefined;
        return handleHttpError(e, new Error('Error on CatalogueApi.getMpxMediaById()'), {}, [404], null, 'warn');
      });
  }

  return getVideoPromises[cacheKey];
}

export function getSeriesBySlug(seriesType: OnDemand.SeriesType, slug: string, language: string): Promise<OnDemand.TvSeries> {
  type TvSeriesReturnType = paths[typeof CATALOGUE_TV_SERIES_BY_SLUG]['get']['responses']['200']['content']['application/json'];
  type NewsSeriesReturnType = paths[typeof CATALOGUE_NEWS_SERIES_BY_SLUG]['get']['responses']['200']['content']['application/json'];
  type SportsSeriesReturnType = paths[typeof CATALOGUE_SPORTS_SERIES_BY_SLUG]['get']['responses']['200']['content']['application/json'];
  let response: Promise<AxiosResponse<TvSeriesReturnType | NewsSeriesReturnType | SportsSeriesReturnType>>;

  const httpClient = catalogueHttpClient(language);

  if (seriesType === 'tv') {
    const url = CATALOGUE_TV_SERIES_BY_SLUG.replace('{slug}', slug);
    response = httpClient.get<TvSeriesReturnType>(url);
  } else if (seriesType === 'news') {
    const url = CATALOGUE_NEWS_SERIES_BY_SLUG.replace('{slug}', slug);
    response = httpClient.get<NewsSeriesReturnType>(url);
  } else if (seriesType === 'sports') {
    const url = CATALOGUE_SPORTS_SERIES_BY_SLUG.replace('{slug}', slug);
    response = httpClient.get<SportsSeriesReturnType>(url);
  }

  if (response instanceof Promise) {
    return response
      .then(({ data }) => {
        return CatalogApiSeriesTransformer.transform(data);
      })
      .catch((e) => {
        return handleHttpError(e, new Error('Error on CatalogueApi.getSeriesBySlug()'), {}, [404], null, 'warn');
      });
  }

  throw new Error(`Error on CatalogueApi.getSeresBySlug(): ${seriesType} is not supported`);
}

let getPageBySlugPromise: Promise<OnDemand.Page>;

export function getPageBySlug(slug: string, language: string, auth = false, nextCursor: string = ''): Promise<OnDemand.Page> {
  const path: Path = CATALOGUE_PAGE_BY_SLUG;
  type ReturnType = paths[typeof path]['get']['responses']['200']['content']['application/json'];
  const httpClient = auth ? catalogueHttpClientWithAuth(language) : catalogueHttpClient(language);

  const url = path.replace('{slug}', slug);
  const paginationParameters = { limit: '10', ...(nextCursor && { cursor: nextCursor }) };
  const paginatedUrl = `${url}?${new URLSearchParams(paginationParameters).toString()}`;

  if (!(getPageBySlugPromise instanceof Promise)) {
    getPageBySlugPromise = httpClient.get<ReturnType>(paginatedUrl)
      .then(({ data }) => {
        // Only display thew newsletter promo block on the 1st paginated section of the home page
        const injectNewsletterPromoBlock = data.slug === 'home' && nextCursor === '';

        return CatalogApiPageTransformer.transform(data, injectNewsletterPromoBlock);
      })
      .catch((e) => {
        return handleHttpError(e, new Error('Error on CatalogueApi.getPageBySlug()'), {}, [404], null, 'warn');
      })
      .finally(() => { getPageBySlugPromise = undefined; });
  }

  return getPageBySlugPromise;
}

interface CollectionOptions {
  limit?: number;
  sort?: string;
  cursor?: string;
  type?: string[];
  language?: string[];
  subtitle?: string[];
}

export function getCollectionPageBySlug(slug: string, language: string, options: CollectionOptions = {}): Promise<OnDemand.CollectionPage> {
  const path: Path = COLLECTION_BY_SLUG;
  type ReturnType = paths[typeof path]['get']['responses']['200']['content']['application/json'];

  let url = path.replace('{slug}', slug);

  const params: Record<string, string> = {};

  forEach(Object.keys(options), (key) => {
    if (!isEmpty(options[key])) {
      if (Array.isArray(options[key])) {
        params[key] = options[key].join(',');
      } else {
        params[key] = options[key].toString();
      }
    }
  });

  if (Object.keys(params).length > 0) {
    url = `${url}?${queryString(params)}`;
  }

  return catalogueHttpClient(language).get<ReturnType>(url)
    .then(({ data }) => {
      return transformCollectionToCollectionPage(data);
    })
    .catch((e) => {
      return handleHttpError(e, new Error('Error on CatalogueApi.CollectionPageBySlug()'), {}, [404], null, 'warn');
    });
}

export function getPopularSearches(language: string = 'en'): Promise<Suggestion[]> {
  const path: Path = CATALOGUE_DOCUMENTS;
  type ReturnType = paths[typeof path]['get']['responses']['200']['content']['application/json'];
  const url = path.replace('{name}', 'suggested_searches');

  return catalogueHttpClient(language).get<ReturnType>(url)
    .then(({ data }) => {
      return transformPopularSearches(atob(data.content).split('\n'));
    })
    .catch((e) => {
      return handleHttpError(e, new Error('Error on CatalogueApi.getPopularSearches()'), {}, [404], null, 'warn');
    });
}
