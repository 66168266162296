import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useCallback } from 'react';

import blue from '@@styles/colors/blue';
import { onLinkKeyListener } from '@@utils/helpers';

import OnDemand from '../../@types/OnDemand';
import { OdLink } from '../../routes';
import Image, { ImageProps } from '../Html/Image';
import { useTileStyles } from './Tile';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '&:focus-visible, :focus-visible &': {
        outline: 'none',
      },
    },
    tileTitle: {
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        marginTop: 14,
      },
      [theme.breakpoints.down('lg')]: {
        marginTop: 12,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 10,
      },
    },
    titleLink: {
      '&:focus-visible, :focus-visible &': {
        outline: 'none',
      },
    },
    imageContainer: {
      borderRadius: '50%',
      overflow: 'hidden',
      width: '100%',
      aspectRatio: '1 / 1',
      'div[role=button]:focus-visible &': {
        outline: `3px solid ${blue.navy}`,
      },
      '& picture, & img': {
        aspectRatio: '1 / 1',
      },
    },
  });
});

export interface CircleTileProps<Item = OnDemand.Collection, IP = ImageProps> {
  item: Partial<Item>;
  imageProps: IP;
  isActive?: boolean;
  onClick?(e): void;
}

const CircleTile: FunctionComponent<CircleTileProps> = (props) => {
  const {
    item,
    imageProps,
    isActive,
    onClick = () => {
      // do nothing
    },
  } = props;

  const classes = useStyles(props);
  const tileClasses = useTileStyles(props);

  const handleClick = useCallback((e) => {
    onClick(e);
  }, [onClick]);

  return (
    <div className={clsx(tileClasses.root, classes.root)} data-testid="circle-tile">
      <div className={tileClasses.card}>
        <OdLink
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...item.route}
          onClick={handleClick}
          onKeyPress={onLinkKeyListener(handleClick)}
          tabIndex={isActive ? 0 : -1}
          aria-label={item.title}
          className={clsx(tileClasses.tileLink, classes.titleLink)}
        >
          <div className={clsx(tileClasses.imageContainer, classes.imageContainer)}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Image {...imageProps}/>
          </div>
        </OdLink>
        <div className={clsx(tileClasses.tileTitle, classes.tileTitle)}>
          {item.title}
        </div>
      </div>
    </div>
  );
};

export default CircleTile;
