import { createSlice, createSelector } from '@reduxjs/toolkit';

import Config from '../@types/Config';
import { getConfig as fetchConfig } from '../services/ConfigService';
import type { RootState } from '../store';

const initialState: Config.Config = {
  movieProduct: {
    rows: [],
  },
  episodeProduct: {
    rows: [],
  },
  oneOffProduct: {
    rows: [],
  },
  playback: null,
  sponsorship: [],
};

const configStore = createSlice({
  name: 'ConfigStore',
  initialState,
  reducers: {
    setConfig(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

// thunk actions
export function loadVideoConfig() {
  return (dispatch) => {
    return fetchConfig().then((config) => {
      dispatch(configStore.actions.setConfig(config));
    });
  };
}

export const getConfig = createSelector(
  (state: RootState) => {
    return state.config;
  },
  (config) => {
    return config;
  },
);

export const getSponsorship = createSelector(
  (state: RootState) => {
    return state.config.sponsorship;
  },
  (sponsorship) => {
    return sponsorship;
  },
);

export const getAutoplayNextEpDelay = createSelector(
  (state: RootState) => {
    return state.config.playback;
  },
  (playback) => {
    return playback.autoplayNextEpDelay;
  },
);

export default configStore;
