import { LogEntry } from 'winston';
import TransportStream from 'winston-transport';

interface NewRelicAgent {
  noticeError(message: string, customParameters: Record<string, string>): void;
}

/**
 * Abstract class to log to newrelic using one of the newrelic agent
 */
export default class NewRelicAbstract extends TransportStream {
  protected newRelicAgent: NewRelicAgent;

  public constructor(opts?: TransportStream.TransportStreamOptions) {
    if (new.target === NewRelicAbstract) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }

    super(opts);

    // eslint-disable-next-line no-prototype-builtins
    if (opts && opts.level) {
      this.level = opts.level;
    }
  }

  public log(logEntry: LogEntry, next: () => void) {
    if (!this.newRelicAgent) {
      throw new Error('newRelicAgent is not defined');
    }

    setImmediate(() => {
      (this as any).emit('logged', logEntry);
    });

    const { message, ...rest } = logEntry;

    let error: any = message;

    // JSON.stringify the error if the error is an object other than an instance of Error
    if (!(error instanceof Error) && typeof error === 'object') {
      error = JSON.stringify(error);
    }

    const customParameters = {};
    Object.keys(rest).forEach((k) => {
      customParameters[k] = rest[k];
    });

    this.newRelicAgent.noticeError(error, customParameters);

    next();
  }
}
