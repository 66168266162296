import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import {
  FunctionComponent, ReactElement, cloneElement,
  createRef, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import withSizes from 'react-sizes';

import DataLayer from '@@utils/DataLayer';
import {
  mapSizesToProps,
  tileItemsPerViewLandscape,
  tileItemsPerViewPortrait,
  tileItemsPerViewTall,
  tileItemsPerViewSquare,
} from '@@utils/helpers';

import Button from '../Inputs/Button';
import ActivateTileButton from '../Tiles/ActivateTileButton';

// transition duration & delay for the zoom animation
export const transitionDuration = '200ms';
export const transitionDelay = '200ms';
const growFactor = 1.3;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    grid: {
      display: 'grid',
      gridColumnGap: theme.spacing(1),
      gridRowGap: theme.spacing(1),
    },
    'grid-tile-landscape': {
      gridTemplateColumns: `repeat(${tileItemsPerViewLandscape.xs}, minmax(1px, 1fr))`,
      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewLandscape.sm}, minmax(1px, 1fr))`,
      },
      [theme.breakpoints.only('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewLandscape.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.only('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewLandscape.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewLandscape.xl}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
    },
    'grid-tile-portrait': {
      gridTemplateColumns: `repeat(${tileItemsPerViewPortrait.xs}, minmax(1px, 1fr))`,
      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewPortrait.sm}, minmax(1px, 1fr))`,
      },
      [theme.breakpoints.only('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewPortrait.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.only('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewPortrait.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewPortrait.xl}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(10),
      },
    },
    'grid-tile-tall': {
      gridTemplateColumns: `repeat(${tileItemsPerViewTall.xs}, minmax(1px, 1fr))`,
      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewTall.sm}, minmax(1px, 1fr))`,
      },
      [theme.breakpoints.only('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewTall.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.only('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewTall.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewTall.xl}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(10),
      },
    },
    'grid-tile-square': {
      gridTemplateColumns: `repeat(${tileItemsPerViewSquare.xs}, minmax(1px, 1fr))`,
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewSquare.xs}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(3),
      },
      [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewSquare.sm}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(5),
      },
      [theme.breakpoints.only('md')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewSquare.md}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.only('lg')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewSquare.lg}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: `repeat(${tileItemsPerViewSquare.xl}, minmax(1px, 1fr))`,
        gridRowGap: theme.spacing(10),
      },
      '& div:focus-visible': {
        outline: 'none',
      },
    },
    tileContainer: {
      width: '100%',
      transition: `transform ${transitionDuration}`,
      transitionDelay,
      transform: 'scale(1)',
    },
  });
});

export interface GridProps {
  tiles: ReactElement[];
  clickSourceTitle?: string;
  windowWidth?: number;
  breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  hasMoreItems?: boolean;
  isLoadingMoreItems?: boolean;
  loadMoreItems?(cb?: () => void): void;
  tileSpec: {
    width: number;
    height: number;
    imageHeight: number;
    itemsPerView: number;
    orientation: string;
  };
}

const Grid: FunctionComponent<GridProps> = (props) => {
  const classes = useStyles(props);
  const {
    tiles,
    clickSourceTitle = '',
    breakpoint,
    tileSpec,
    hasMoreItems = false,
    isLoadingMoreItems = false,
    loadMoreItems = () => {
      // do nothing
    },
  } = props;

  const {
    itemsPerView,
    width: tileWidth,
    orientation: tileOrientation,
  } = tileSpec;

  const widthGrow = tileWidth * (growFactor - 1);

  const [activeTiles, setActiveTiles] = useState({});

  function getPos(index, itemsPerRow) {
    return {
      x: index % itemsPerRow,
      y: Math.floor(index / itemsPerRow),
    };
  }

  const itemRefs = useRef([]);
  const { t } = useTranslation('common');

  const activate = (index) => {
    setActiveTiles((state) => {
      return {
        ...state,
        [index]: true,
      };
    });

    // no zoom in animation for small breakpoints
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return;
    }

    const pos = getPos(index, itemsPerView);

    // start and end index in the current view's row
    const startIndex = pos.y * itemsPerView;
    const endIndex = pos.y * itemsPerView + itemsPerView - 1;

    // move left
    if (index !== startIndex) {
      for (let i = startIndex; i < index; i += 1) {
        if (itemRefs.current[i]) {
          const x = index === endIndex ? -widthGrow : -widthGrow / 2;
          const item = get(itemRefs, `current[${i}].current`);
          if (item) {
            Object.assign(item.style, {
              transform: `translate3d(${x}px, 0, 0)`,
            });
          }
        }
      }
    }

    if (itemRefs.current[index]) {
      let transformOrigin = 'center center';

      if (index === startIndex) {
        transformOrigin = 'left center';
      } else if (index === endIndex) {
        transformOrigin = 'right center';
      }

      Object.assign(itemRefs.current[index].current.style, {
        transform: `scale(${growFactor})`,
        transformOrigin,
        zIndex: 1,
      });
    }

    // move right
    if (index !== endIndex) {
      for (let i = index + 1; i < itemRefs.current.length && i <= endIndex; i += 1) {
        if (itemRefs.current[i]) {
          const x = index === startIndex ? widthGrow : widthGrow / 2;
          Object.assign(itemRefs.current[i].current.style, {
            transform: `translate3d(${x}px, 0, 0)`,
          });
        }
      }
    }
  };

  const deactivate = (index) => {
    setActiveTiles((state) => {
      return {
        ...state,
        [index]: false,
      };
    });

    for (let i = 0; i < itemRefs.current.length; i += 1) {
      if (itemRefs.current[i]) {
        Object.assign(itemRefs.current[i].current.style, {
          transform: 'translate3d(0, 0, 0)',
          zIndex: 0,
        });
      }
    }

    Object.assign(itemRefs.current[index].current.firstChild.style, {
      transform: 'scale(1)',
    });
  };

  if (tiles && tiles.length > 0) {
    // create the refs
    const _itemRefs = [];
    tiles.forEach(() => {
      _itemRefs.push(createRef<HTMLDivElement>());
    });

    itemRefs.current = _itemRefs;

    return (
      <div>
        {/*
           Note: Setting role="application" on the grid,
           so that the tiles will receive keypress event when pressing enter with JAWS, instead of a mouse click
        */}
        <div role="application" className={clsx(classes.grid, classes[`grid-tile-${tileOrientation}`])}>
          {
            tiles.map((tile, index) => {
              const itemRef = itemRefs.current[index];

              const tileProps = {
                onClick(e) {
                  if (tile.props.onClick) {
                    tile.props.onClick(e);
                  }
                  DataLayer.setClickSource(clickSourceTitle, 'grid', 1, index + 1);
                },
                onKeyPress(e) {
                  if (tile.props.onKeyPress) {
                    tile.props.onKeyPress(e);
                  }
                  DataLayer.setClickSource(clickSourceTitle, 'grid', 1, index + 1);
                },
                classes: {
                  ...get(tile, 'props.classes', {}),
                },
                isActive: !!activeTiles[index],
              };

              if (tileOrientation === 'tall') {
                return cloneElement(tile, tileProps);
              }

              return (
                <ActivateTileButton
                  key={tile.key}
                  ref={itemRef}
                  className={classes.tileContainer}
                  aria-label={tile.props.item ? tile.props.item.title : ''}
                  tabIndex={0}
                  onActivate={() => {
                    activate(index);
                  }}
                  onDeactivate={() => {
                    deactivate(index);
                  }}
                >
                  {cloneElement(tile, tileProps)}
                </ActivateTileButton>
              );
            })
          }
        </div>
        {
          hasMoreItems && (
            <Box mt={6} textAlign="center">
              <Button
                buttonType="secondary"
                onClick={() => {
                  loadMoreItems();
                }}
                disabled={isLoadingMoreItems}
              >
                {!isLoadingMoreItems && t('navigation.viewMore')}
                {isLoadingMoreItems && t('navigation.loading')}
              </Button>
            </Box>
          )
        }
      </div>
    );
  }
  return null;
};

export default withSizes(mapSizesToProps)(Grid);
