import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

import { getImageUrl } from '@@src/components/Html/Image';
import { getImageIdByProperties } from '@@src/transformers/CatalogApiCommonTransformer';
import OnDemand from '@@types/OnDemand';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '&.stack': {
        fontSize: '0.9rem',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: '0.8rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.6rem',
        },
      },
      '&.inline': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '-2px',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.875rem',
        },
      },
    },
    prefix: {
      '.inline & + $sponsorImage': {
        marginLeft: 8,
      },
      '.stack & + $sponsorImage': {
        marginTop: 8,
      },
    },
    sponsorImage: {
      '.stack &': {
        width: 145,
        [theme.breakpoints.down('md')]: {
          width: 131,
        },
        [theme.breakpoints.down('sm')]: {
          width: 93,
        },
      },
      '.inline &': {
        height: 55,
        verticalAlign: 'middle',
        [theme.breakpoints.down('md')]: {
          height: 47,
          marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
          height: 32,
          marginBottom: 0,
        },
      },
      '.carousel .slide img&': {
        width: 'auto',
      },
    },
  });
});

type SponsorshipProps = {
  type: 'stack' | 'inline';
  sponsorship: OnDemand.Sponsorship;
  classes?: {
    root?: string;
  };
};

const Sponsorship: FunctionComponent<SponsorshipProps> = (props) => {
  const {
    type, sponsorship: { text, title, images },
  } = props;
  const classes = useStyles(props);

  const imageProps = type === 'inline' ? {
    type: 'SPONSORSHIP_INLINE',
  } : {
    type: 'SPONSORSHIP_STACKED',
  };

  const imageId = getImageIdByProperties(images, imageProps);

  const image = getImageUrl(imageId);

  return (
    <div className={clsx(classes.root, type)}>
      {
        text
        && <div className={classes.prefix}>{text}</div>
      }
      <img
        src={image}
        alt={title}
        className={classes.sponsorImage}
      />
    </div>
  );
};

export default Sponsorship;
