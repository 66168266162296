import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { SupportedLanguage } from '@@src/i18n';
import { SSRFunctionComponent } from '@@types/ssr';
import { restoreLocationScrollPosition } from '@@utils/helpers';
import Logger from '@@utils/logger/Logger';

import ScrollToTop from '../components/Utils/ScrollToTop';
import grey from '../styles/colors/grey';

declare global {
  interface Window {
    _INITIAL_PROPS_LOADED_: boolean;
  }
}

// This make debugging easier. Components will show as SSR(MyComponent) in
// react-dev-tools.
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

let timeoutHandler;
// This is a Higher Order Component that abstracts duplicated data fetching
// on the server and client.
export default function withSSR(
  Page: SSRFunctionComponent,
  onLoadSuccess?: (title: string) => void,
  onLoadError?: (errorCode: string, errorMessage: string) => void,
) {
  interface Props extends RouteComponentProps {
    initialProps: any;
    store: any;
    language: SupportedLanguage;
    ssr: boolean;
  }

  interface State {
    data: any;
    isLoading: boolean;
  }

  class SSR extends Component<Props, State> {
    public static getInitialProps(ctx) {
      // Need to call the wrapped components getInitialProps if it exists
      return Page.getInitialProps
        ? Page.getInitialProps(ctx)
        : Promise.resolve(null);
    }

    public constructor(props) {
      super(props);
      this.state = {
        data: (typeof window !== 'undefined' && window._INITIAL_PROPS_LOADED_) ? null : props.initialProps,
        isLoading: false,
      };
    }

    public componentDidMount() {
      const { state, props: { location } } = this;
      // we fetch data if state.data is not populated (client side) or the initial props has been loaded before

      if (!!Page.getInitialProps && (
        window._INITIAL_PROPS_LOADED_ || !state.data)) {
        this.fetchData();
      } else if (Page.load) {
        Page.load().then(() => {
          if (!!Page.getInitialProps && (
            window._INITIAL_PROPS_LOADED_ || !state.data)) {
            this.fetchData();
          } else {
            // restore scroll when user refreshes the page
            // or going back from an external site using the browser back button
            restoreLocationScrollPosition(location, 100);
          }
        });
      }

      if (Page.refreshInterval) {
        clearTimeout(timeoutHandler);
        timeoutHandler = setTimeout(() => {
          this.fetchData();
        }, Page.refreshInterval);
      }
    }

    public componentDidUpdate(prevProps) {
      const { props: { match: { url } } } = this;

      // if shouldGetInitialProps is defined, call it to determine whether we should call fetchData() which calls getInitialProps()
      if (Page.shouldGetInitialProps) {
        if (Page.shouldGetInitialProps(prevProps, this.props)) {
          this.fetchData();
        }
      } else if (url !== prevProps.match.url) {
        this.fetchData();
      }

      if (Page.refreshInterval) {
        clearTimeout(timeoutHandler);
        timeoutHandler = setTimeout(() => {
          this.fetchData();
        }, Page.refreshInterval);
      }
    }

    public componentWillUnmount() {
      // set loaded flag so that we will re-fetch next time
      window._INITIAL_PROPS_LOADED_ = true;
      clearTimeout(timeoutHandler);
    }

    public fetchData = () => {
      this.setState({ isLoading: true });
      const { props } = this;

      const mySearchParams = new URLSearchParams(props.location.search);
      const query = {};
      mySearchParams.forEach((value, key) => {
        query[key] = value;
      });

      SSR.getInitialProps({
        store: props.store,
        match: props.match,
        pathname: props.location.pathname,
        language: props.language,
        ssr: props.ssr,
        query,
      }).then(
        (data) => {
          this.setState({ data, isLoading: false });

          // Restore scroll location after get initial props, it will only work if the scroll position was saved in the scroll history session storage
          // We use setTimeout to give React a chance to render before we restore scroll position.
          restoreLocationScrollPosition(props.location, 100);
        },
        (error) => {
          Logger.error('Error getting initial props', {
            error: {
              stack: error.stack,
              message: error.message,
            },
          });
          this.setState(() => {
            return {
              data: { error },
              isLoading: false,
            };
          });
        },
      );
    };

    public render() {
      // Flatten out all the props.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { initialProps, ...rest } = this.props;

      //  if we wanted to create an app-wide error component,
      //  we could also do that here using <HTTPStatus />. However, it is
      //  more flexible to leave this up to the Routes themselves.
      //
      // if (rest.error && rest.error.code) {
      //   <HttpStatus statusCode={rest.error.code || 500}>
      //     {/* cool error screen based on status code */}
      //   </HttpStatus>
      // }
      const { state } = this;

      return (
        <>
          {
            state.isLoading
            /* black loading overlay so that user won't see delay in the page update */
            && (
              <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: grey.darkBlackPearl,
                zIndex: 99,
              }}
              />
            )
          }
          {
            // if disableScrollToTop is defined and true, do not render ScrollToTop component
            Page.disableScrollToTop !== true && (
              <ScrollToTop/>
            )
          }
          <Page
            isLoading={state.isLoading}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...state.data}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            onLoadSuccess={onLoadSuccess}
            onLoadError={onLoadError}
          />
        </>
      );
    }
  }

  (SSR as React.ComponentClass).displayName = `SSR(${getDisplayName(Page)})`;
  return SSR;
}
