import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { supportedLangCodes } from '@@src/i18n';
import { generateFullUrlFromPath } from '@@src/routes';

const AlternateLinks: FunctionComponent = () => {
  const location = useLocation();

  const alternateLinks = supportedLangCodes.map((lang) => {
    const langPrefix = lang !== 'en' ? `/${lang}` : '';
    const fullUrl = generateFullUrlFromPath(`${langPrefix.toLowerCase()}${location.pathname}`);
    return (
      <link key={lang} rel="alternate" hrefLang={lang} href={fullUrl}/>
    );
  });

  return (
    <Helmet>
      {alternateLinks}
    </Helmet>
  );
};

export default AlternateLinks;
