import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import { FunctionComponent } from 'react';

import { OdLink } from '../../routes';
import MessageBanner from './MessageBanner';

export interface FeedbackSurveyBannerProps {
  ctaText: string;
  ctaLink: string;
  title: string;
  description: string;
  onClose?: () => void;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>;
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: 'white',
      boxShadow: '0 2px 4px 0 rgb(168, 168, 168, 0.5)',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        left: 'auto',
        right: 0,
      },
    },
    notificationTitleContainer: {
      backgroundColor: 'white',
    },
  });
});

const HalfSizeMessageBanner: FunctionComponent<FeedbackSurveyBannerProps> = (props) => {
  const {
    onClose, ctaText, ctaLink, title, description,
  } = props;
  const classes = useStyles(props);

  const ctaLinks = (
    <OdLink
      to={ctaLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      {ctaText}
    </OdLink>
  );

  return (
    <MessageBanner
      title={title}
      description={description}
      ctaLinks={ctaLinks}
      onClose={onClose}
      data-testid="feedbacksurvey-banner"
      classes={{
        root: classes.root,
        notificationTitleContainer: classes.notificationTitleContainer,
      }}
    />
  );
};

export default HalfSizeMessageBanner;
