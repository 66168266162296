import { Duration } from 'luxon';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export interface LiveInLabelProps {
  durationToAvailable: number;
  shortUnits?: boolean;
}

const LiveInLabel: FunctionComponent<LiveInLabelProps> = (props) => {
  const {
    durationToAvailable,
    shortUnits = false,
  } = props;
  const { t } = useTranslation('common');
  const timeToLiveLabel = t('video.duration', {
    duration: durationToAvailable,
    formatParams: {
      duration: {
        units: ['mo', 'w', 'd', 'h', 'm', 's'],
        largest: durationToAvailable < 86400 && durationToAvailable > 3600 ? 2 : 1,
        delimiter: ' ',
      },
    },
  });

  if (durationToAvailable > 0) {
    const duration = Duration.fromObject({ hours: 0, minutes: 0, seconds: durationToAvailable })
      .normalize();

    return (
      <Trans
        i18nKey="availabilityBadge.liveInWithTag"
        tOptions={{
          formatParams: {
            duration: {
              units: ['mo', 'w', 'd', 'h', 'm', 's'],
              largest: durationToAvailable < 86400 && durationToAvailable > 3600 ? 2 : 1,
              shortUnits,
              spacer: shortUnits ? '' : ' ',
              delimiter: ' ',
            },
          },
        }}
      >
        Live in
        <time
          dateTime={duration.toISO()}
          aria-label={shortUnits ? timeToLiveLabel : undefined}
        >
          {{ duration: durationToAvailable }}
        </time>
      </Trans>
    );
  }

  return t('availabilityBadge.live');
};

export default LiveInLabel;
