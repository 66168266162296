import { useState, FunctionComponent, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { generatePathFromLinkProps } from '@@src/routes';
import { Suggestion } from '@@src/services/SearchService';
import {
  setKeywords,
  setSuggestions,
  loadSuggestionsAsyncThunk,
  loadPopularSearchesAsyncThunk,
} from '@@stores/SearchStore';
import DataLayer from '@@utils/DataLayer';
import Logger from '@@utils/logger/Logger';

import { getRouteFromName } from '../../routes';
import SearchInput from './SearchInput';

interface SearchBarProps {
  classes?: any;
  iconPosition?: 'left' | 'right';
}

const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
  const { t } = useTranslation('common');
  const minLengthToSearch = 3;
  const searchDebounceWait = 250;
  const {
    classes, iconPosition = 'right',
  } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const searchRoute = getRouteFromName('search');
  const searchInputPlaceholder = t('searchBar.placeholder', { value: 15000 });
  const [onSearchPage, setOnSearchPage] = useState<boolean>(!!matchPath(location.pathname, searchRoute));

  useEffect(() => {
    setOnSearchPage(!!matchPath(location.pathname, searchRoute));
  }, [location.pathname, searchRoute]);

  const popularSearches = useAppSelector((state) => {
    return state.search.popularSearches;
  });

  const suggestions = useAppSelector((state) => {
    return state.search.suggestions;
  });

  const keywords = useAppSelector((state) => {
    return state.search.keywords;
  });

  const [suggestionTitle, setSuggestionTitle] = useState<string>(t('searchBar.suggestionTitle'));
  const [hasQuery, setHasQuery] = useState<boolean>(!!keywords?.length);

  const dispatchSetKeywords = useCallback((value) => {
    dispatch(setKeywords(value));
  }, [dispatch]);

  const processSelectedSuggestion = useCallback((suggestion: Suggestion) => {
    let path;

    if ('route' in suggestion) {
      path = generatePathFromLinkProps(suggestion.route);
    } else {
      path = generatePath(searchRoute.path, { query: encodeURIComponent(suggestion.label) });
    }
    history.push(path);
  }, [history, searchRoute.path]);

  const handleChange = useCallback((newValue) => {
    setHasQuery(newValue.length);
    dispatchSetKeywords(newValue);
  }, [dispatchSetKeywords]);

  const handleSubmit = useCallback((_keywords) => {
    setHasQuery(_keywords.length);
    dispatchSetKeywords(_keywords);
    const path = generatePath(searchRoute.path, { query: encodeURIComponent(_keywords) });
    history.push(path);
  }, [dispatchSetKeywords, history, searchRoute.path]);

  useDebounce(() => {
    const checkSuggestions = async () => {
      const inputLength = keywords.length;
      if (onSearchPage) {
        // don't display suggestions if we are on the search page
        dispatch(setSuggestions([]));
      } else if (inputLength === 0) {
        if (popularSearches?.length) {
          dispatch(setSuggestions(popularSearches));
        } else {
          await dispatch(loadPopularSearchesAsyncThunk());
        }
        setSuggestionTitle(t('searchBar.suggestions'));
      } else if (inputLength >= minLengthToSearch) {
        setSuggestionTitle(t('searchBar.suggestions'));
        await dispatch(loadSuggestionsAsyncThunk({ keywords }));
      } else {
        dispatch(setSuggestions([]));
      }
    };
    checkSuggestions().catch((error) => {
      Logger.error(error.message);
    });
  }, searchDebounceWait, [dispatch, keywords, onSearchPage, t, popularSearches]);

  const handleSuggestionSelected = useCallback((suggestionValue: string) => {
    const matchingSuggestion = suggestions.find((suggestion) => {
      return suggestion.id === suggestionValue;
    });

    // When submitting the SearchInput, the Autocomplete component triggers the onChange event which in turn
    // triggers this with an undefined suggestion. The browser path update is handled inside SearchInput.
    if (matchingSuggestion !== undefined) {
      processSelectedSuggestion(matchingSuggestion);
    }

    dispatch(setSuggestions([]));
    DataLayer.events.searchResult('suggestedSearch', suggestionValue, keywords);
  }, [processSelectedSuggestion, dispatch, keywords, suggestions]);

  return (
    <SearchInput
      searchRoute={searchRoute}
      actionPath="/search"
      hasQuery={hasQuery}
      searchKeywords={keywords}
      inputPlaceholder={searchInputPlaceholder}
      iconPosition={iconPosition}
      classes={classes}
      suggestions={suggestions}
      suggestionTitle={suggestionTitle}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export default SearchBar;
