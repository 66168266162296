// Use https://www.color-blindness.com/color-name-hue to name the colour
export default {
  white: '#ffffff',
  zumthor: '#d5d9dc',
  chateau: '#9ba4ac',
  fiord: '#515a61',
  codgrey: '#2f3438',
  nightrider: '#2e2e2e',
  jaguar: '#272728',
  blackpearl: '#1b2024',
  darkBlackPearl: '#0b0d0f',
  black: '#000000',
};
