import { createSlice, PayloadAction, createSelector, createAsyncThunk } from '@reduxjs/toolkit';

import CastPlayer from '@@src/lib/VideoPlayerV2/CastPlayer';
import { RootState } from '@@src/store';
import { getResumePosition, loadProgressesOnce } from '@@stores/ProgressStore';

export interface BreakClip {
  duration: number;
  id: string;
  title: string;
}

export interface Break {
  breakClipIds: string[];
  duration: number;
  id: string;
  isEmbedded: boolean;
  isWatched: boolean;
  position: number;
}

export interface Tracks {
  trackContentType: string;
  trackId: number;
  name?: string;
  type: string;
}

export interface CustomData {
  ozTamSessionId: string;
}

export interface MediaInfo {
  breakClips: BreakClip[];
  breaks: Break[];
  contentType: string;
  contentUrl: string;
  customData: CustomData;
  duration: number;
  entity: string;
  streamType: string;
  tracks?: Tracks[];
}

export interface ChromeCastStore {
  casting: boolean;
  loading: undefined | boolean;
  hasDevicesAvailable: boolean;
  sessionState?: cast.framework.SessionState;
  currentTime?: number;
  isPaused?: boolean;
  mediaInfo?: MediaInfo;
  castState?: cast.framework.CastState;
  activeTracks?: number[];
  isAd?: boolean;
}

const initialState: ChromeCastStore = {
  casting: false,
  hasDevicesAvailable: false,
  sessionState: undefined,
  currentTime: undefined,
  loading: undefined,
  isPaused: undefined,
  mediaInfo: undefined,
  castState: undefined,
  isAd: undefined,
  activeTracks: undefined,
};

export interface LoadMediaArgs {
  entityId: string;
  isLive: boolean;
  sessionId: string
  resumePosition?: number
  checkReconnection?: boolean
  subtitlesLanguage?: string;
}

interface LoadMediaThunkConfig {
  state: RootState
  rejectValue: string
}

let loadMediaPromise: Promise<void> | undefined;

// Get resume position for a video from the progress store and send a LOAD request to the remote chromecast receiver app.
// Saves the request promise locally to prevent multiple requests in parallel.
export const loadMediaAsyncThunk = createAsyncThunk<
void,
LoadMediaArgs,
LoadMediaThunkConfig
>('chromecast/loadMedia', async (args, thunkConfig) => {
  const state = thunkConfig.getState() as RootState;
  if (args?.checkReconnection) {
    if (state.chromecast?.mediaInfo?.entity && state.chromecast.mediaInfo.entity.replace('mpx:', '') === args.entityId) {
      return Promise.reject(new Error(`Cast receiver is already playing entity id ${args.entityId}`));
    }
  }

  const resumePosition = args?.resumePosition ?? await thunkConfig.dispatch(loadProgressesOnce()).then(() => {
    return getResumePosition(state, args.entityId);
  });

  if (!(loadMediaPromise instanceof Promise)) {
    loadMediaPromise = CastPlayer.current.loadVideo(
      args.entityId,
      resumePosition,
      args.sessionId,
      args.isLive,
      args.subtitlesLanguage,
    );

    if (!(loadMediaPromise instanceof Promise)) {
      return Promise.reject(new Error(`Failed loading video ${args.entityId} to cast receiver`));
    }

    loadMediaPromise.finally(() => { loadMediaPromise = undefined; });
  }

  return loadMediaPromise;
});

const chromeCastStore = createSlice({
  name: 'ChromeCastStore',
  initialState,
  reducers: {
    setSessionState(state, action: PayloadAction<cast.framework.SessionState>) {
      state.sessionState = action.payload;
    },
    setCasting(state, action: PayloadAction<boolean>) {
      state.casting = action.payload;
    },
    setHasDevicesAvailable(state, action: PayloadAction<boolean>) {
      state.hasDevicesAvailable = action.payload;
    },
    setCurrentTime(state, action: PayloadAction<number>) {
      state.currentTime = action.payload;
    },
    setIsPaused(state, action: PayloadAction<boolean>) {
      state.isPaused = action.payload;
    },
    setMediaInfo(state, action: PayloadAction<MediaInfo>) {
      state.mediaInfo = action.payload;
    },
    setCastState(state, action: PayloadAction<cast.framework.CastState>) {
      state.castState = action.payload;
    },
    setIsAd(state, action: PayloadAction<boolean>) {
      state.isAd = action.payload;
    },
    setActiveTracks(state, action: PayloadAction<Array<number>>) {
      state.activeTracks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadMediaAsyncThunk.pending, (state) => {
      state.loading = true;
      state.casting = true;
    });

    builder.addCase(loadMediaAsyncThunk.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(loadMediaAsyncThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const getCurrentTime = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.currentTime;
  },
);

export const getCasting = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.casting;
  },
);

export const getHasDevicesAvailable = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.hasDevicesAvailable;
  },
);

export const getSessionState = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.sessionState;
  },
);

export const getCastState = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.castState;
  },
);

export const getIsPaused = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.isPaused;
  },
);

export const getMediaInfo = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast.mediaInfo;
  },
);

export const getIsAd = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return !!chromecast.isAd;
  },
);

export const getMediaLoading = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return !!chromecast.loading;
  },
);

export const getMediaDetails = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    let isLive: boolean | null = null;
    let duration: number | null = null;
    let entityId: string | null = null;
    let breaks: number[] | null = null;
    let tracks: Array<Tracks & { active:boolean }> | null = null;

    if (chromecast.mediaInfo) {
      duration = chromecast.mediaInfo.duration;
      isLive = chromecast.mediaInfo.streamType === 'LIVE';
      entityId = chromecast.mediaInfo.entity.replace('mpx:', '');

      // There should be always at least one track (the video one)
      if (chromecast?.mediaInfo?.tracks?.length > 1) {
        tracks = chromecast?.mediaInfo?.tracks?.map((track) => {
          const name = track.type === 'TEXT' ? track.name : 'None';
          let active = !!chromecast?.activeTracks?.includes(track.trackId);

          if (track.type === 'VIDEO') {
            active = !!(chromecast?.activeTracks?.length === 1 && chromecast?.activeTracks?.includes(track.trackId));
          }

          return {
            ...track,
            active,
            name,
          };
        });
      }

      breaks = chromecast.mediaInfo?.breaks?.map((b: Break) => {
        return b.position;
      })
        .sort((a, b) => {
          return (a - b);
        });
    }

    return {
      entityId,
      isLive,
      duration,
      breaks,
      tracks,
    };
  },
);

export const getMediaStreamType = createSelector(
  (rootState: RootState) => {
    return rootState.chromecast;
  },
  (chromecast) => {
    return chromecast?.mediaInfo?.streamType ?? null;
  },
);

/**
 * uses the entity id in the `mediaInfo` object to fetch an OnDemand Video
 */
export const getVideoDetails = createSelector(
  (rootState: RootState) => {
    return rootState;
  },
  (rootState) => {
    const entityId = rootState.chromecast?.mediaInfo?.entity?.replace('mpx:', '');

    if (!entityId) {
      return null;
    }

    const video = rootState.video[entityId];

    return video ?? null;
  },
);

export default chromeCastStore;
export const {
  setCurrentTime,
  setCasting,
  setHasDevicesAvailable,
  setSessionState,
  setIsPaused,
  setMediaInfo,
  setCastState,
  setIsAd,
  setActiveTracks,
} = chromeCastStore.actions;
