import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { ReactComponent } from './episodes.svg';

const Episodes: OverridableComponent<SvgIconTypeMap> = (props) => {
  return <SvgIcon component={ReactComponent} {...props}/>;
};

export default Episodes;
