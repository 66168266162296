/**
 * Logger class used to log application messages
 * This class is used on client side (browser)
 * There is a replacement module Logger-server which is used on server side (node.js)
 *
 * On client side, it will log to the following places:
 *    - browser console
 *    - newrelic using newrelic browser agent api
 *
 * Use the guideline below when choosing a log severity level
 *   - Error: One or more functionalities are not working, preventing some functionalities from working correctly.
 *   - Warn: Unexpected behavior happened inside the application, but it is continuing its work and the key business features are operating as expected.
 *   - Info: An event happened, the event is purely informative and can be ignored during normal operations.
 *   - Debug: A log level used for events considered to be useful during software debugging when more granular information is needed.
 */

import winston, { format } from 'winston';

import { VERSION, BUILD_HASH } from '@@utils/constants';

import fixErrorObjectMessage from './formats/fixErrorObjectMessage';
import formatErrorObject from './formats/formatErrorObject';
import BrowserConsole from './transports/BrowserConsole';
import NewRelicBrowser from './transports/NewRelicBrowser';

const { timestamp, combine } = format;

export const browserConsoleTransport = new BrowserConsole({
  format: combine(
    timestamp({
      format: 'isoDateTime',
    }),
    formatErrorObject(),
  ),
});

/**
 * EUM transport logs to newrelic using newrelic browser agent api
 * This log will end up in Browser applications > Monitor > JS errors
 */
export const newRelicBrowserTransport = new NewRelicBrowser({
  // Only log warn level or above to go to newrelic
  level: 'warn',
});

const Logger = winston.createLogger({
  level: process.env.BVAR_LOG_LEVEL,
  defaultMeta: { version: `${VERSION}-${BUILD_HASH}` },
  format: fixErrorObjectMessage(),
  transports: [
    browserConsoleTransport,
    newRelicBrowserTransport,
  ],
});

export default Logger;
