import { Skeleton } from '@material-ui/lab';
import { FunctionComponent, useMemo } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import { getCurrentBreakpoint } from '../../styles/breakpoints';
import Gpt from './Gpt';

interface MrecProps {
  targeting?: Record<string, string>;
  pos: number;
  pageSlug: string;
}

const Mrec: FunctionComponent<MrecProps> = (props) => {
  const { pathname } = useLocation();

  const { targeting: targetingProp, pos, pageSlug } = props;

  const targeting: Record<string, string> = {
    screensize: getCurrentBreakpoint(),
    path: pathname,
    ...targetingProp,
  };

  const slotSize = useMemo(() => {
    return [300, 250];
  }, []);

  let adSlotName = `slot${pos}`;
  if (isMobileOnly) {
    adSlotName += '_mobile';
  } else if (isTablet) {
    adSlotName += '_tablet';
  } else {
    adSlotName += '_desktop';
  }

  const adUnitPath = `/4117/video.programs.sbs.com.au/${pageSlug}/${adSlotName}`;

  return (
    <Gpt
      slotSize={slotSize}
      adUnitPath={adUnitPath}
      targeting={targeting}
      data-testid="promo-block-mrec"
    />
  );
};

export const MrecWithLazyLoad: FunctionComponent<MrecProps> = (props) => {
  const placeholder = (
    <Skeleton variant="rect" animation={false} width={300} height={250} data-testid="promo-block-mrec"/>
  );

  return (
    <LazyLoad once offset={50} placeholder={placeholder}>
      <Mrec {...props}/>
    </LazyLoad>
  );
};

export default Mrec;
