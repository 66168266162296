import { operations } from '@@types/ContentSearch';
import { contentSearchHttpClient } from '@@utils/HttpClient';
import { SEARCH_PATH } from '@@utils/constants';

type CatalogueSearchResponseSchema = operations['catalogue-search']['responses'][200]['content']['application/json'];
type CatalogueSearchParams = operations['catalogue-search']['parameters']['query'];

export function performSearch(query: string, nextCursor: string | null = null, pageSize: number = 60): Promise<CatalogueSearchResponseSchema> {
  const params: CatalogueSearchParams = {
    q: query,
    limit: pageSize.toString(),
  };

  if (nextCursor) {
    params.cursor = nextCursor;
  }

  return contentSearchHttpClient().get<CatalogueSearchResponseSchema>(SEARCH_PATH, {
    params,
  }).then((response) => {
    return response.data;
  });
}

export default {
  performSearch,
};
