import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';

import { ImageProps, getResizedUrl } from '@@src/components/Html/Image';
import { liveChannelStyles } from '@@src/components/LiveTv/LiveTvChannelShelf';
import LiveScheduleTileLive from '@@src/components/Tiles/LiveScheduleTileLive';
import LiveScheduleTileUpcoming from '@@src/components/Tiles/LiveScheduleTileUpcoming';
import useDurationToExpired from '@@src/hooks/useDurationToExpired';
import useDurationToLive from '@@src/hooks/useDurationToLive';
import OnDemand from '@@types/OnDemand';
import DataLayer from '@@utils/DataLayer';

const useLiveChannelStyles = makeStyles(liveChannelStyles);
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tileLive: {
      width: '32%',
      [theme.breakpoints.down('md')]: {
        width: '38%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '47%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '81%',
      },
    },
    tileUpcoming: {
      width: '22%',
      [theme.breakpoints.down('md')]: {
        width: '27%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '33%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '58%',
      },
    },
  });
});

export interface LiveScheduleTileProps<Item = OnDemand.Movie | OnDemand.Episode | OnDemand.OneOff | OnDemand.Clip> {
  item: Partial<Item>;
  imageProps: ImageProps;
  rowIndex: number;
  tileIndex: number;
  shelfName: string;
}

const LiveScheduleTile: FunctionComponent<LiveScheduleTileProps> = (props) => {
  const {
    item, imageProps, rowIndex, tileIndex, shelfName,
  } = props;
  const classes = useStyles();
  const liveChannelClasses = useLiveChannelStyles();
  const durationToLive = useDurationToLive(item.availableDate);
  const durationToExpired = useDurationToExpired(item.expiredDate);
  const [isLive, setIsLive] = useState<boolean>(item.available);
  const [isExpired, setIsExpired] = useState<boolean>(item.expired);

  const setClickSource = () => {
    const dataLayerShelfName = shelfName.replace(/:/, '');
    DataLayer.setClickSource(shelfName, `carousel:${dataLayerShelfName}:${rowIndex + 1}:${tileIndex + 1}`, rowIndex + 1, tileIndex + 1);
  };

  const imageConfig = 'imageId' in imageProps ? { imageId: imageProps.imageId } : { src: imageProps.src };

  useEffect(() => {
    // update whether the stream is live and not expired on the client side
    if (durationToExpired > 0) {
      if (durationToLive <= 0) {
        setIsLive(true);
        setIsExpired(false);
      } else {
        setIsLive(false);
        setIsExpired(false);
      }
    } else {
      setIsLive(false);
      setIsExpired(true);
    }
  }, [durationToExpired, durationToLive]);

  return !isExpired && (
    <div key={item.id} className={clsx(liveChannelClasses.tile, isLive ? classes.tileLive : classes.tileUpcoming)}>
      {isLive ? (
        <LiveScheduleTileLive
          id={item.id}
          title={item.title}
          imageUrl={getResizedUrl(imageConfig, { width: 1280, height: 720, quality: 80 })}
          start={item.availableDate}
          end={item.expiredDate}
          onClick={setClickSource}
        />
      ) : (
        <LiveScheduleTileUpcoming
          route={item.route}
          title={item.title}
          imageUrl={getResizedUrl(imageConfig, { width: 1280, height: 720, quality: 80 })}
          start={item.availableDate}
          onClick={setClickSource}
        />
      )}
    </div>
  );
};

export default LiveScheduleTile;
