import { get } from 'lodash';
import { Duration } from 'luxon';

import { components } from '@@types/CatalogueApi';
import OnDemand from '@@types/OnDemand';

import {
  getImageIdByProperties, transformAnnouncement,
  transformAvailability, transformCasts, transformClassification, transformConsumerAdvices,
  transformCrewToDirectors, transformLanguages, transformTextTracksToSubtitles,
  transformSponsorship,
} from './CatalogApiCommonTransformer';

type MovieOrProgram = components['schemas']['movie']
| components['schemas']['tvProgram']
| components['schemas']['sportsProgram']
| components['schemas']['newsProgram'];

const transform = (data: MovieOrProgram): OnDemand.Movie | OnDemand.OneOff => {
  const availability = transformAvailability(data.availability);
  const isOneOff = data.entityType === 'TV_PROGRAM'
    || data.entityType === 'SPORTS_PROGRAM'
    || data.entityType === 'NEWS_PROGRAM';

  const distributors = get(data, 'distributors', []) as OnDemand.Distributor[];
  const sponsorship = transformSponsorship(data.sponsorships);

  return {
    availableDate: availability.availableDate,
    available: availability.available,
    cast: transformCasts(data.casts),
    channels: data.channels,
    classification: 'classificationID' in data ? transformClassification(data.classificationID) : null,
    consumerAdvices: transformConsumerAdvices(data.consumerAdvices),
    consumerAdviceTexts: data.consumerAdviceTexts,
    countries: data.countries,
    description: data.description,
    distributors,
    duration: 'duration' in data ? Duration.fromISO(data.duration).as('seconds') : null,
    directors: transformCrewToDirectors(data.crews),
    expired: availability.expired,
    expiredDate: availability.expiredDate,
    genres: data.genres,
    hasClosedCaption: get(data, 'textTracks').length > 0,
    hasAudioDescription: get(data, 'hasAudioDescription', false),
    id: data.mpxMediaID.toString(),
    entityType: data.entityType,
    catalogueId: data.id,
    odImageId: getImageIdByProperties(data.images, { ratio: '16:9', type: 'KEY_ART' }),
    ogImageId: getImageIdByProperties(data.images, { ratio: '16:9', type: 'BANNER' }),
    isLiveStream: 'liveStream' in data ? data.liveStream : false,
    isHighDefinition: get(data, 'qualities').includes('HD'),
    languages: transformLanguages(get(data, 'languages')),
    mediaTitle: data.title,
    metaDescription: data.description.replace(/[\r\n]+/g, ' '),
    pageTitle: data.title,
    pilatId: 'ibmsTitleID' in data ? data.ibmsTitleID.toString() : null,
    playerTitles: {
      title: data.title,
      subtitle: '',
    },
    publicationYear: 'releaseYear' in data ? data.releaseYear : null,
    route: isOneOff ? {
      name: 'program',
      params: {
        id: data.mpxMediaID.toString(),
        slug: data.slug,
        programType: data.entityType.toLocaleLowerCase().split('_').shift(),
      },
    } : {
      name: 'movie',
      params: {
        id: data.mpxMediaID.toString(),
        slug: data.slug,
      },
    },
    subtitles: transformTextTracksToSubtitles(data.textTracks),
    type: isOneOff ? 'OneOff' : 'Movie',
    title: data.title,
    trailerId: get(data, 'trailers.0.mpxMediaID'),
    announcement: 'announcements' in data ? transformAnnouncement(data.announcements) : null,
    sponsorship,
    cdpTitle: 'cdpTitle' in data ? data.cdpTitle : null,

    // these attributes not transformed because they are not available, left here because they are still expected in data layer
    airDate: '',
    award: '',
    collections: [],
    eras: [],
    groupings: [],
    keywords: [],
    locations: [],
    organisations: [],
    places: [],
    seriesCrid: '',
    subgenres: [],
    subjects: [],
    themes: [],
    tones: [],
    useType: '',
  };
};

export default { transform };
