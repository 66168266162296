import { Box, CardMedia, Hidden } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import blue from '@@src/styles/colors/blue';

import { OdLink } from '../../routes';
import ProgressBar from '../ProgressBar/ProgressBar';
import PlayButton from '../Video/PlayButton';

const styles = createStyles({
  root: {
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '100%',
  },
  playLink: {
    '&[data-focus-visible-added]': {
      outline: 'none',
    },
    '&[data-focus-visible-added]::before': {
      content: '""',
      outline: `3px solid ${blue.navy}`,
      outlineOffset: -3,
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 1,
    },
  },
  playButton: {},
  playButtonOverlay: {},
  progressContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  progressBar: {
    height: 6,
  },
});

const useStyles = makeStyles<Theme, LiveCardMediaProps>(styles);

export interface LiveCardMediaProps {
  /** The title of the live stream. */
  title: string;
  /** The url for the image. */
  imageUrl: string;
  progressPercent?: number;
  clickUrl?: string;
  playButtonLabel?: string;
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof styles>>>;
}

const LiveCardMedia: FunctionComponent<LiveCardMediaProps> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  const {
    title,
    imageUrl,
    progressPercent = 0,
    clickUrl,
    playButtonLabel,
  } = props;

  const isClickable = clickUrl;

  return (
    <Box className={classes.root}>
      {isClickable && (
        <OdLink
          className={classes.playLink}
          to={clickUrl}
          aria-label={playButtonLabel || t('playback.play')}
        >
          <CardMedia className={classes.media} image={imageUrl} title={title}/>
          <Hidden smDown>
            <PlayButton
              overlay
              classes={{ playIcon: classes.playButton, overlay: classes.playButtonOverlay }}
            />
          </Hidden>
          <Hidden mdUp>
            <PlayButton
              size={48}
              overlay
              classes={{ playIcon: classes.playButton }}
            />
          </Hidden>
        </OdLink>
      )}
      {!isClickable && <CardMedia className={classes.media} image={imageUrl} title={title}/>}
      {progressPercent > 0 && (
        <Box className={classes.progressContainer} data-testid="progress-container">
          <ProgressBar
            progressPercent={progressPercent}
            transparent
            classes={{ root: classes.progressBar }}
          />
        </Box>
      )}
    </Box>
  );
};

export default LiveCardMedia;
