import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import OnDemand from '../../@types/OnDemand';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { removeFromContinueWatching } from '../../services/ProgressService';
import blue from '../../styles/colors/blue';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      // keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="close"]': {
        opacity: 1,
      },
      'body:not(.using-mouse) &:focus': {
        outline: `3px solid ${blue.navy}`,
      },
      '& svg': {
        color: common.white,
        // x button
        '& [class*="close"]': {
          opacity: 0.7,
        },
        '&:hover [class*="close"]': {
          opacity: 1,
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

interface ButtonProps {
  item: Partial<OnDemand.Video>;
  tabIndex?: number;
  classes?: any;
  children?: any;
  onClick?(e): void;
}

const OdRemoveFromContinueWatchingButton: FunctionComponent<ButtonProps> = (props) => {
  const {
    item,
    tabIndex = 0,
    onClick,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromContinueWatching(item.id);

    if (onClick) {
      onClick(e);
    }
  };

  const label = t('button.removeFromContinueWatching');
  const buttonProps = {
    title: label,
    'aria-label': label,
    onClick: handleClick,
    classes: {
      root: classes.root,
    },
    disableRipple: true,
    disableFocusRipple: true,
    tabIndex,
  };

  return (
    <IconButton
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
    >
      <CloseIcon/>
    </IconButton>
  );
};

export default OdRemoveFromContinueWatchingButton;
