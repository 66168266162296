import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';

import { ReactComponent as PlayIcon } from '../../images/icons/play-circle.svg';

type ClassKey = 'root' | 'overlay' | 'playIcon';

const useStyles = makeStyles<Theme, PlayButtonProps>(() => {
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      display: 'flex',
    },
    overlay: {
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 1,
      '@media (hover: hover)': {
        opacity: 0,
      },
      transition: 'opacity 300ms',
      '&:hover': {
        opacity: 1,
      },
      'a[data-focus-visible-added] > &': {
        opacity: 1,
      },
    },
    playIcon: ({ size }) => {
      return {
        width: size,
        height: size,
        opacity: 0.8,
        '&:hover': {
          opacity: 0.9,
        },
      };
    },
  });
});

interface PlayButtonProps {
  size?: number;
  overlay?: boolean;
  classes?: Partial<Record<ClassKey, string>>;
}

const PlayButton: FunctionComponent<PlayButtonProps> = (props) => {
  const { overlay = false, size = 80 } = props;
  const classes = useStyles({ ...props, size });

  return (
    <div className={clsx(classes.root, { [classes.overlay]: overlay })}>
      <PlayIcon className={classes.playIcon}/>
    </div>
  );
};

export default PlayButton;
