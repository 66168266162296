import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { OdLink } from '../../routes';
import MessageBanner from './MessageBanner';

export interface GeoblockBannerProps {
  onClose?: () => void;
  classes?: any;
}

const GeoblockBanner: FunctionComponent<GeoblockBannerProps> = (props) => {
  const { onClose, classes } = props;
  const { t } = useTranslation('common');

  const ctaLinks = (
    <OdLink
      to="https://help.sbs.com.au/hc/en-au/articles/360002023895-Can-I-watch-SBS-On-Demand-outside-Australia-"
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('messageBanner.learnMore')}
    </OdLink>
  );

  return (
    <MessageBanner
      title={t('geoblockBanner.title')}
      description={t('geoblockBanner.description')}
      ctaLinks={ctaLinks}
      onClose={onClose}
      data-testid="geoblock-banner"
      classes={classes}
    />
  );
};

export default GeoblockBanner;
