import { IconButton, Snackbar, SnackbarOrigin } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { get } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { setSnackPack } from '@@stores/FavouritesStore';
import blue from '@@styles/colors/blue';

import { ReactComponent as HeartIcon } from '../../images/icons/heart.svg';
import { OdLink } from '../../routes';
import type { RootState } from '../../store';
import grey from '../../styles/colors/grey';

interface FavouriteSnackbarProps {
  autoHideDuration?: number;
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: grey.codgrey,
      color: common.white,
    },
    snackBar: {
      '& .MuiSnackbarContent-message': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& svg': {
        '& [class*="heart"]': {
          color: common.white,
          fill: common.white,
        },
      },
    },
    icon: {
      width: 40,
      height: 36,
    },
    links: {
      textDecoration: 'underline',
      marginLeft: '0.25rem',
    },
    closeButton: {
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
  });
});

const FavouritesSnackbar: FunctionComponent<FavouriteSnackbarProps> = (props) => {
  const { autoHideDuration = 6000 } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles(props);
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  let messagePrefix;
  const snackbarType = get(messageInfo, 'type');
  if (snackbarType === 'added') {
    messagePrefix = t('snackbars.addedTo');
  } else if (snackbarType === 'removed') {
    messagePrefix = t('snackbars.removedFrom');
  }

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };

  const snackPack = useAppSelector((state: RootState) => {
    return state.favourites.snackPack;
  });

  const handleClose = (_e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  useEffect(() => {
    if (snackPack.length && messageInfo === undefined) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      dispatch(setSnackPack(snackPack.slice(1)));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open, dispatch]);

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
      className={classes.snackBar}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      message={messageInfo
        ? (
          <>
            <HeartIcon className={classes.icon}/>
            {' '}
            {messagePrefix}
            {' '}
            <OdLink
              to="/favourites"
              className={classes.links}
            >
              {t('snackbars.favourites')}
            </OdLink>
          </>
        )
        : undefined}
      action={(
        <IconButton
          size="small"
          aria-label={t('snackbars.close')}
          color="inherit"
          classes={{ root: classes.closeButton }}
          onClick={(event) => {
            handleClose(event, undefined);
          }}
        >
          <CloseIcon fontSize="small"/>
        </IconButton>
      )}
    />
  );
};

export default FavouritesSnackbar;
