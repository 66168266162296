import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { get } from 'lodash';
import {
  FunctionComponent, ReactElement, cloneElement,
  useEffect, useState, createRef, useRef, useCallback, RefObject,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { getCurrentBreakpoint } from '@@src/styles/breakpoints';
import {
  onButtonKeyListener,
  tileItemsPerViewTall,
  tileItemsPerViewLandscape,
  tileItemsPerViewPortrait,
  tileItemsPerViewSquare,
} from '@@utils/helpers';

import { ReactComponent as RightArrowIcon } from '../../images/icons/right-arrow.svg';
import grey from '../../styles/colors/grey';
import padding from '../../styles/padding';
import ActivateTileButton from '../Tiles/ActivateTileButton';

// transition duration & delay for the zoom animation
export const transitionDuration = '200ms';
export const transitionDelay = '200ms';
const growFactor = 1.3;

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    slider: {
      padding: `0 ${padding.container.xs}px`,
      position: 'relative',
      transition: `transform ${transitionDuration}`,
      transitionDelay,
      zIndex: 1,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: padding.container.sm,
        paddingRight: padding.container.sm - 8,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: padding.container.md,
        paddingRight: padding.container.md - 8,
        paddingBottom: padding.container.md - 40,
      },
      '&:hover $handleIcon': {
        opacity: 0.7,
      },
    },
    handle: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 60,
      display: 'flex',
      background: alpha(grey.darkBlackPearl, 0.7),
      cursor: 'pointer',
      fontSize: '3rem',
      zIndex: 1,
      '&:hover $handleIcon, &:focus $handleIcon': {
        opacity: 1,
      },
    },
    handlePrev: {
      left: 0,
      zIndex: 1,
      '& $handleIcon': {
        transform: 'rotate(180deg)',
      },
    },
    handleNext: {
      right: 0,
    },
    handleIcon: {
      margin: 'auto',
      opacity: 0,
      transition: '200ms opacity',
      // on non hover media query, the icon is always visible
      '@media (hover:none)': {
        opacity: 0.7,
      },
    },
    sliderContent: {
      whiteSpace: 'nowrap',
      transition: '500ms transform', // sliding animation
    },
    sliderItemContainer: {
      width: '25%',
      transition: `transform ${transitionDuration}`,
      transform: 'scale(1)',
      transitionDelay,
      display: 'inline-block',
      position: 'relative',
      '&:first-child': {
        marginLeft: -4,
      },
      padding: '0 4px',
    },
    'tile-landscape': {
      width: `${100 / tileItemsPerViewLandscape.xs}%`,
      [theme.breakpoints.only('sm')]: {
        width: `${100 / tileItemsPerViewLandscape.sm}%`,
      },
      [theme.breakpoints.only('md')]: {
        width: `${100 / tileItemsPerViewLandscape.md}%`,
      },
      [theme.breakpoints.only('lg')]: {
        width: `${100 / tileItemsPerViewLandscape.lg}%`,
      },
      [theme.breakpoints.up('xl')]: {
        width: `${100 / tileItemsPerViewLandscape.xl}%`,
      },
    },
    'tile-portrait': {
      width: `${100 / tileItemsPerViewPortrait.xs}%`,
      [theme.breakpoints.only('sm')]: {
        width: `${100 / tileItemsPerViewPortrait.sm}%`,
      },
      [theme.breakpoints.only('md')]: {
        width: `${100 / tileItemsPerViewPortrait.md}%`,
      },
      [theme.breakpoints.only('lg')]: {
        width: `${100 / tileItemsPerViewPortrait.lg}%`,
      },
      [theme.breakpoints.up('xl')]: {
        width: `${100 / tileItemsPerViewPortrait.xl}%`,
      },
    },
    'tile-tall': {
      width: `${100 / tileItemsPerViewTall.xs}%`,
      [theme.breakpoints.only('sm')]: {
        width: `${100 / tileItemsPerViewTall.sm}%`,
      },
      [theme.breakpoints.only('md')]: {
        width: `${100 / tileItemsPerViewTall.md}%`,
      },
      [theme.breakpoints.only('lg')]: {
        width: `${100 / tileItemsPerViewTall.lg}%`,
      },
      [theme.breakpoints.up('xl')]: {
        width: `${100 / tileItemsPerViewTall.xl}%`,
      },
    },
    'tile-square': {
      width: `${100 / tileItemsPerViewSquare.xs}%`,
      [theme.breakpoints.only('sm')]: {
        width: `${100 / tileItemsPerViewSquare.sm}%`,
      },
      [theme.breakpoints.only('md')]: {
        width: `${100 / tileItemsPerViewSquare.md}%`,
      },
      [theme.breakpoints.only('lg')]: {
        width: `${100 / tileItemsPerViewSquare.lg}%`,
      },
      [theme.breakpoints.up('xl')]: {
        width: `${100 / tileItemsPerViewSquare.xl}%`,
      },
      '&:focus-visible': {
        outline: 'none',
      },
    },
    scrollWrapper: {
      overflowY: 'hidden',
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  });
});

interface TileSliderProps {
  tiles?: ReactElement[];
  hasMoreItems?: boolean;
  hasActiveState: boolean;
  tileSpec: {
    width: number;
    height: number;
    imageHeight: number;
    itemsPerView: number;
    orientation: string;
  };
}

const TileSlider: FunctionComponent<TileSliderProps> = (props) => {
  const {
    tiles,
    tileSpec,

    hasMoreItems = false,
    hasActiveState = false,
  } = props;

  const breakpoint = getCurrentBreakpoint();

  const {
    itemsPerView,
    width: tileWidth,
    imageHeight: tileImageHeight,
    orientation: tileOrientation,
  } = tileSpec;

  const classes = useStyles(props);

  const hoverDevice = useMediaQuery('(hover: hover)');
  const allowScroll = isMobile || !hoverDevice;

  const { t } = useTranslation('common');

  const widthGrow = tileWidth * (growFactor - 1);

  const itemRefs = useRef<RefObject<HTMLDivElement>[]>([]);

  const [activeTiles, setActiveTiles] = useState({});
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);

  /**
   * anchorTile is the first item in the current page,
   * it should stay visible when itemsPerView changes
   */
  const [anchorTile, setAnchorTile] = useState(0);

  const scrollWrapperRef = createRef<HTMLDivElement>();
  const sliderContentRef = createRef<HTMLDivElement>();

  const hasPrev = page > 1;
  const hasNext = page * itemsPerView < tiles.length;

  const goToPage = useCallback((pageNo) => {
    let x = -(pageNo - 1) * 100;
    const lastPage = (pageNo) * itemsPerView >= tiles.length;

    if (lastPage && !hasMoreItems) {
      x = -(tiles.length / itemsPerView - 1) * 100;
    }

    if (sliderContentRef.current) {
      sliderContentRef.current.style.transform = `translate3d(${x}%, 0, 0)`;
    }
  }, [hasMoreItems, itemsPerView, sliderContentRef, tiles.length]);

  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPage((state) => {
        const newPage = state - 1;
        setAnchorTile((newPage - 1) * itemsPerView);
        goToPage(newPage);
        return newPage;
      });
    }
  }, [goToPage, hasPrev, itemsPerView]);

  const nextPage = useCallback(() => {
    if (hasNext) {
      setPage((state) => {
        const newPage = state + 1;
        setAnchorTile(Math.min(tiles.length - itemsPerView, (newPage - 1) * itemsPerView));
        goToPage(newPage);
        return newPage;
      });
    }
  }, [goToPage, hasNext, itemsPerView, tiles.length]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  // when items per view changes, change the page
  useEffect(() => {
    const _page = Math.floor(anchorTile / itemsPerView) + 1;
    if (page !== 0 && page !== _page) {
      goToPage(_page);
      setPage(_page);
    }
  }, [itemsPerView]); // eslint-disable-line react-hooks/exhaustive-deps

  const activate = useCallback((index) => {
    // if allow scroll, don't activate
    if (allowScroll || !hasActiveState) {
      return;
    }

    setActiveTiles({
      [index]: true,
    });

    // no zoom in animation for small breakpoints
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return;
    }

    // start and end index in the current view;
    let startIndex = Math.floor(index / itemsPerView) * itemsPerView;
    let endIndex = (Math.floor(index / itemsPerView) + 1) * itemsPerView - 1;

    // potentialMaxNumberOfItems is the total number of items up to the end of the current page.
    // For example, if there are 7 items per page and we are at page 3, then that means if each page
    // was full of new items, there should be 21 different items since page 1.
    const potentialMaxNumberOfItems = page * itemsPerView;

    // If at any page, the total number of items is lower than potentialMaxNumberOfItems
    // that means we didn't have enough item to do a full page scroll, so we need to adjust the start and end index.
    if (itemRefs.current.length < potentialMaxNumberOfItems) {
      startIndex = itemRefs.current.length - itemsPerView - 1;
      endIndex = itemRefs.current.length - 1;
    }

    // move left
    if (index !== startIndex) {
      for (let i = Math.max(0, startIndex - 1); i < index; i += 1) {
        const leftItemRef = get(itemRefs, `current[${i}].current`);
        if (leftItemRef) {
          const x = index === endIndex ? -widthGrow : -widthGrow / 2;
          Object.assign(leftItemRef.style, {
            transform: `translate3d(${x}px, 0, 0)`,
          });
        }
      }
    }

    const activeItemRef = get(itemRefs, `current[${index}].current`);
    if (activeItemRef) {
      let transformOrigin = 'center center';

      if (index === startIndex) {
        transformOrigin = 'left center';
      } else if (index === endIndex) {
        transformOrigin = 'right center';
      }

      Object.assign(activeItemRef.style, {
        transform: `scale(${growFactor})`,
        transformOrigin,
      });
    }

    // move right
    if (index !== endIndex) {
      for (let i = index + 1; i < itemRefs.current.length && i <= endIndex + 1; i += 1) {
        const rightItemRef = get(itemRefs, `current[${i}].current`);
        if (rightItemRef) {
          const x = index === startIndex ? widthGrow : widthGrow / 2;
          Object.assign(rightItemRef.style, {
            transform: `translate3d(${x}px, 0, 0)`,
          });
        }
      }
    }
  }, [allowScroll, breakpoint, hasActiveState, itemsPerView, page, widthGrow]);

  const deactivate = useCallback((index = null) => {
    if (!hasActiveState) return;

    if (index !== null) {
      setActiveTiles((state) => {
        return {
          ...state,
          [index]: false,
        };
      });
    } else {
      setActiveTiles({});
    }

    // no zoom in animation for small breakpoints
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return;
    }

    for (let i = 0; i < itemRefs.current.length; i += 1) {
      const currentRef = get(itemRefs, `current[${i}].current`);
      if (currentRef) {
        Object.assign(currentRef.style, {
          transform: 'translate3d(0, 0, 0)',
        });
      }
    }
  }, [breakpoint, hasActiveState]);

  const handleClickNext = useCallback((e) => {
    // change focus to the first item for a keyboard event
    if (e.detail === 0) {
      const focusIndex = page * itemsPerView;
      if (itemRefs.current[focusIndex].current) {
        // container is focusable, lets focus it
        const tabIndex = itemRefs.current[focusIndex].current.getAttribute('tabindex');
        // for Tall tile, the tabIndex will be null
        if (tabIndex === null) {
          // container is not focusable, get the first anchor element (eg: for TALL tile)
          const anchor = itemRefs.current[focusIndex].current.querySelector('a');
          if (anchor) {
            anchor.focus();
          }
        } else {
          itemRefs.current[focusIndex].current.focus();
        }
      }
    }

    nextPage();
  }, [itemsPerView, nextPage, page]);

  const handleClickPrev = useCallback((e) => {
    // change focus to the last item for a keyboard event
    if (e.detail === 0) {
      const focusIndex = (page - 1) * itemsPerView - 1;
      if ((itemRefs.current[focusIndex].current)) {
        // container is focusable, lets focus it
        const tabIndex = itemRefs.current[focusIndex].current.getAttribute('tabindex');
        // for Tall tile, the tabIndex will be null
        if (tabIndex === null) {
          // container is not focusable, get the first anchor element (eg: for TALL tile)
          const anchor = itemRefs.current[focusIndex].current.querySelector('a');
          if (anchor) {
            anchor.focus();
          }
        } else {
          itemRefs.current[focusIndex].current.focus();
        }
      }
    }

    prevPage();
  }, [itemsPerView, page, prevPage]);

  if (tiles && tiles.length > 0) {
    // create the refs
    const _itemRefs = [];
    tiles.forEach(() => {
      _itemRefs.push(createRef<HTMLDivElement>());
    });

    itemRefs.current = _itemRefs;

    return (
      <div className={clsx({ [classes.scrollWrapper]: allowScroll })} ref={scrollWrapperRef}>
        <div className={`prefers-reduced-motion ${classes.slider}`} data-testid="tile-slider">
          {!allowScroll && loaded && hasPrev && (
            <div
              role="button"
              aria-label={t('navigation.prevItemHandleLabel')}
              tabIndex={0}
              onKeyPress={onButtonKeyListener(handleClickPrev)}
              onClick={handleClickPrev}
              className={`${classes.handle} ${classes.handlePrev}`}
            >
              <RightArrowIcon className={classes.handleIcon}/>
            </div>
          )}
          {/*
             Note: Setting role="application" on the shelf,
             so that the tiles will receive keypress event when pressing enter with JAWS, instead of a mouse click
          */}
          <div role="application" className={classes.sliderContent} ref={sliderContentRef}>
            {
              tiles.map((tile, index) => {
                const itemRef = itemRefs.current[index];

                // only set tabable when loaded client side
                let tabable = loaded && index >= (page - 1) * itemsPerView && index < page * itemsPerView;

                // last page
                if (loaded && page * itemsPerView >= tiles.length) {
                  tabable = index >= tiles.length - itemsPerView;
                }

                const tileProps = tile.props.is === 'placeholder' ? {} : {
                  tabable,
                  imageHeight: tileImageHeight,
                  isActive: activeTiles[index],
                };

                const sliderItemContainerProps = {
                  ref: itemRef,
                  className: clsx(classes.sliderItemContainer, classes[`tile-${tileOrientation}`]),
                };

                if (hasActiveState) {
                  return (
                    <ActivateTileButton
                      key={tile.key}
                      aria-label={tile.props.item ? tile.props.item.title : ''}
                      tabIndex={tabable ? 0 : -1}
                      onActivate={() => {
                        activate(index);
                      }}
                      onDeactivate={() => {
                        deactivate(index);
                      }}
                      {...sliderItemContainerProps}
                    >
                      {cloneElement(tile, tileProps)}
                    </ActivateTileButton>
                  );
                }

                return (
                  <div
                    key={tile.key}
                    aria-hidden={!tabable}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...sliderItemContainerProps}
                  >
                    {cloneElement(tile, tileProps)}
                  </div>
                );
              })
            }
          </div>
          {!allowScroll && loaded && hasNext && (
            <div
              aria-label={t('navigation.nextItemHandleLabel')}
              role="button"
              tabIndex={0}
              onClick={handleClickNext}
              onKeyPress={onButtonKeyListener(handleClickNext)}
              className={`${classes.handle} ${classes.handleNext}`}
            >
              <RightArrowIcon className={classes.handleIcon}/>
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default TileSlider;
