import OnDemand2 from '@@src/@types/OnDemand2';
import { OdLinkProps } from '@@src/routes';
import { transformSearchResultToGridRow } from '@@src/transformers/ContentSearchApiSearchResultTransformer';
import Logger from '@@utils/logger/Logger';

import * as CatalogueApi from '../apis/CatalogueApi';
import SearchApi from '../apis/SearchApi';

export interface Suggestion {
  id: string;
  label: string;
  route?: OdLinkProps;
}

export function getPopularSearches(): Promise<Suggestion[]> {
  const maxSuggestions = 6;
  return CatalogueApi.getPopularSearches().then((result) => {
    return result.slice(0, maxSuggestions);
  });
}

export function search(query: string, nextCursor: string | null = null, pageSize: number = 60): Promise<{
  items: OnDemand2.CollectionItem[];
  nextCursor: string | null;
}> {
  return SearchApi.performSearch(query, nextCursor, pageSize).then((result) => {
    try {
      return transformSearchResultToGridRow(result);
    } catch (e) {
      Logger.warn(new Error('Error on performSearch'), {
        error: {
          message: e.message,
        },
      });
    }

    return {
      items: [],
      nextCursor: null,
    };
  });
}

export function getSuggestions(query: string): Promise<Suggestion[]> {
  return search(query, null, 5).then((result) => {
    return result.items.map((resultItem) => {
      return {
        id: resultItem.id,
        label: resultItem.title,
        route: resultItem.route,
      };
    });
  });
}

export default {
  getSuggestions,
  getPopularSearches,
  search,
};
