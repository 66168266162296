import { get } from 'lodash';

import { ImageProps } from '@@src/components/Html/Image';
import OnDemand from '@@types/OnDemand';
import { httpClientWithAuth } from '@@utils/HttpClient';
import { FAVOURITE_ADD_PATH, FAVOURITE_LIST_PATH, FAVOURITE_REMOVE_PATH, FAVOURITE_FEED_PATH } from '@@utils/constants';

import { transformToLandscapeTileItem } from '../transformers/FeedTransformer';
import { FeedApi } from './FeedApi';

interface AddFavouriteResponse {
  add: {
    response: {
      result: boolean;
      detail: {
        type: 'program' | 'movie';
        itemId: string;
      };
    };
    status: boolean;
  };
}

export async function add(type: 'program' | 'movie', id: string): Promise<boolean> {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);
  try {
    const response = await httpClientWithAuth.post<AddFavouriteResponse>(FAVOURITE_ADD_PATH, formData);
    return response.data.add.response.result;
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error('Unauthorised');
    }
    throw error;
  }
}

interface RemoveFavouriteResponse {
  remove: {
    response: {
      result: boolean;
    };
    status: boolean;
  };
}

export async function remove(type: 'program' | 'movie', id: string): Promise<boolean> {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);

  try {
    const response = await httpClientWithAuth.post<RemoveFavouriteResponse>(FAVOURITE_REMOVE_PATH, formData);
    return get(response, 'data.remove.response.result', false);
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error('Unauthorised');
    }
    throw error;
  }
}

interface ListAllFavouritesResponse {
  all: {
    response: {
      movies: string[];
      programs: string[];
      programs_fda: string[];
    };
    status: boolean;
  };
}

export async function list(): Promise<{ videos: string[], series: string[] }> {
  try {
    const response = await httpClientWithAuth.get<ListAllFavouritesResponse>(FAVOURITE_LIST_PATH);
    return {
      videos: response.data.all.response.movies,
      series: [...response.data.all.response.programs, ...response.data.all.response.programs_fda],
    };
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error('Unauthorised');
    }
    throw error;
  }
}

export async function fetchFavouriteItems(page: number = 1, pageSize: number = 30): Promise<{
  items: OnDemand.Tile<OnDemand.Video | OnDemand.TvSeries, ImageProps>[],
  hasMoreItems: boolean
}> {
  let options = null;

  if (pageSize) {
    const start = (page - 1) * pageSize + 1;
    const end = start + (pageSize - 1);
    const range = `${start}-${end}`;
    options = {
      params: {
        range,
      },
    };
  }

  try {
    const response = await httpClientWithAuth.get<FeedApi.Response>(FAVOURITE_FEED_PATH, options);
    const { numberOfItems, totalNumberOfItems, itemListElement } = response.data;
    const previousNumberOfItems = (page - 1) * pageSize;

    const favouriteItems = itemListElement.filter((feedItem) => {
      return !!feedItem.id;
    }).map(transformToLandscapeTileItem);

    return {
      items: favouriteItems,
      hasMoreItems: previousNumberOfItems + numberOfItems < totalNumberOfItems,
    };
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error('Unauthorised');
    }
    throw error;
  }
}

export default {
  add,
  remove,
  list,
  fetchFavouriteItems,
};
