import EventEmitter from 'eventemitter3';
import { canUseDOM } from 'exenv';

import Logger from '@@utils/logger/Logger';

declare global {
  interface Window {
    googletag: any;
  }
}

export default class GptManager extends EventEmitter {
  private loadPromise: Promise<unknown>;
  public googletag: any = { cmd: [] };
  public serviceEnabled = false;

  private counter = 0;

  generateId() {
    this.counter += 1;
    return `gpt-${this.counter}`;
  }

  enableServices() {
    if (!this.serviceEnabled) {
      this.googletag.enableServices();
      this.serviceEnabled = true;
    }
  }

  load(): Promise<any> {
    Logger.debug('GptManager: load');
    if (!this.loadPromise) {
      Logger.debug('GptManager: first load');
      this.loadPromise = new Promise((resolve, reject) => {
        const onLoad = () => {
          if (window.googletag) {
            this.googletag = window.googletag;
            resolve(this.googletag);
          } else {
            reject(new Error('window.googletag is not available'));
          }
        };

        if (!canUseDOM) {
          reject(new Error('DOM not available'));
          return;
        }

        if (window.googletag && window.googletag.apiReady) {
          onLoad();
        } else {
          const script = document.createElement('script');
          script.async = true;
          script.onload = onLoad;
          script.onerror = () => {
            reject(new Error('Failed to load script'));
          };
          script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
          document.head.appendChild(script);
        }
      });
    }
    Logger.debug('GptManager: return loadPromise');
    return this.loadPromise;
  }
}
