import { head } from 'lodash';

import { JsonLdFeedResponse } from '@@types/Api';
import httpClient, { handleHttpError } from '@@utils/HttpClient';
import { VIDEO_LOOKUP_PATH } from '@@utils/constants';

import { FeedItem } from './FeedApi';

export function fetch(id): Promise<FeedItem.Video> {
  const url = VIDEO_LOOKUP_PATH.replace('[VIDEOID]', id);

  return httpClient.get<JsonLdFeedResponse<FeedItem.Video>>(url)
    .then((response) => {
      const { data } = response;
      return head(data.itemListElement);
    })
    .catch((e) => {
      return handleHttpError(
        e,
        new Error('Error on VideoLookupApi.fetch()'),
        {},
        [404],
        null,
        'warn',
      );
    });
}

export default {
  fetch,
};
