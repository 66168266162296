import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles, VideoPlayWidgetProps } from '@@src/components/Video/VideoPlayWidget';
import { ReactComponent as PlayIcon } from '@@src/images/icons/play-circle.svg';

type ExpiredPlayWidgetProps = Optional<VideoPlayWidgetProps, 'video'> & {
  title?: string;
};

const ExpiredVideoPlayWidget: FunctionComponent<ExpiredPlayWidgetProps> = (props) => {
  const {
    video,
    title,
  } = props;

  const classes = useStyles(props);
  const { t, i18n } = useTranslation('common');

  let _title = t('video.expired');

  const videoType = get(video, 'type');
  const isLiveStream = get(video, 'isLiveStream', false);

  if (isLiveStream) {
    _title = t('video.expired[Livestream]');
  } else if (i18n.exists(`video.expired[${videoType}]`)) {
    _title = t(`video.expired[${video.type}]`);
  }

  return (
    <div className={classes.root}>
      <div className={classes.playButtonContainer}>
        <Button
          className={clsx(classes.playButton)}
          disabled
          aria-disabled
          data-testid="video-play-widget-play-button"
          disableElevation
          disableRipple
          disableFocusRipple
        >
          <PlayIcon/>
        </Button>
      </div>
      <div className={clsx(classes.centeredContent, classes.expiredTitle)}>
        {!video?.isLiveStream && (
          <div className={classes.title}>{title || _title}</div>
        )}
        {video?.isLiveStream && (
          <>
            <div className={classes.availabilityLabel}>{title || _title}</div>
            <div className={clsx(classes.watchLabel, 'no-badge')}>{t('video.catchupAvailableSoon')}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpiredVideoPlayWidget;
