import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import OnDemand2 from '@@src/@types/OnDemand2';
import padding from '@@src/styles/padding';

import GridV2 from '../Grid/GridV2';
import ShelfTitle from './ShelfTitle';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    gridShelf: {
      padding: `0 ${padding.container.xs}px`,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: padding.container.sm,
        paddingRight: padding.container.sm,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: padding.container.md,
        paddingRight: padding.container.md,
        paddingBottom: padding.container.md - 40,
      },
    },
    shelfTitle: {
      marginBottom: '0.875rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0.75rem',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '0.625rem',
      },
    },
    gridContainer: {
      // to align with the scrollWrapper on the other shelves
      marginLeft: '-4px',
      marginRight: '-4px',
    },
  });
});

type GridShelfV2Props = {
  collection: OnDemand2.Collection;
};

const GridShelfV2: FunctionComponent<GridShelfV2Props> = (props) => {
  const { collection } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.gridShelf}>
      <div className={classes.shelfTitle}>
        <ShelfTitle
          name={collection.title}
          route={collection.route}
        />
      </div>
      <div className={classes.gridContainer}>
        <GridV2 collection={collection}/>
      </div>
    </div>
  );
};

export default GridShelfV2;
