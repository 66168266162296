import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import getPlaybackStream from '@@src/apis/PlaybackApi';
import RemotePlayer from '@@src/components/RemotePlayer/RemotePlayer';
import { useAppSelector, useAppDispatch, useAppStore } from '@@src/hooks/store';
import useChromeCast from '@@src/hooks/useChromeCast';
import CastPlayer from '@@src/lib/VideoPlayerV2/CastPlayer';
import { getResumePosition, loadProgressesOnce, loadProgresses } from '@@stores/ProgressStore';
import { getSessionId } from '@@stores/UserStore';
import {
  getCasting,
  getCurrentTime,
  getIsPaused,
  getSessionState,
  getIsAd,
  setCasting,
  setCastState,
  setCurrentTime,
  setIsPaused,
  setMediaInfo,
  setSessionState,
  setIsAd,
  setActiveTracks,
  getMediaDetails,
  getCastState,
  getMediaLoading,
  loadMediaAsyncThunk,
  setHasDevicesAvailable,
} from '@@stores/chromeCastStore';
import { PlaybackStreamData } from '@@types/PlaybackStreamData';
import { hasFeature } from '@@utils/config';
import Logger from '@@utils/logger/Logger';

/**
 * MiniPlayerMenu component represents a small player menu with playback controls
 * for casting content to Google Cast devices.
 */
export const ChromeCastRemotePlayer: React.FC = () => {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const { i18n: { language } } = useTranslation('common');

  const [playbackData, setPlaybackData] = useState<PlaybackStreamData>(undefined);
  const castAvailable = useChromeCast();

  const isCasting = useAppSelector(getCasting);
  const sessionState = useAppSelector(getSessionState);
  const castState = useAppSelector(getCastState);
  const isAd = useAppSelector(getIsAd);
  const isPaused = useAppSelector(getIsPaused);
  const sessionId = useAppSelector(getSessionId);
  const loading = useAppSelector(getMediaLoading);

  const currentTime = useAppSelector(getCurrentTime);
  const {
    entityId: castedVideoId, duration, isLive, tracks, breaks,
  } = useAppSelector(getMediaDetails);

  const params = useParams<{ id: string }>();
  const location = useLocation();
  const [currentVideoId, setCurrentVideoId] = useState<string>(params.id);

  /**
   * When the video player changes video ID internally, it calls window.history.replaceState()
   * which does not trigger a change in params.id or location.history.pathname.
   * This useEffect listens to a change of params.id (from user changing page) and to the player OdPlayerVideoChange event
   * to update the currentVideoId state in order to fetch the data for the newly requested video.
   */
  useEffect(() => {
    setCurrentVideoId(params.id);

    const handlePlayerChangedVideo = (event: CustomEvent) => {
      const { detail: { videoId } } = event;
      setCurrentVideoId(videoId);
    };

    document.addEventListener('OdPlayerVideoChange', handlePlayerChangedVideo as EventListener);

    return () => {
      document.removeEventListener('OdPlayerVideoChange', handlePlayerChangedVideo as EventListener);
    };
  }, [params.id]);

  /**
   * Handle casting status when starting session and reconnecting
   */
  useEffect(() => {
    if (sessionState === 'SESSION_ENDED') {
      dispatch(loadProgresses()).then(() => {
        dispatch(setCasting(false));
      });
    } else {
      dispatch(setCasting([
        'SESSION_RESUMED',
        'SESSION_STARTED',
      ].includes(sessionState)));
    }
  }, [dispatch, sessionState]);

  /**
   * When the current video ID changes, fetch new playback data
   */
  useEffect(() => {
    const videoId = currentVideoId || castedVideoId;

    if (
      castAvailable
      && castState === cast.framework.CastState.CONNECTED
      && !playbackData
      && videoId
    ) {
      getPlaybackStream(videoId, language)
        .then((_playbackData) => {
          if (!('error' in _playbackData)) {
            setPlaybackData(_playbackData);
          }
        });
    }
  }, [castAvailable, castState, dispatch, castedVideoId, language, currentVideoId, playbackData]);

  /**
   * load new video when user navigates to watch page
   */
  useEffect(() => {
    if (
      location.pathname.includes('/watch')
      && castAvailable
      && castState === cast.framework.CastState.CONNECTED
      && currentVideoId
      && sessionId
      && playbackData
    ) {
      dispatch(loadProgressesOnce()).then(() => {
        return getResumePosition(store.getState(), playbackData.mpxId);
      }).then((resumePosition) => {
        dispatch(loadMediaAsyncThunk({
          checkReconnection: true,
          entityId: playbackData.mpxId,
          isLive: playbackData.streamType === 'live',
          resumePosition,
          sessionId,
        }));
      });
    }
  }, [castAvailable, castState, dispatch, location.pathname, currentVideoId, playbackData, sessionId, store]);

  /**
   * If casting is available, check whether there is an existing cast session
   */
  useEffect(() => {
    if (!castAvailable) {
      return;
    }

    if (CastPlayer.current.currentSession === null) {
      return;
    }

    const initialSessionState = CastPlayer.current.currentSession.getSessionState();

    dispatch(setSessionState(initialSessionState));
  }, [dispatch, castAvailable]);

  /**
   * Attached event listeners to the cast context and remote player
   */
  useEffect(() => {
    dispatch(setHasDevicesAvailable(CastPlayer.hasAvailableDevices()));

    const handleSessionState = (event) => {
      dispatch(setSessionState(event.sessionState));
      if (event.sessionState === 'SESSION_ENDED') {
        dispatch(setIsPaused(undefined));
      }
    };

    const handleCurrentTimeChanged = (event) => {
      dispatch(setCurrentTime(event.value));
      if (isCasting === true && isPaused === undefined) {
        dispatch(setIsPaused(false));
      }
    };

    const handleIsPausedChanged = (event) => {
      dispatch(setIsPaused(event.value));
    };

    const handleMediaInfoChanged = (event) => {
      dispatch(setMediaInfo(event.value));
      dispatch(setActiveTracks(CastPlayer.current.activeTrackIds));
    };

    const handleCastState = (event) => {
      const { castState: state } = event;
      dispatch(setCastState(state));
      dispatch(setHasDevicesAvailable(CastPlayer.hasAvailableDevices(state)));
    };

    const handleIsPlayingBreakChanged = (event) => {
      dispatch(setIsAd(event.value));
    };

    if (castAvailable) {
      CastPlayer.current.addCastStateEventListener(handleCastState);
      CastPlayer.current.addSessionStateEventListener(handleSessionState);

      CastPlayer.current.addRemotePlayerEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, handleCurrentTimeChanged);
      CastPlayer.current.addRemotePlayerEventListener(cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED, handleIsPausedChanged);
      CastPlayer.current.addRemotePlayerEventListener(cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, handleMediaInfoChanged);
      // @ts-ignore unresolved variable IS_PLAYING_BREAK_CHANGED
      CastPlayer.current.addRemotePlayerEventListener(cast.framework.RemotePlayerEventType.IS_PLAYING_BREAK_CHANGED, handleIsPlayingBreakChanged);
    }

    return () => {
      if (castAvailable) {
        CastPlayer.current.removeSessionStateEventListener(handleSessionState);
        CastPlayer.current.removeCastStateEventListener(handleCastState);

        CastPlayer.current.removeRemotePlayerEventListener(cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, handleCurrentTimeChanged);
        CastPlayer.current.removeRemotePlayerEventListener(cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED, handleIsPausedChanged);
        CastPlayer.current.removeRemotePlayerEventListener(cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED, handleMediaInfoChanged);
        // @ts-ignore unresolved variable IS_PLAYING_BREAK_CHANGED
        CastPlayer.current.removeRemotePlayerEventListener(cast.framework.RemotePlayerEventType.IS_PLAYING_BREAK_CHANGED, handleIsPlayingBreakChanged);
      }
    };
  }, [castAvailable, currentTime, dispatch, isCasting, isPaused]);

  const onClickPlay = useCallback(() => {
    if (isLive) {
      CastPlayer.current.seekToLive();
    }
    CastPlayer.current.play();
  }, [isLive]);

  const onClickPause = () => {
    CastPlayer.current.pause();
  };

  const onClickRewind = useCallback(() => {
    CastPlayer.current.seek(currentTime - 10);
  }, [currentTime]);

  const onClickFastForward = useCallback(() => {
    CastPlayer.current.seek(currentTime + 10);
  }, [currentTime]);

  const handleOnSeek = useCallback((targetPercent: number) => {
    if (!duration) {
      Logger.warn('Attempted to seek without duration defined', { duration });
      return;
    }

    const targetTime = (targetPercent / 100) * duration;
    CastPlayer.current.seek(targetTime);
  }, [duration]);

  if (isCasting === false || castAvailable === false) {
    return null;
  }

  let deviceName;

  if (sessionState && ['SESSION_STARTED', 'SESSION_RESUMED'].includes(sessionState)) {
    if (CastPlayer.current.currentSession) {
      deviceName = CastPlayer.current.currentSession.getCastDevice().friendlyName;
    }
  }

  const handleRequestSession = () => {
    CastPlayer.current.castContext.requestSession().catch((err) => {
      Logger.warn(`Request session ${err}`);
    });
  };

  const handleRequestTrack = (trackId: number) => {
    CastPlayer.current.requestTrack(trackId, () => {
      dispatch(
        setActiveTracks(
          CastPlayer.current.activeTrackIds,
        ),
      );
    }, () => {
      dispatch(
        setActiveTracks(
          undefined,
        ),
      );
    });
  };

  let statusLabel;

  switch (true) {
    case (castState === cast.framework.CastState.NO_DEVICES_AVAILABLE):
      statusLabel = 'No cast device available';
      break;

    case (castState === cast.framework.CastState.CONNECTING):
      statusLabel = `Connecting to ${deviceName}`;
      break;

    case (loading === true):
      statusLabel = `Loading media to ${deviceName}`;
      break;

    case (isPaused === true):
      statusLabel = `Paused on ${deviceName}`;
      break;

    case (isPaused === false):
      statusLabel = `Playing on ${deviceName}`;
      break;

    case (castState === cast.framework.CastState.CONNECTED):
      statusLabel = `Connected to ${deviceName}`;
      break;

    default:
      statusLabel = null;
      break;
  }

  /**
   * If there is an existing cast session render the mini player
   */
  return (
    <RemotePlayer
      timelineMarkers={breaks}
      currentTime={currentTime}
      onClickPlay={onClickPlay}
      onClickPause={onClickPause}
      duration={CastPlayer.current.duration}
      onClickRewind={onClickRewind}
      onClickFastForward={onClickFastForward}
      onSeek={handleOnSeek}
      isPaused={isPaused}
      statusLabel={statusLabel}
      onClickCastButton={handleRequestSession}
      onSelectSubtitle={handleRequestTrack}
      isAd={isAd}
      isLive={isLive}
      playbackData={playbackData}
      loading={loading}
      tracks={tracks}
    />
  );
};

const ChromeCastRemotePlayerWithFlag = () => {
  if (!hasFeature('watchPageV2')) {
    return null;
  }

  return <ChromeCastRemotePlayer/>;
};

export default ChromeCastRemotePlayerWithFlag;
