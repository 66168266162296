import { Fade, ListItemIcon, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { setLanguage } from '@@stores/UserPreferencesStore';
import { getIsLoggedIn } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';
import { MYACCOUNT_HOST } from '@@utils/constants';
import Logger from '@@utils/logger/Logger';

import { languageLabels, getLocalizedPath, isRtl } from '../../i18n';
import { ReactComponent as ContactUsIcon } from '../../images/icons/contact-us.svg';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as LanguagesIcon } from '../../images/icons/languages.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/log-out.svg';
import { ReactComponent as ManageAccountIcon } from '../../images/icons/manage-account.svg';
import { ReactComponent as MercatorIcon } from '../../images/icons/mercator.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as UpChevronIcon } from '../../images/icons/up-chevron.svg';
import { OdLink } from '../../routes';
import fontFamily from '../../styles/typography/fontFamily';
import { dispatchSignOut } from '../Login/LoginForm';

const useStyles = makeStyles(() => {
  return createStyles({
    menuPopper: {
      zIndex: 1200,
      minWidth: 300,
    },
    menu: {
      borderRadius: 4,
    },
    menuMessageBannerMenuItem: {
      padding: 0,
    },
    menuMessageBanner: {
      borderRadius: '4px 4px 0 0',
    },
    flyoutMenuItem: {
      minHeight: 48,
      fontFamily: fontFamily.secondary,
      fontSize: '1em',
      padding: 0,
      '&.Mui-focusVisible': {
        outline: '3px solid transparent',
        outlineOffset: '-3px',
      },
      '&.Mui-disabled': {
        fontFamily: fontFamily.primary,
        opacity: 0.7,
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        alignItems: 'flex-end',
        '&:nth-child(n+3)': {
          marginTop: 8,
          paddingTop: 8,
        },
      },
      '&.Mui-disabled:nth-child(n+3), &$flyoutMenuItemDivider': {
        borderTop: `1px solid ${alpha(common.white, 0.10)}`,
      },
      '& > span, & > a, & > div': {
        width: '100%',
        padding: '6px 16px',
      },
      '& > a, & > div': {
        display: 'flex',
        alignItems: 'center',
        minHeight: 'inherit',
      },
    },
    itemListIconRoot: {
      minWidth: 0,
      marginRight: 12,
      fontSize: '1.5em',
    },
    flyoutMenuItemDivider: {},
    chevronIcon: {
      fontSize: 13,
    },
    languageSelectorExpand: {
      position: 'absolute',
      right: 25,
      transform: 'rotate(90deg)',
    },
    languageSelectorCollapse: {
      transform: 'rotate(-90deg)',
    },
    languageSpacer: {
      minWidth: 36,
    },
    languageLabel: {
      marginLeft: 6,
      marginRight: 6,
    },
    noPointerIcon: {
      pointerEvents: 'none',
    },
  });
});

interface MoreMenuProps {
  id: string;
  open: boolean;
  anchorEl: HTMLDivElement;
  disablePortal?: boolean;
  onClose?: () => void;
  onListKeyDown?: (e: React.KeyboardEvent) => void;
  placement?: 'bottom-end' | 'top-start';
}

const MoreMenu: FunctionComponent<MoreMenuProps> = (props) => {
  const classes = useStyles(props);
  const {
    id,
    open,
    anchorEl,
    disablePortal = false,
    onClose = () => {
      // intentionally empty
    },
    onListKeyDown = () => {
      // intentionally empty
    },
    placement = 'bottom-end',
  } = props;
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(getIsLoggedIn);
  const location = useLocation();
  const wrapperRef = useRef(null);
  const [moreMenuType, setMoreMenuType] = useState('main');

  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;

  const handleClose = useCallback(() => {
    setMoreMenuType('main');
    onClose();
  }, [onClose]);

  const handleClickOutside = useCallback((e) => {
    if (
      open
      && wrapperRef.current
      && !wrapperRef.current.contains(e.target)
      && !e.target.classList.contains('languageSelector')
    ) {
      Logger.debug('Click outside more menu');
      handleClose();
    }
  }, [handleClose, open]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const clickLogout = useCallback(() => {
    dispatchSignOut();
    handleClose();
  }, [handleClose]);

  const handleKeyDownLogout = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      dispatchSignOut();
      handleClose();
    }
  }, [handleClose]);

  const onMenuItemClick = useCallback((event) => {
    if (
      'type' in event
      && event.type === 'keydown'
      && event.code === 'Enter'
    ) {
      const { target } = event;
      const anchor = target.querySelector('a');
      if (anchor) {
        anchor.click();
      }
    }

    handleClose();
  }, [handleClose]);

  async function handleLanguageChange(lang) {
    handleClose();
    dispatch(setLanguage(lang));
    const localizedUrl = getLocalizedPath(lang, location);
    DataLayer.events.queueLanguageChange(lang, localizedUrl);
    window.location.href = localizedUrl;
  }

  const handleBackToMoreMenuMainClick = () => {
    setMoreMenuType('main');
  };

  const handleLanguageSelectorClick = () => {
    setMoreMenuType('languageSelector');
  };

  const moreMenuMain = (
    <MenuList autoFocusItem={open} onKeyDown={onListKeyDown} disablePadding>
      {(loggedIn) && (
        <MenuItem disabled className={classes.flyoutMenuItem}>
          <span>{t('menuItem.settings')}</span>
        </MenuItem>
      )}

      {loggedIn && (
        <MenuItem onClick={onMenuItemClick} className={classes.flyoutMenuItem}>
          <a href={MYACCOUNT_HOST} target="_blank" rel="noopener noreferrer">
            <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
              <ManageAccountIcon/>
            </ListItemIcon>
            {t('menuItem.manageAccount')}
          </a>
        </MenuItem>
      )}

      <MenuItem onClick={handleLanguageSelectorClick} className={classes.flyoutMenuItem}>
        <div className="languageSelector">
          <ListItemIcon className={classes.noPointerIcon} classes={{ root: classes.itemListIconRoot }}>
            <LanguagesIcon/>
          </ListItemIcon>
          {t('menuItem.currentLanguage', { language: get(languageLabels, `${currentLanguage}.0`) })}
          <UpChevronIcon className={clsx(classes.chevronIcon, classes.languageSelectorExpand, classes.noPointerIcon)}/>
        </div>
      </MenuItem>

      <MenuItem disabled className={classes.flyoutMenuItem}>
        <span>{t('menuItem.getToKnowUs')}</span>
      </MenuItem>
      <MenuItem onClick={onMenuItemClick} className={classes.flyoutMenuItem}>
        <a href="https://sbs.com.au" target="_blank" rel="noopener noreferrer">
          <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
            <MercatorIcon/>
          </ListItemIcon>
          {t('menuItem.visitSbs')}
        </a>
      </MenuItem>
      <MenuItem disabled className={classes.flyoutMenuItem}><span>{t('menuItem.support')}</span></MenuItem>
      <MenuItem onClick={onMenuItemClick} className={classes.flyoutMenuItem}>
        <OdLink
          to="https://help.sbs.com.au/hc/en-au/categories/360000172615-SBS-On-Demand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
            <HelpIcon/>
          </ListItemIcon>
          {t('menuItem.help')}
        </OdLink>
      </MenuItem>
      <MenuItem onClick={onMenuItemClick} className={classes.flyoutMenuItem}>
        <OdLink to="https://help.sbs.com.au/hc/en-au/requests/new" target="_blank" rel="noopener noreferrer">
          <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
            <ContactUsIcon/>
          </ListItemIcon>
          {t('menuItem.contact')}
        </OdLink>
      </MenuItem>

      {loggedIn && (
        <MenuItem
          onClick={clickLogout}
          onKeyDown={handleKeyDownLogout}
          className={clsx(classes.flyoutMenuItem, classes.flyoutMenuItemDivider)}
        >
          <div aria-label={t('menuItem.signOut')}>
            <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
              <LogOutIcon/>
            </ListItemIcon>
            {t('menuItem.signOut')}
          </div>
        </MenuItem>
      )}
    </MenuList>
  );

  const moreMenuLanguageSelector = (
    <MenuList autoFocusItem={open} onKeyDown={onListKeyDown} disablePadding>
      <MenuItem onClick={handleBackToMoreMenuMainClick} className={classes.flyoutMenuItem}>
        <div className="languageSelector">
          <ListItemIcon className={classes.noPointerIcon} classes={{ root: classes.itemListIconRoot }}>
            <UpChevronIcon className={clsx(classes.chevronIcon, classes.languageSelectorCollapse)}/>
          </ListItemIcon>
          {t('menuItem.chooseLanguage')}
        </div>
      </MenuItem>
      {
        Object.keys(languageLabels).map((lang) => {
          return (
            <MenuItem
              key={lang}
              onClick={() => {
                handleLanguageChange(lang);
              }}
              className={classes.flyoutMenuItem}
              role="button"
              aria-current={currentLanguage === lang}
            >
              <div>
                <div className={classes.languageSpacer}>
                  {currentLanguage === lang && (
                    <ListItemIcon classes={{ root: classes.itemListIconRoot }}>
                      <TickIcon/>
                    </ListItemIcon>
                  )}
                </div>
                <div>
                  {
                    languageLabels[lang].map((label) => {
                      return (
                        <span key={label} className={classes.languageLabel}>
                          {label}
                          &lrm;
                        </span>
                      );
                    })
                  }
                </div>
              </div>
            </MenuItem>
          );
        })
      }
    </MenuList>
  );

  return (
    <div data-testid="more-menu">
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.menuPopper}
        disablePortal={disablePortal}
        placement={placement}
        transition
        dir={isRtl(currentLanguage) ? 'rtl' : 'ltr'}
      >
        {({ TransitionProps }) => {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Fade {...TransitionProps}>
              <Paper className={classes.menu} ref={wrapperRef} component="nav">
                {moreMenuType === 'main' && moreMenuMain}
                {moreMenuType === 'languageSelector' && moreMenuLanguageSelector}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </div>
  );
};

export default MoreMenu;
