import OnDemand2 from '@@src/@types/OnDemand2';
import { moreLikeThis } from '@@src/apis/RecommendationApi';
import Logger from '@@utils/logger/Logger';

import OnDemand from '../@types/OnDemand';
import {
  GridRow, HeroRow, NewsletterPromoBlockRow, PageRowType, PromoBlockRow, RecommendationShelfRow, ShelfRow, ShortcutRow,
} from '../components/PageRows/PageRow';
import {
  transformToContinueWatchingTileItem,
  transformToLandscapeTileItem,
  transformToPortraitTileItem,
} from '../transformers/FeedTransformer';
import CatalogService from './CatalogService';

export const PAGESIZE_CAROUSEL_SHELF = 10;
export const PAGESIZE_GRID_LANDSCAPE = 30;
export const PAGESIZE_GRID_PORTRAIT = 56;

interface PageRowItems {
  feedUrl?: string;
  items: (OnDemand.Tile | OnDemand.Hero)[];
  totalItems: number;
  rangeTo?: number;
}

type SupportedPageRowTypes = HeroRow | ShelfRow | RecommendationShelfRow | GridRow | PromoBlockRow | ShortcutRow | NewsletterPromoBlockRow;

/**
 * Get page row items from the given url
 * @param row
 * @param subs
 * @param rangeFrom
 * @param rangeTo
 */
export async function getPageRowItems(row: SupportedPageRowTypes, subs = {}, rangeFrom = 1, rangeTo = 10): Promise<PageRowItems> {
  let { feedUrl } = row;
  const { itemType, requireLogin } = row;

  if (feedUrl) {
    Object.entries(subs).forEach((sub: [string, string]) => {
      feedUrl = feedUrl.replace(sub[0], sub[1]);
    });

    let transformFunction;

    if (itemType === 'movie') {
      transformFunction = transformToPortraitTileItem;
    } else if (itemType === 'continue-watching') {
      transformFunction = transformToContinueWatchingTileItem;
    } else if (itemType === 'mixed-big' || itemType === 'title-under') {
      transformFunction = transformToLandscapeTileItem;
    } else {
      Logger.warn(`Unsupported row itemType ${itemType}`);
      return {
        feedUrl,
        items: [],
        totalItems: 0,
      };
    }

    return {
      feedUrl,
      rangeTo,
      ...await CatalogService.getItems(feedUrl, transformFunction, requireLogin, rangeFrom, rangeTo),
    };
  }

  return {
    feedUrl,
    items: [],
    totalItems: 0,
  };
}

export default {
  getPageRowItems,
};
