import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import OnDemand from '../@types/OnDemand';
import { ReactComponent as PlayIcon } from '../images/icons/play-circle.svg';
import { OdLink } from '../routes';

interface PlayOverlayProps {
  video: Partial<OnDemand.Video>;
  classes?: {
    playButton?: string;
    root?: string;
  };
  tabIndex: number;
  label?: string;
  onClick?: (e?: Event) => void;
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      cursor: 'pointer',
    },
    playButtonContainer: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playButton: {
      width: '30%',
      height: '30%',
    },
  });
});

const PlayOverlay: FunctionComponent<PlayOverlayProps> = (props) => {
  const {
    video, children, tabIndex = 0, onClick,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const { label = t('playOverlay.label', { title: video.title }) } = props;

  return (
    <OdLink
      name="watch"
      params={{ id: video.id }}
      className={classes.root}
      title={label}
      aria-label={label}
      tabIndex={tabIndex}
      onClick={handleClick}
    >
      <div className={classes.playButtonContainer}>
        <PlayIcon className={classes.playButton}/>
      </div>
      {children}
    </OdLink>
  );
};

export default PlayOverlay;
