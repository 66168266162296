import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  ButtonClassKey, StyleRules,
} from '@material-ui/core';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import { merge, pick } from 'lodash';
import { forwardRef, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const styles = (theme: Theme) => {
  return createStyles({
    alternateColour: {
      // primary button
      '&.MuiButton-containedSecondary': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        '& .MuiButton-startIcon svg': {
          color: theme.palette.primary.main,
          '& [class*="outline"]': {
            stroke: theme.palette.primary.main,
          },
        },
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.contrastText, 0.8),
          },
        },
      },
      // secondary button
      '&.MuiButton-outlinedPrimary': {
        border: `2px solid ${theme.palette.primary.contrastText}`,
        color: theme.palette.primary.contrastText,
        background: 'transparent',
        '& .MuiButton-startIcon svg': {
          color: theme.palette.primary.contrastText,
          '& [class*="outline"]': {
            stroke: theme.palette.primary.contrastText,
          },
        },
        '@media (hover: hover)': {
          '&:hover': {
            border: `2px solid ${theme.palette.primary.contrastText}`,
            backgroundColor: alpha(theme.palette.primary.main, 0.8),
          },
        },
      },
      '&.MuiButton-text': {
        '&:hover': {
          '& .MuiButton-startIcon': {
            borderColor: theme.palette.primary.main,

            '& svg': {
              color: theme.palette.secondary.main,
              '& [class*="outline"]': {
                stroke: theme.palette.secondary.main,
              },
            },
          },
        },
        '& .MuiButton-startIcon': {
          borderColor: theme.palette.secondary.main,
          '& svg': {
            color: theme.palette.secondary.main,
            '& [class*="outline"]': {
              stroke: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  });
};

const useStyles = makeStyles<Theme, ButtonProps>(styles);

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export type ButtonProps = {
  /** Use alternate colour. */
  alternateColor?: boolean;
  /**
   *  The type of the button.
   *  For the `text` button type, the icon will be styled with transparent color and it may only work on certain icons that has outline class defined in the svg.
   *  Ideally, this should be refactored so that it will work for any icons.
   */
  buttonType?: 'primary' | 'secondary' | 'text' | 'textWithSmallIcon'
  /**
   * The location to link to when the button is clicked.
   * If defined, a `<Link>` element will be used as the root node.
   * Use this prop to link to an internal route.
   */
  to?: LinkProps['to'];
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof styles>>>
  /**
   * If `true`, space is added between the label and the icon so that they are aligned to the edge of the button.
   */
  justifiedIcon?: boolean;
  /**
   * minWidth of the component, pass 0 value to have no minWidth
   */
  minWidth?: number;
  /**
   * maxWidth of the component, pass 0 value to have no maxWidth
   */
  maxWidth?: number;
  customStyles?: Partial<StyleRules<ButtonClassKey, Record<string, unknown>>>;
} & MuiButtonProps & MuiButtonProps<'a'>;
/* eslint-enable react/require-default-props */

/**
 * Basic Button component
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    buttonType = 'primary',
    alternateColor = false,
    justifiedIcon,
    minWidth = undefined,
    maxWidth = undefined,
    customStyles,
    ...other
  } = props;

  const classes = useStyles({
    // only pass the classes that we implement, the rest are passed to the root element MuiButton
    classes: pick(props.classes, ['alternateColour']),
  });

  // default button type is primary
  let variant: MuiButtonProps['variant'] = 'contained';
  let color: MuiButtonProps['color'] = 'secondary';

  if (buttonType === 'secondary') {
    variant = 'outlined';
    color = 'primary';
  } else if (buttonType === 'text' || buttonType === 'textWithSmallIcon') {
    variant = 'text';
    color = 'primary';
  }

  const buttonProps: MuiButtonProps & { component?: React.ElementType } = {
    // For primary button, use contained secondary
    // For secondary button, use outlined primary
    variant,
    color,
    ...other,
  };

  // if we have 'to' props, set component to Link and set role to null so MuiButton does not set role=button
  if (other.to) {
    buttonProps.component = Link;
    buttonProps.role = null;
  }

  if (alternateColor) {
    buttonProps.className = classes.alternateColour;
  }

  const spaceBetweenLabelAndIcon = !!(justifiedIcon && (other.startIcon || other.endIcon));

  /**
   * Customise the Mui Button style using their button classes
   */
  const CustomisedMuiButton = useMemo(() => {
    return withStyles((theme: Theme) => {
      // This styles object is typed to give us auto complete & type checked!
      let muiButtonCustomStyles: Partial<StyleRules<ButtonClassKey, Record<string, unknown>>> = {
        root: {
          ...(maxWidth === 0 ? null : { maxWidth }),
          ...(minWidth === 0 ? null : { minWidth: minWidth ?? 180 }),
          ...(
            spaceBetweenLabelAndIcon && {
              justifyContent: 'space-between',
            }
          ),
        },
        sizeSmall: {
          ...(minWidth === 0 ? null : { minWidth: minWidth ?? 110 }),
        },
        sizeLarge: {
          ...(minWidth === 0 ? null : { minWidth: minWidth ?? 200 }),
        },
        startIcon: {
          '& svg': {
            color: theme.palette.primary.contrastText,
            '& [class*="outline"]': {
              stroke: theme.palette.primary.contrastText,
            },
          },
          marginRight: 10,
        },
        endIcon: {
          '& svg': {
            color: theme.palette.primary.contrastText,
            '& [class*="outline"]': {
              stroke: theme.palette.primary.contrastText,
            },
          },
        },
        outlinedPrimary: {
          '& $startIcon svg, & $endIcon svg': {
            color: theme.palette.primary.main,
            '& [class*="outline"]': {
              stroke: theme.palette.primary.main,
            },
          },
          '&:hover $startIcon svg, &:hover $endIcon svg': {
            '@media (hover: hover)': {
              color: theme.palette.primary.contrastText,
              '& [class*="outline"]': {
                stroke: theme.palette.primary.contrastText,
              },
            },
          },
        },
      };

      if (buttonType === 'text') {
        muiButtonCustomStyles = {
          text: {
            '&:hover': {
              // fill on hover
              '& $startIcon svg': {
                color: theme.palette.primary.main,
                '& [class*="outline"]': {
                  stroke: theme.palette.primary.main,
                },
              },
            },
          },
          startIcon: {
            borderRadius: '50rem',
            border: `2px solid ${theme.palette.primary.main}`,
            '& > *:first-child': {
              padding: 8,
            },
            '& svg': {
              // outlined style for the icon
              color: 'transparent',
              '& [class*="outline"]': {
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            },
          },
          endIcon: {
            borderRadius: '50rem',
            border: `2px solid ${theme.palette.primary.main}`,
            '& > *:first-child': {
              padding: 8,
            },
            '& svg': {
              // outlined style for the icon
              color: 'transparent',
              '& [class*="outline"]': {
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            },
          },
          iconSizeSmall: {
            '& > *:first-child': {
              fontSize: '2rem',
            },
          },
          iconSizeMedium: {
            '& > *:first-child': {
              fontSize: '2.4rem',
            },
          },
          iconSizeLarge: {
            '& > *:first-child': {
              fontSize: '2.8rem',
            },
          },
        };
      } else if (buttonType === 'textWithSmallIcon') {
        muiButtonCustomStyles = {
          root: {
            fontSize: '1rem',
            '&.MuiButton-text': {
              opacity: 0.8,
              '&:hover, &:focus-visible': {
                opacity: 1,
              },
            },
          },
          sizeSmall: {
            fontSize: '0.875rem',
          },
          sizeLarge: {
            fontSize: '1.125rem',
          },
          text: {
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
              // fill on hover
              '& $startIcon svg': {
                color: theme.palette.primary.main,
                '& [class*="outline"]': {
                  stroke: theme.palette.primary.main,
                },
              },
            },
          },
          startIcon: {
            '& svg': {
              '& [class*="outline"]': {
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            },
            marginRight: 4,
          },
          endIcon: {
            '& svg': {
              '& [class*="outline"]': {
                stroke: theme.palette.primary.main,
                strokeWidth: 2,
              },
            },
            marginLeft: 4,
          },
          iconSizeSmall: {
            '& > *:first-child': {
              fontSize: '1em',
            },
          },
          iconSizeMedium: {
            '& > *:first-child': {
              fontSize: '1em',
            },
          },
          iconSizeLarge: {
            '& > *:first-child': {
              fontSize: '1em',
            },
          },
        };
      }

      muiButtonCustomStyles = merge(muiButtonCustomStyles, customStyles);

      return muiButtonCustomStyles as StyleRules<keyof typeof muiButtonCustomStyles, Record<string, unknown>>;
    })(MuiButton);
  }, [buttonType, customStyles, maxWidth, minWidth, spaceBetweenLabelAndIcon]);

  return <CustomisedMuiButton ref={ref} {...buttonProps}/>;
});

export default Button;
