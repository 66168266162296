import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronRight from '@@icons/ChevronRight';
import { OdLinkProps, OdLink } from '@@src/routes';
import orange from '@@src/styles/colors/orange';
import fontFamily from '@@src/styles/typography/fontFamily';

export interface ShelfTitleProps {
  name: string;
  route?: OdLinkProps;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'baseline',
      width: 'fit-content',
      lineHeight: 1,
      '&:focus-visible': {
        outline: 'none',
      },

      // dont display view all on sm non hover
      [theme.breakpoints.down('sm')]: {
        '@media (hover: none)': {
          '& $viewAll': {
            display: 'none',
          },
          '& $link[data-content="View all"]': {
            transform: 'translate3d(0, 0, 0)',
          },
        },
      },

      // don't display view all on xs
      [theme.breakpoints.down('xs')]: {
        '& $viewAll': {
          display: 'none',
        },
        '& $link[data-content="View all"]': {
          transform: 'translate3d(0, 0, 0)',
        },
      },
    },
    chevron: {
      position: 'relative',
      display: 'inline',
      fontSize: 12,
      alignSelf: 'center',
      // to align it with the view all text
      top: '-1px',
      '& path': {
        fill: 'rgba(255, 255, 255, 0.6)',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 8,
      },
    },
    shelfName: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 'bold',
      position: 'relative',
      marginRight: 9,
      transition: 'color 0.2s ease-in-out',
      '& $chevron': {
        top: 0,
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        '& h2': {
          fontSize: '1.285em',
        },
      },
      '$root:hover &, $root.focus-visible &': {
        color: orange.darkTangerine,
      },
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      position: 'relative',
      alignItems: 'flex-end',
      transform: 'translate3d(0, 0, 0)',
      // only enable sliding animation for "View all", if translated it will need a different value
      '&[data-content="View all"]': {
        transform: 'translate3d(-66px, 0, 0)',
        '& $viewAll': {
          opacity: 0,
        },
      },

      '&:not([data-content="View all"])': {
        '& $viewAll': {
          display: 'none',
        },
      },

      transition: 'transform 0.5s ease-in-out',
      '$root:hover &, $root.focus-visible &': {
        transform: 'translate3d(0, 0, 0)',
      },
    },
    viewAll: {
      fontFamily: fontFamily.ubuntu,
      fontSize: '0.875rem',
      fontWeight: 500,
      opacity: 0.6,
      marginLeft: 5,
      marginRight: 5,
      lineHeight: 1,
      transition: 'opacity 0.5s ease-in-out',
      '$root:hover &, $root.focus-visible &': {
        opacity: 0.6,
      },
    },
  });
});

const ShelfTitle: FunctionComponent<ShelfTitleProps> = (props) => {
  const {
    name, route,
    onClick = null,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation('common');

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const titleComponent = (
    <div className={classes.shelfName}>
      <Typography variant="h2" component="h2">
        {name}
      </Typography>
    </div>
  );

  return (
    <>
      {route ? (
        <OdLink
          name={route.name}
          params={route.params}
          className={classes.root}
          onClick={handleClick}
          aria-label={`${name} - ${t('navigation.viewAll')}`}
          tabIndex={0}
        >
          {titleComponent}
          <div className={classes.link} data-content={t('navigation.viewAll')}>
            <div className={classes.viewAll}>
              {t('navigation.viewAll')}
            </div>
            <ChevronRight className={classes.chevron}/>
          </div>
        </OdLink>
      ) : titleComponent}
    </>
  );
};

export default ShelfTitle;
