import { Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import padding from '../../styles/padding';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingLeft: padding.container.xs,
      paddingRight: padding.container.xs,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: padding.container.sm,
        paddingRight: padding.container.sm,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: padding.container.md,
        paddingRight: padding.container.md,
      },
    },
  });
});

const OdContainer = (props) => {
  const classes = useStyles(props);
  const { children, ...rest } = props;
  return (
    <Container maxWidth={false} classes={{ root: classes.root }} {...rest}>
      {children}
    </Container>
  );
};

export default OdContainer;
