import { createStyles, makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';
import { FunctionComponent, createRef, useEffect, useState } from 'react';

const useStyles = makeStyles(() => {
  return createStyles({
    screenReaderOnly: {
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: 1,
      height: 1,
      overflow: 'hidden',
    },
  });
});

const SignInSuccessMessage: FunctionComponent = () => {
  const [successMessage, setSuccessMessage] = useState<string>('');
  const successMessageRef = createRef<HTMLDivElement>();

  const classes = useStyles({});

  useEffect(() => {
    const onLogin = () => {
      setSuccessMessage(t('login.successfulSignIn'));
    };
    const onLogout = () => {
      setSuccessMessage(t('login.successfulSignOut'));
    };

    document.addEventListener('OdWebsite_SignInSuccess', onLogin);
    document.addEventListener('OdWebsite_SignOutSuccess', onLogout);

    return () => {
      document.removeEventListener('OdWebsite_SignInSuccess', onLogin);
      document.removeEventListener('OdWebsite_SignOutSuccess', onLogout);
    };
  }, []);

  useEffect(() => {
    if (successMessageRef.current && successMessage !== '') {
      // timeout so that NVDA can pick up the change in success message
      setTimeout(() => {
        successMessageRef.current.focus();
      }, 2000);
    }
  }, [successMessageRef, successMessage]);

  return (
    <p tabIndex={-1} ref={successMessageRef} className={classes.screenReaderOnly}>
      {successMessage}
    </p>
  );
};

export default SignInSuccessMessage;
