import { FunctionComponent } from 'react';

import ShelfShell from '@@src/components/Shelf/ShelfShell';

import { PAGESIZE_GRID_LANDSCAPE, PAGESIZE_GRID_PORTRAIT } from '../../services/PageService';
import Grid from '../Grid/Grid';
import { GridRow } from '../PageRows/PageRow';
import OdContainer from '../Utils/OdContainer';

interface GridShelfProps {
  row: GridRow;
  rowIndex: number;
}

const GridShelf: FunctionComponent<GridShelfProps> = (props) => {
  const { row, rowIndex } = props;

  const pageSize = row.itemType === 'movie' ? PAGESIZE_GRID_PORTRAIT : PAGESIZE_GRID_LANDSCAPE;

  return (
    <ShelfShell
      row={row}
      rowIndex={rowIndex}
      pageSize={pageSize}
      renderShelfItems={
        (renderProps) => {
          return (
            <OdContainer>
              <Grid
                tiles={renderProps.tiles}
                tileSpec={renderProps.tileSpec}
                hasMoreItems={renderProps.hasMoreItems}
                loadMoreItems={renderProps.loadMoreItems}
                isLoadingMoreItems={renderProps.isLoadingMoreItems}
              />
            </OdContainer>
          );
        }
      }
    />
  );
};

export default GridShelf;
