import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import CastPlayer from '@@src/lib/VideoPlayerV2/CastPlayer';

const isServer = typeof window === 'undefined';

/**
 * Custom hook for checking ChromeCast availability.
 *
 * This hook checks whether ChromeCast is available upon mounting.
 * Casting is not available on mobile web.
 *
 * The hook uses a polling mechanism with a timeout to check the availability of ChromeCast.
 * It attempts to check the availability multiple times with a maximum of 5 attempts.
 *
 * @returns Boolean indicating whether ChromeCast is available.
 */
const useChromeCast = (): boolean => {
  const [available, setAvailability] = useState<boolean | undefined>();

  /**
   * Check whether casting is available upon mounting
   * casting is not available on mobile web
   */
  useEffect(() => {
    let attemptCount = 0;

    const checkGCastApi = () => {
      if (attemptCount < 5) {
        if (CastPlayer.isChromeCastSdkAvailable) {
          setAvailability(true);
        } else {
          setTimeout(() => {
            attemptCount += 1;
            checkGCastApi();
          }, 1000);
        }
      }
    };

    if (isServer || isMobile) {
      setAvailability(false);
    } else {
      checkGCastApi();
    }
  }, [available]);

  return Boolean(available);
};

export default useChromeCast;
