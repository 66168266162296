import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';

import AvailableDateTimeLabel from '@@src/components/Utils/AvailableDateTimeLabel';
import LiveInLabel from '@@src/components/Utils/LiveInLabel';
import useDurationToLive from '@@src/hooks/useDurationToLive';
import useIsInViewPort from '@@src/hooks/useIsInViewPort';
import { OdLink } from '@@src/routes';
import fontFamily from '@@styles/typography/fontFamily';

import LiveBadge from '../DataDisplay/Badge/LiveBadge';
import LiveCardMedia from '../Utils/LiveCardMedia';
import { sharedStyle } from './LiveScheduleTileLive';

const useSharedStyles = makeStyles(sharedStyle);

const useStyles = makeStyles<Theme, LiveScheduleTileUpcomingProps>((theme: Theme) => {
  return createStyles({
    titleContainer: {
      flexWrap: 'nowrap',
      marginBottom: 4,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 8,
      },
    },
    title: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 700,
      fontSize: '1.125rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    cardContent: {
      width: '100%',
      padding: '16px 12px 0 12px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 8px 0 8px',
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    time: {
      fontFamily: fontFamily.ubuntu,
      fontWeight: 500,
      color: alpha(theme.palette.primary.main, 0.7),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8125rem',
      },
    },
    statusBadge: {
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '3px 20px 4px 10px',
      borderRadius: '0 0 18px 0',
    },
  });
});

export interface LiveScheduleTileUpcomingProps {
  /** The route of the video */
  route: object;
  /** The title of the live stream. */
  title: string;
  /** The url for the thumbnail */
  imageUrl: string;
  /** Start date/time in ISO format. */
  start: string;
  onClick?: () => void;
}

const LiveScheduleTileUpcoming: FunctionComponent<LiveScheduleTileUpcomingProps> = (props) => {
  const classes = useStyles(props);
  const sharedClasses = useSharedStyles(props);
  const {
    route,
    title,
    imageUrl,
    start,
    onClick = () => {
      // do nothing
    },
  } = props;

  const durationToLive = useDurationToLive(start);
  const [badgeColor, setBadgeColor] = useState<'warning' | 'error' | 'success'>('warning');
  const [liveInBadgeStatus, setLiveInBadgeStatus] = useState<string | ReactElement>('');
  const [upcomingOrLiveDateTimeStatus, setUpcomingOrLiveDateTimeStatus] = useState<string | ReactElement>('');
  const tileRef = useRef();
  const inViewPort = useIsInViewPort(tileRef);

  // since LiveInBadge, UpcomingOrLiveDateTime are dynamic, they need to be rendered client side
  useEffect(() => {
    setBadgeColor(durationToLive <= 0 ? 'success' : 'warning');
    setLiveInBadgeStatus(
      <LiveInLabel durationToAvailable={durationToLive} shortUnits={durationToLive <= 86340}/>,
    );
    setUpcomingOrLiveDateTimeStatus(
      <AvailableDateTimeLabel start={start}/>,
    );
  }, [durationToLive, start]);

  return (
    <OdLink
      {...route}
      className={sharedClasses.link}
      onClick={onClick}
      data-testid="tile"
      tabIndex={inViewPort ? 0 : -1}
    >
      <Card className={sharedClasses.root} square elevation={0} ref={tileRef}>
        <div className={classes.thumbnail}>
          <LiveCardMedia
            title={title}
            imageUrl={imageUrl}
            classes={{
              media: sharedClasses.media,
              root: sharedClasses.mediaRoot,
            }}
          />
        </div>
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid container className={classes.titleContainer}>
            <Grid item xs={12} md={12}>
              <Box>
                <Typography variant="h5" component="h3" className={clsx(sharedClasses.title, classes.title)}>
                  {title}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Box fontWeight={500}>
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.time}
                >
                  {upcomingOrLiveDateTimeStatus}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Box className={classes.statusBadge}>
          <LiveBadge color={badgeColor} overlap="rectangular">
            <Typography variant="body2" className={sharedClasses.status}>
              {liveInBadgeStatus}
            </Typography>
          </LiveBadge>
        </Box>
      </Card>
    </OdLink>
  );
};

export default LiveScheduleTileUpcoming;
