import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import type { RootState } from '../store';

export interface UserData {
  sessionId: string;
  id: string; // Janrain ID
  name: string;
  emailHash: string;
}

export interface UserStoreState {
  userData: UserData;
}

/**
 * If any of the userData is undefined, it means that the user store is not ready
 * If the userData.id or userData.sessionId is null, it means that the user is not logged in
 */
const initialState: UserStoreState = {
  userData: {
    sessionId: undefined,
    name: undefined,
    id: undefined,
    emailHash: undefined,
  },
};

const userStore = createSlice({
  name: 'UserStore',
  initialState,
  reducers: {
    clear(state) {
      state.userData.sessionId = null;
      state.userData.name = null;
      state.userData.id = null;
      state.userData.emailHash = null;
    },
    setUserData(state, action: PayloadAction<UserData>) {
      state.userData = action.payload;
    },
  },
});

// selectors
export const getIsLoggedIn = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'user');
  },
  (loginData) => {
    return get(loginData, 'userData.sessionId', null) !== null;
  },
);

export const getUserId = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'user');
  },
  (loginData) => {
    return get(loginData, 'userData.id');
  },
);

export const getUserEmailHash = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'user');
  },
  (loginData) => {
    return get(loginData, 'userData.emailHash');
  },
);

export const getSessionId = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'user');
  },
  (loginData) => {
    return get(loginData, 'userData.sessionId');
  },
);

export const getUserName = createSelector(
  (rootState: RootState) => {
    return get(rootState, 'user');
  },
  (state) => {
    return get(state, 'userData.name');
  },
);

export const getIsReady = createSelector(
  (rootState: RootState) => {
    return rootState.user;
  },
  (state) => {
    // user store is ready when the userData.id is defined
    return get(state, 'userData.id') !== undefined;
  },
);

export default userStore;
