import React, { FunctionComponent, ReactElement } from 'react';

export interface MetadataProps {
  list: Array<string | ReactElement>
}

/**
 * Displays metadata on the tiles
 * e.g. 'Drama', 'English', '2 seasons'.
 * Accepts strings or Elements like UpcomingOrLiveDateTimeLabel
 * @param props
 * @constructor
 */
const Metadata: FunctionComponent<MetadataProps> = (props) => {
  const {
    list,
  } = props;

  const listItems = list.map((item, index) => {
    const key = `metadata_${index}`;
    if (item) {
      return <li key={key}>{item}</li>;
    }

    return null;
  });

  return (list.length > 0 ? (
    <ul>
      {listItems}
    </ul>
  ) : null
  );
};

export default Metadata;
