import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { FunctionComponent, ReactElement } from 'react';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      borderRadius: 4,
    },
  });
});

interface PlaceholderTileProps {
  imageHeight: number;
  is?: string;
  animation?: false | 'pulse' | 'wave';
  classes?: object;
}

export const PlaceholderTile: FunctionComponent<PlaceholderTileProps> = ({ imageHeight, animation = 'pulse' }, props) => {
  const className = useStyles(props);

  return (
    <Box>
      <Skeleton variant="rect" width="100%" height={imageHeight} animation={animation} classes={{ root: className.root }}/>
      <Skeleton width="60%" animation={animation}/>
      <Skeleton width="40%" animation={animation}/>
    </Box>
  );
};

PlaceholderTile.defaultProps = {
  is: 'placeholder',
};

export function createPlaceholderTiles(
  imageHeight,
  length = 1,
  keyPrefix = 'placeholder',
  animation: false | 'pulse' | 'wave' = 'pulse',
): ReactElement[] {
  return Array.from({ length }, (_x, i) => {
    const key = `${keyPrefix}-${i}`;
    return <PlaceholderTile key={key} imageHeight={imageHeight} animation={animation}/>;
  });
}

export default PlaceholderTile;
