import { Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import DataLayer from '@@utils/DataLayer';

import blue from '../../styles/colors/blue';
import grey from '../../styles/colors/grey';
import padding from '../../styles/padding';
import fontFamily from '../../styles/typography/fontFamily';
import ScrollButton from './ScrollButton';

interface OdShortcutLink {
  label: string;
  uri: string;
}

interface OdShortcutBarProps {
  title: string;
  labels?: string[];
  links?: OdShortcutLink[];
  defaultValue: number | false;
  onChange?: (e: React.ChangeEvent, newValue: number) => void;
  highlight?: boolean;
  rowIndex?: number;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      overflow: 'display',
      marginTop: 40,
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
        marginBottom: 24,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 16,
        marginBottom: 16,
      },
      '&.noHighlight .MuiTabs-indicator': {
        display: 'none',
      },
      '&:hover .OdShortcutBar-scrollButtons': {
        [theme.breakpoints.down('sm')]: {
          '@media (hover: hover)': {
            opacity: 1,
          },
        },
      },
      position: 'relative',
    },
    tab: {
      opacity: 1,
      fontFamily: fontFamily.ubuntu,
      '& h2': {
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.125rem',
        },
      },
      fontWeight: 'bold',
      color: common.white,
      backgroundColor: grey.blackpearl,
      borderRadius: 5,
      padding: '15px 20px',
      marginRight: 8,
      lineHeight: 1.2,
      '&:hover': {
        backgroundColor: grey.codgrey,
      },
      '&.Mui-selected': {
        backgroundColor: grey.fiord,
        '&:hover': {
          backgroundColor: grey.fiord,
        },
      },
      '.noHighlight &.Mui-selected': {
        backgroundColor: grey.blackpearl,
      },
      'body:not(.using-mouse) &:focus': {
        outline: `3px solid ${blue.navy}`,
        outlineOffset: '-3px',
      },
      '&:first-child': {
        [theme.breakpoints.only('xs')]: {
          marginLeft: padding.container.xs,
        },
        [theme.breakpoints.only('sm')]: {
          marginLeft: padding.container.sm,
        },
      },
    },
  };
});

const OdShortcutBar: FunctionComponent<OdShortcutBarProps> = (props) => {
  const classes = useStyles(props);
  const {
    title, labels, links, defaultValue, onChange,
    highlight = true, rowIndex,
  } = props;

  const a11yProps = useCallback((index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent, newValue: number) => {
    if (onChange) {
      onChange(event, newValue);
    }
  }, [onChange]);

  const handleLinkClick = useCallback((tileIndex: number) => {
    DataLayer.setClickSource('Shortcut Bar', `carousel:${title}:${rowIndex + 1}:${tileIndex + 1}`, rowIndex + 1, tileIndex + 1);
  }, [rowIndex, title]);

  if (!labels && !links) {
    return null;
  }

  return (
    <Tabs
      className={clsx(classes.root, !highlight && 'noHighlight')}
      value={defaultValue}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
      ScrollButtonComponent={ScrollButton}
      aria-label={title}
      data-testid="shortcut-bar"
    >
      { labels?.map((label, index) => {
        return (
          <Tab
            key={label}
            label={<Typography variant="h2" component="h2">{label}</Typography>}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...a11yProps(index)}
            wrapped
            classes={{ root: classes.tab }}
            {...(defaultValue === false && { tabIndex: 0 })}
          />
        );
      })}

      { links?.map((link, index) => {
        return (
          <Tab
            key={link.label}
            label={<Typography variant="h2" component="h2">{link.label}</Typography>}
            component={Link}
            to={link.uri}
            onClick={() => {
              handleLinkClick(index);
            }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...a11yProps(index)}
            wrapped
            classes={{ root: classes.tab }}
            {...(defaultValue === false && { tabIndex: 0 })}
          />
        );
      })}
    </Tabs>
  );
};

export default OdShortcutBar;
