import { Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useState, useRef, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CcBadge } from '@@src/images/badges/cc.svg';
import { ReactComponent as HdBadge } from '@@src/images/badges/hd.svg';

import OnDemand from '../../@types/OnDemand';
import grey from '../../styles/colors/grey';
import fontFamily from '../../styles/typography/fontFamily';
import { transitionDelay, transitionDuration } from '../Carousel/TileSlider';
import ClassificationBadge from '../Utils/ClassificationBadge';

interface Props {
  metadata?: (string | Record<string, unknown>)[];
  extraMetadata?: (string | Record<string, unknown>)[];
  classes?: {
    root?: any;
    primaryMetadata?: string;
    extraMetadata?: string;
    classification?: string;
  };
  classification?: OnDemand.Classification;
  hasCc?: boolean;
  isActive?: boolean;
  isHd?: boolean;
  isCcAvailable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: grey.chateau,
      display: 'flex',
      fontSize: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8125rem',
      },
      lineHeight: 1.2,
      '& > span:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      minHeight: '1.2em',
    },
    active: {
      '& $extraMetadata, & $classification, & $hasCc': {
        opacity: 1,
      },
    },
    metadata: {
      textAlign: 'left',
      '& + $metadata:before': {
        content: '"•"',
        fontSize: '0.8em',
        padding: '0px 4px',
      },
      fontFamily: fontFamily.roboto,
    },
    noShrink: {
      flexShrink: 0,
    },
    extraMetadata: {
      flex: 0,
      opacity: 0,
      transition: `opacity ${transitionDuration}`,
      transitionDelay,
      fontFamily: fontFamily.primary,
    },
    classification: {
      opacity: 0,
      transition: `opacity ${transitionDuration}`,
      transitionDelay,
    },
    hasCc: {
      backgroundColor: 'black',
      border: `1px solid ${grey.chateau}`,
      borderRadius: 2,
      padding: '2px 3px',
      fontWeight: 'bold',
      fontSize: '0.7em',
      height: '1.6em',
      color: grey.chateau,
      marginLeft: 8,
      flex: 0,
      opacity: 0,
      transition: `opacity ${transitionDuration}`,
      transitionDelay,
    },
    badgeContainer: {
      display: 'inline-flex',
      alignItems: 'center',
    },
  });
});

const Metadata: FunctionComponent<Props> = (props) => {
  const {
    metadata,
    extraMetadata = [],
    classification = null,
    hasCc = false,
    isActive = false,
    isHd = false,
  } = props;

  const { t } = useTranslation('common');
  const classes = useStyles(props);

  function renderMetadata(mds, className = '') {
    if (!mds) {
      return '';
    }
    return mds.map((md, i) => {
      let mdText = md;
      if (typeof md === 'object') {
        mdText = t(`${md.key}`, md.params);
        // @todo: Re-enable this once we get Seasons metadata translation
        // if (isRtl(language)) {
        //   mdText = <span dir="rtl">{mdText}</span>;
        // }
      }
      // eslint-disable-next-line react/no-array-index-key
      return <span className={className} key={i}>{mdText}</span>;
    });
  }

  const [delayedActive, setDelayedActive] = useState(isActive);

  const didMountRef = useRef(false);

  // delay the showing of extra meta data, should only happen after showExtraMetadata prop change
  useEffect(() => {
    if (didMountRef.current) {
      const timeout = setTimeout(() => {
        setDelayedActive(isActive);
      }, 350);
      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {
      // do nothing
    };
  }, [isActive]);

  useEffect(() => {
    didMountRef.current = true;
  });

  const metadataHtml = renderMetadata(metadata, `${classes.metadata} ${!delayedActive ? classes.noShrink : ''} ellipsis`);
  const extraMetadataHtml = renderMetadata(
    extraMetadata,
    `${classes.metadata} ${classes.extraMetadata}`,
  );

  let renderedMetadata;
  if (metadataHtml.length > 0 || extraMetadata.length > 0) {
    renderedMetadata = (
      <span>
        {metadataHtml}
        {extraMetadata.length > 0 && extraMetadataHtml}
      </span>
    );
  } else {
    renderedMetadata = <span>&nbsp;</span>;
  }

  return (
    <div className={clsx(classes.root, isActive && classes.active)}>
      {renderedMetadata}
      {classification && (<ClassificationBadge classification={classification} classes={{ root: classes.classification }}/>)}
      {isHd && <span className={classes.badgeContainer}><HdBadge role="img" aria-label="HD available"/></span>}
      {hasCc && <span className={classes.badgeContainer}><CcBadge role="img" aria-label="CC available"/></span>}
    </div>
  );
};

export default Metadata;
