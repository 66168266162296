import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { get, take } from 'lodash';

import { getMpxMediaById } from '@@src/apis/CatalogueApi';
import OnDemand from '@@types/OnDemand';

import { AppThunk, RootState } from '../store';

export interface VideoStoreState {
  [key: string]: OnDemand.Video;
}

const initialState: VideoStoreState = {};

const videoStore = createSlice({
  name: 'VideoStore',
  initialState,
  reducers: {
    addVideo(state, action: PayloadAction<OnDemand.Video>) {
      // remove some objects when we have more than 20 in the store to keep the store memory down
      if (Object.keys(state).length >= 20) {
        take(Object.keys(state), 11).forEach((key) => {
          delete state[key];
        });
      }

      state[action.payload.id] = action.payload;
    },
  },
});

// Load data from MPX Media API.
// Saves the request promise locally to prevent multiple requests in parallel.
export function getVideoAsyncThunk(videoId: string, language: string): AppThunk<Promise<OnDemand.Video | null>> {
  return (dispatch, getState) => {
    const state = getState();

    if (videoId in state.video) {
      return Promise.resolve(state.video[videoId]);
    }

    return getMpxMediaById(videoId, language)
      .then((data) => {
        dispatch(videoStore.actions.addVideo(data));
        return data;
      })
      .catch(() => {
        return null;
      });
  };
}

export const getVideoMetadata = createSelector(
  (state: RootState) => {
    return state.video;
  },
  (_state, videoId) => {
    return videoId;
  },
  (videoState, videoId) => {
    return get(videoState, videoId, null);
  },
);

export const getVideoStoreState = createSelector(
  (rootState: RootState) => {
    return rootState.video;
  },
  (video) => {
    return video;
  },
);

export const {
  addVideo,
} = videoStore.actions;

export default videoStore;
