const breakpoints = {
  xs: 0,
  sm: 720,
  md: 1280,
  lg: 1600,
  xl: 1920,
};

export default breakpoints;

interface BreakpointSizeRange {
  min: number;
  max: number;
}

export const getBreakpointSizeRanges = (breakpoint): BreakpointSizeRange => {
  const breakpointNames = Object.keys(breakpoints);
  for (let bi = 0; bi < breakpointNames.length; bi += 1) {
    const breakpointName = breakpointNames[bi];
    const nextBreakpointName = breakpointNames[bi + 1];

    if (breakpointName === breakpoint) {
      return {
        min: breakpoints[breakpointName],
        max: breakpoints[nextBreakpointName] - 1,
      };
    }
  }

  return null;
};

export const getCurrentBreakpoint = (): 'xs' | 'sm' | 'md' | 'lg' | 'xl' => {
  if (typeof window !== 'undefined') {
    const breakpointsNames = Object.keys(breakpoints);
    for (let bi = breakpointsNames.length - 1; bi >= 0; bi -= 1) {
      const breakpointName = breakpointsNames[bi];
      const breakpoint = breakpoints[breakpointName];

      if (window.innerWidth >= breakpoint) {
        return breakpointName as 'xs' | 'sm' | 'md' | 'lg' | 'xl';
      }
    }
  }

  return 'xl';
};
