import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { getShortLocaleCode } from '../../i18n';

interface UnfurlingMetaTagsProps {
  title: string;
  description?: string;
  imageUrl: string;
  url: string;
  type?: string;
}

const UnfurlingMetaTags: FunctionComponent<UnfurlingMetaTagsProps> = (
  {
    title,
    description,
    imageUrl,
    url,
    type = 'website',
  },
) => {
  const { i18n: { language } } = useTranslation();

  let ogLocale = null;
  const localeCode = getShortLocaleCode(language);
  if (localeCode) {
    ogLocale = <meta property="og:locale" content={localeCode}/>;
  }

  return (
    <Helmet>
      <meta property="og:title" content={title}/>
      {description && (
        <meta property="og:description" content={description}/>
      )}
      <meta property="og:type" content={type}/>
      <meta property="og:image" content={imageUrl}/>
      <meta property="og:url" content={url}/>
      {ogLocale}
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:site" content="@SBSOnDemand"/>
      <meta name="twitter:title" content={title}/>
      {description && (
        <meta name="twitter:description" content={description}/>
      )}
      <meta name="twitter:image" content={imageUrl}/>
    </Helmet>
  );
};

export default UnfurlingMetaTags;
