import {
  Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  Typography,
} from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { get } from 'lodash';
import { FunctionComponent, useCallback, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import { setLanguage } from '@@stores/UserPreferencesStore';
import { getIsLoggedIn, getUserName } from '@@stores/UserStore';
import DataLayer from '@@utils/DataLayer';

import { languageLabels, getLocalizedPath } from '../../i18n';
import { ReactComponent as AccountIcon } from '../../images/icons/account.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as UpChevronIcon } from '../../images/icons/up-chevron.svg';
import { getRouteFromName } from '../../routes';
import { MenuItemKey, MenuItemProp, getMenuItems } from '../../services/MenuService';
import grey from '../../styles/colors/grey';
import fontFamily from '../../styles/typography/fontFamily';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '& .MuiListSubheader-gutters, & .MuiListItem-gutters': {
        paddingLeft: theme.spacing(3),
      },
      '& .MuiTypography-body1': {
        fontSize: '1rem',
        fontFamily: fontFamily.secondary,
        color: alpha(common.white, 0.7),
      },
      '& .MuiListSubheader-root': {
        fontFamily: fontFamily.primary,
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'uppercase',
        color: alpha(common.white, 0.5),
      },
    },
    paper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
      background: grey.darkBlackPearl,
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 5,
      zIndex: 9,
    },
    profile: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: 24,
      background: alpha(common.white, 0.10),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& .MuiTypography-body1': {
        fontSize: '1.125rem',
      },
    },
    accountIcon: {
      display: 'inline-flex',
      flexShrink: 0,
      minWidth: 52,
      fontSize: '2.5rem',
    },
    menuMessageBanner: {
      borderRadius: 0,
    },
    mainMenuList: {
      '& .MuiTypography-body1': {
        fontSize: '1.125rem',
      },
    },
    menuItemActive: {
      '& .MuiTypography-body1': {
        color: grey.white,
        fontWeight: 700,
      },
    },
    iconExpand: {
      transform: 'rotate(90deg)',
    },
    iconCollapse: {
      transform: 'rotate(-90deg)',
    },
    languageLabel: {
      fontFamily: fontFamily.secondary,
      marginLeft: 6,
      marginRight: 6,
    },
    chooseLanguageHeading: {
      '& .MuiTypography-body1': {
        fontSize: '1.125rem',
      },
    },
    activeLanguage: {
      '& .MuiTypography-body1': {
        color: grey.white,
      },
    },
    itemListIconRoot: {
      fontSize: '1.5em',
    },
    username: {
      '$profile &': {
        fontFamily: fontFamily.roboto,
      },
    },
  });
});

interface MobileMenuDrawerProps {
  open: boolean;
  onClose(): void;
}

const MobileMenuDrawer: FunctionComponent<MobileMenuDrawerProps> = (props) => {
  const classes = useStyles(props);
  const {
    open, onClose,
  } = props;

  const location = useLocation();
  const dispatch = useAppDispatch();

  const displayName = useAppSelector(getUserName);
  const loggedIn = useAppSelector(getIsLoggedIn);

  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;

  function handleLanguageChange(lang) {
    onClose();
    dispatch(setLanguage(lang));
    const localizedUrl = getLocalizedPath(lang, location);
    DataLayer.events.queueLanguageChange(lang, localizedUrl);
    window.location.assign(localizedUrl);
  }

  const [menuType, setMenuType] = useState<'main' | 'languageSelector'>('main');

  const handleClose = useCallback(() => {
    onClose();
    setMenuType('main');
  }, [onClose]);

  type MobileMenuItem = MenuItemProp & {
    id?: string;
    itemText?: string;
    startIcon?: React.ReactElement;
    endIcon?: React.ReactElement;
    listItemClassName?: string;
  };

  const renderMobileMenuList = useCallback((menuItemKeys: (MenuItemKey | MobileMenuItem)[], header = null, headerId = null, className = null) => {
    const subHeader = header ? <ListSubheader id={headerId} component="div" disableSticky>{header}</ListSubheader> : null;

    const menuItems = getMenuItems();

    return (
      <List aria-labelledby={headerId} className={className}>
        {subHeader}
        {
          menuItemKeys.map((menuItemKey) => {
            let item: MenuItemProp | MobileMenuItem;
            if (typeof menuItemKey === 'string') {
              item = menuItems[menuItemKey];
            } else {
              item = menuItemKey;
            }
            const { tOptions = {} } = item;
            const key = 'id' in item ? item.id : item.tKey;

            const listItemContent = (
              <>
                {'startIcon' in item && <ListItemIcon>{item.startIcon}</ListItemIcon>}
                <ListItemText primary={'itemText' in item ? item.itemText : t(item.tKey, tOptions)}/>
                {'endIcon' in item && item.endIcon}
              </>
            );

            const onClick = () => {
              // auto close the menu when item is clicked, except when the menu item key is an object, we assume that it will handle the closing if needed
              if (typeof menuItemKey === 'string') {
                handleClose();
              }
              if (item.action) {
                item.action();
              }
            };

            const componentProps = {
              onClick,
            };

            let listItemClassName = 'listItemClassName' in item ? item.listItemClassName : null;

            if (item.routeName) {
              const route = getRouteFromName(item.routeName);
              return (
                <Route key={key} path={route.path} exact={route.exact}>
                  {
                    ({ match }) => {
                      if (match) {
                        listItemClassName = clsx(listItemClassName, classes.menuItemActive);
                      }

                      return (
                        <ListItem
                          button
                          component={Link}
                          to={route.path}
                          {...componentProps}
                          className={listItemClassName}
                        >
                          {listItemContent}
                        </ListItem>
                      );
                    }
                  }
                </Route>
              );
            }

            if (item.url) {
              return (
                <ListItem key={key} button component="a" href={item.url} {...componentProps} {...item.urlProps} className={listItemClassName}>
                  {listItemContent}
                </ListItem>
              );
            }

            return (
              <ListItem key={key} button {...componentProps} className={listItemClassName}>
                {listItemContent}
              </ListItem>
            );
          })
        }
      </List>
    );
  }, [classes, handleClose, t]);

  const currentLanguageMenuItem: MobileMenuItem = {
    tKey: 'menuItem.currentLanguage',
    tOptions: {
      language: get(languageLabels, `${currentLanguage}.0`),
    },
    action() {
      setMenuType('languageSelector');
    },
    endIcon: <UpChevronIcon className={classes.iconExpand}/>,
  };

  const languageMenuList: MobileMenuItem[] = [
    ...Object.keys(languageLabels).map((lang) => {
      return {
        id: lang,
        startIcon: lang === currentLanguage ? <ListItemIcon classes={{ root: classes.itemListIconRoot }}><TickIcon/></ListItemIcon> : null,
        itemText: languageLabels[lang].map((label) => {
          return <span key={label} className={classes.languageLabel}>{label}</span>;
        }),
        action() {
          handleLanguageChange(lang);
        },
        listItemClassName: lang === currentLanguage ? classes.activeLanguage : null,
      };
    }),
  ];

  return (
    <Drawer
      className={classes.root}
      anchor="left"
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
      PaperProps={{
        component: 'nav',
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={handleClose}
        color="inherit"
        aria-label={t('menuItem.closeMenu')}
      >
        <CloseIcon/>
      </IconButton>
      {
        loggedIn && (
          <div>
            <div className={classes.profile}>
              <div className={classes.accountIcon}>
                <AccountIcon/>
              </div>
              <div className={classes.username}>
                <Typography component="span">
                  {displayName}
                </Typography>
              </div>
            </div>
          </div>
        )
      }
      <Divider/>
      {menuType === 'main' && (
        <>
          {
            renderMobileMenuList([
              'home',
              'tvShows',
              'movies',
              'liveTv',
              'news',
              'sport',
              ...(loggedIn ? ['favourites'] : []) as MenuItemKey[],
            ], null, null, classes.mainMenuList)
          }
          <Divider/>
          {
            renderMobileMenuList(
              [
                ...(loggedIn ? ['manageAccount'] : ['signIn', 'createAccount']) as MenuItemKey[],
                currentLanguageMenuItem,
              ],
              t('menuItem.settings'),
              'nav-settings-label',
            )
          }
          <Divider/>
          {renderMobileMenuList(['visitSbs'], t('menuItem.getToKnowUs'), 'nav-get-to-know-us-label')}
          <Divider/>
          {renderMobileMenuList(['help', 'contact'], t('menuItem.support'), 'nav-support-label')}
          <Divider/>
          { loggedIn && (
            <>
              <Divider/>
              {renderMobileMenuList(['signOut'])}
            </>
          )}
        </>
      )}
      {
        menuType === 'languageSelector' && (
          <>
            {
              renderMobileMenuList([{
                tKey: 'menuItem.chooseLanguage',
                action() {
                  setMenuType('main');
                },
                startIcon: <UpChevronIcon className={classes.iconCollapse}/>,
                listItemClassName: classes.chooseLanguageHeading,
              }])
            }
            <Divider/>
            {renderMobileMenuList(languageMenuList)}
          </>
        )
      }
    </Drawer>
  );
};

export default MobileMenuDrawer;
