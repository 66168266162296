import { ClassNameMap } from '@material-ui/styles';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import HalfSizeMessageBanner from './HalfSizeMessageBanner';

export interface SwiftStreetSurveyBannerProps {
  onClose?: () => void;
  classes?: ClassNameMap;
}

const SwiftStreetSurveyBanner: FunctionComponent<SwiftStreetSurveyBannerProps> = (props) => {
  const { classes, onClose } = props;
  const { t } = useTranslation('common');

  return (
    <HalfSizeMessageBanner
      classes={classes}
      ctaText={t('swiftStreetSurveyBanner.ctaText')}
      ctaLink="https://www.theexchange.sbs.com.au/c/a/5mu3pbE4jRdBbVH7gGSBA6"
      title={t('swiftStreetSurveyBanner.title')}
      description={t('swiftStreetSurveyBanner.description')}
      onClose={onClose}
    />
  );
};

export default SwiftStreetSurveyBanner;
