import loadable from '@loadable/component';

export const EpisodePage = loadable(
  () => { return import('./EpisodePage'); },
);

export const FavouritesPage = loadable(
  () => { return import('./FavouritesPage'); },
);

export const LiveTvPage = loadable(
  () => { return import('./LiveTvPage'); },
);

export const MoviePage = loadable(
  () => { return import('./MoviePage'); },
);

export const CollectionPage = loadable(
  () => { return import('./CollectionPage'); },
);

export const Page = loadable(
  () => { return import('./Page'); },
);

export const ProgramPage = loadable(
  () => { return import('./ProgramPage'); },
);

export const ProgramPageById = loadable(
  () => { return import('./ProgramPageById'); },
);

export const SearchPage = loadable(
  () => { return import('./SearchPage'); },
);

export const SeriesPage = loadable(
  () => { return import('./SeriesPage'); },
);

export const VideoPage = loadable(
  () => { return import('./VideoPage'); },
);

export const ErrorPage = loadable(
  () => { return import('./ErrorPage'); },
);

export const NotFoundPage = loadable(
  () => { return import('./ErrorPage'); },
  {
    resolveComponent: (component) => {
      return component.NotFoundPage;
    },
  },
);

export const WatchPage = loadable(
  // prefetch the watch page so that the user can watch as soon as they click play
  () => { return import(/* webpackPrefetch: true */ './WatchPage'); },
);

export const WatchPageV2 = loadable(
  // prefetch the watch page so that the user can watch as soon as they click play
  () => { return import(/* webpackPrefetch: true */ './WatchPageV2'); },
);
