import { get } from 'lodash';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import HalfSizeMessageBanner from './HalfSizeMessageBanner';

export interface TvWeekLogiesBannerProps {
  onClose?: () => void;
  classes?: any;
}

const TvWeekLogiesBanner: FunctionComponent<TvWeekLogiesBannerProps> = (props) => {
  const { onClose, classes } = props;
  const location = useLocation();

  let bannerTitle = 'TV WEEK Logie Awards Nominee';
  let bannerDescription;

  switch (true) {
    case (location.pathname === '/'):
      bannerTitle = 'SBS and NITV receive 6 Logie nominations';
      bannerDescription = 'Alone Australia, Erotic Stories, Eddie’s Lil’ Homies and 3 for Safe Home';
      break;

    case (location.pathname.startsWith('/tv-series/alone-australia')):
      bannerDescription = 'Alone Australia for Best Competition Reality Program';
      break;

    case (location.pathname.startsWith('/tv-series/safe-home')):
      bannerDescription = 'Safe Home for Best Miniseries or Telemovie';
      break;

    case (location.pathname.startsWith('/tv-series/erotic-stories')):
      bannerDescription = 'Erotic Stories for Best Miniseries or Telemovie';
      break;

    case (location.pathname.startsWith('/tv-series/eddies-lil-homies')):
      bannerDescription = 'Eddie’s Lil’ Homies for Best Children’s Program';
      break;

    default:
      if (onClose) {
        onClose();
      }
      return null;
  }

  return (
    <HalfSizeMessageBanner
      title={bannerTitle}
      ctaText="Vote now"
      ctaLink="https://vote.tvweeklogies.com.au/"
      description={bannerDescription}
      onClose={onClose}
      classes={classes}
    />
  );
};

export default TvWeekLogiesBanner;
