import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import OnDemand from '../../@types/OnDemand';
import { ReactComponent as ClassificationC } from '../../images/classifications/C.svg';
import { ReactComponent as ClassificationG } from '../../images/classifications/G.svg';
import { ReactComponent as ClassificationM } from '../../images/classifications/M.svg';
import { ReactComponent as ClassificationMA15 } from '../../images/classifications/MA15+.svg';
import { ReactComponent as ClassificationP } from '../../images/classifications/P.svg';
import { ReactComponent as ClassificationPG } from '../../images/classifications/PG.svg';

export const classificationImages = {
  PG: ClassificationPG,
  G: ClassificationG,
  M: ClassificationM,
  'MA15+': ClassificationMA15,
  P: ClassificationP,
  C: ClassificationC,
};

interface StyleProps {
  size: string;
  classes?: {
    root: string;
  };
}

const useStyles = makeStyles<Theme, StyleProps>(() => {
  return createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    classification: {
      height: ({ size }) => {
        return size;
      },
    },
  });
});

export interface ClassificationBadgeProps {
  classification: OnDemand.Classification;
  classes?: {
    root: string;
  };
  size?: string;
}

const ClassificationBadge: FunctionComponent<ClassificationBadgeProps> = (props) => {
  const { size = '1rem', classes: classesProps } = props;
  const classes = useStyles({ size, classes: classesProps });
  const { classification } = props;
  const { t } = useTranslation('common');

  const label = t('metadata.extendedClassification', {
    code: classification,
    description: t(`classification.${classification}`),
  });

  const ClassificationElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = get(classificationImages, classification, null);

  if (ClassificationElement) {
    return (
      <span title={t(`classification.${classification}`)} className={classes.root}>
        <ClassificationElement
          className={classes.classification}
          role="img"
          aria-label={label}
        />
      </span>
    );
  }

  return null;
};

export default ClassificationBadge;
