import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { FunctionComponent } from 'react';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: alpha(common.black, 0.6),
    },
    '@global': {
      body: {
        overflow: 'hidden',
      },
    },
  });
});

const DimmedOverlay: FunctionComponent = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root} data-testid="dimmed-overlay"/>
  );
};

export default DimmedOverlay;
