import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { forwardRef } from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      verticalAlign: 'inherit', // make this vertically aligned with other elements in a grid
      paddingLeft: '1rem',
      '& .MuiBadge-badge': {
        top: '0.65rem',
        left: 5,
      },
    },
    colorWarning: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.warning.main,
      },
    },
    colorSuccess: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.success.main,
      },
    },
    centered: {
      verticalAlign: 'inherit', // make this vertically aligned with other elements in a grid
      paddingLeft: '1rem',
      '& .MuiBadge-badge': {
        top: '0.65rem',
        left: 5,
      },
    },
  });
});

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export type LiveBadgeProps = {
  /** The colour of the component. It's been extended from the default MuiBadge colour to support more colours. */
  color?: 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'success';
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>
} & Omit<MuiBadgeProps, 'color'>;
/* eslint-enable react/require-default-props */

const LiveBadge = forwardRef<HTMLDivElement, LiveBadgeProps>((props, ref) => {
  const {
    variant = 'dot',
    color,
    ...other
  } = props;

  const badgeProps: MuiBadgeProps = {
    variant,
    ...other,
  };
  const classes = useStyles(props);

  let className = null;

  // passthrough the `color` property that is supported by MuiBadge
  if (
    color === 'primary'
    || color === 'secondary'
    || color === 'default'
    || color === 'error'
  ) {
    badgeProps.color = color;
  } else {
    className = clsx(className, classes[`color${capitalize(color)}`]);
  }

  badgeProps.anchorOrigin = { vertical: 'top', horizontal: 'left' };
  className = clsx(className, classes.centered);

  return <MuiBadge ref={ref} className={className} {...badgeProps}/>;
});

export default LiveBadge;
