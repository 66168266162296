import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

/**
 * Calculate the time before it goes live (positive duration)
 * or the time since it went live (negative duration)
 * based on a start date
 * @param start - start date in ISO format
 */
const useDurationToLive = (start: string) => {
  const startDateTime = DateTime.fromISO(start) as DateTime;
  const [durationToLive, setDurationToLive] = useState<number>(Math.floor(startDateTime.diffNow().as('seconds')));

  useEffect(() => {
    const delayLiveCalculation = durationToLive > 120 || durationToLive < 0 ? 60000 : 1000;

    setDurationToLive(Math.round(startDateTime.diffNow().as('seconds')));
    const durationToLiveTimeout = setTimeout(() => {
      setDurationToLive(Math.round(startDateTime.diffNow().as('seconds')));
    }, delayLiveCalculation);

    return () => {
      clearTimeout(durationToLiveTimeout);
    };
  }, [durationToLive, startDateTime]);

  return durationToLive;
};

export default useDurationToLive;
