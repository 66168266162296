import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import useGetOpacityFromVerticalScroll from '@@src/hooks/useGetOpacityFromVerticalScroll';

import Image from '../Html/Image';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      position: 'relative',
    },
    heroImage: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: 380,
      },
      '& img': {
        [theme.breakpoints.down('xs')]: {
          height: 380,
          objectFit: 'cover',
        },
      },
    },
    heroImageVignetteBottom: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(44.69798876665197deg, rgba(11, 13, 15, 1) 0%, rgba(11, 13, 15, 0) 100%), linear-gradient(180deg, rgba(11, 13, 15, 0) 0%, rgba(11, 13, 15, 1) 100%)',
      pointerEvents: 'none',
    },
    pageContent: {
      position: 'relative',
      marginTop: 240,
      '&> div + div': {
        marginTop: 30,
      },
    },
  });
});

interface DetailsPageLayoutProps {
  backgroundImage?: string;
  backgroundImageId?: string;
  children: any;
}

const DetailsPageLayout: FunctionComponent<DetailsPageLayoutProps> = (props) => {
  const { backgroundImage, backgroundImageId, children } = props;
  const classes = useStyles(props);
  const heroImageOpacity = useGetOpacityFromVerticalScroll(0.2);

  const imageProps = {
    imageSizes: { all: '100vw' },
    src: undefined,
    imageId: undefined,
    lazyload: false,
    key: undefined,
  };

  if (backgroundImage) {
    imageProps.src = backgroundImage;
    imageProps.key = backgroundImage;
    delete imageProps.imageId;
  } else if (backgroundImageId) {
    imageProps.imageId = backgroundImageId;
    imageProps.key = backgroundImageId;
    delete imageProps.src;
  }

  return (
    <div className={classes.root}>
      <div className={classes.heroImage} style={{ opacity: heroImageOpacity }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Image alt="" {...imageProps}/>
        <div className={classes.heroImageVignetteBottom}/>
      </div>
      <div className={classes.pageContent}>
        {children}
      </div>
    </div>
  );
};

export default DetailsPageLayout;
