// Based on https://github.com/MarcoMedrano/winston-transport-browserconsole/blob/master/src/lib/BrowserConsole.ts
// Modified to support timestamp, print log level and additional metadata
import { LogEntry } from 'winston';
import TransportStream from 'winston-transport';
import 'setimmediate';

enum Level {
  error = 0,
  warn = 1,
  info = 2,
  debug = 4,
}

export default class BrowserConsole extends TransportStream {
  private readonly printLevel: boolean; // print level in the log

  private methods = {
    debug: 'debug',
    error: 'error',
    info: 'info',
    warn: 'warn',
  };

  public constructor(opts?: TransportStream.TransportStreamOptions & { printLevel?: boolean }) {
    super(opts);

    // eslint-disable-next-line no-prototype-builtins
    if (opts && opts.level && Level.hasOwnProperty(opts.level)) {
      this.level = opts.level;
    }

    this.printLevel = opts.printLevel || true;
  }

  public log(logEntry: LogEntry, next: () => void) {
    setImmediate(() => {
      (this as any).emit('logged', logEntry);
    });

    const { message, level, timestamp } = logEntry;

    const mappedMethod = this.methods[level];

    const messages = [message];

    if (this.printLevel) {
      messages.unshift(`[${level.toUpperCase()}]`);
    }

    if (timestamp) {
      messages.unshift(timestamp);
    }

    // @ts-ignore: symbol is fine to be used as index in this case
    let args = logEntry[Object.getOwnPropertySymbols(logEntry)[1]];
    args = args && args.length >= 1 ? args[0] : args;

    // if there is an error attribute passed into the args, we'll log it separately
    let errorObject;
    if (args && 'error' in args && args.error instanceof Error) {
      errorObject = args.error;
      args.errorMessage = errorObject.message;
      delete args.error;
    }

    /* eslint-disable no-console */
    if (args) console[mappedMethod](messages.join(' '), args);
    else console[mappedMethod](messages.join(' '));

    if (errorObject) {
      console[mappedMethod](errorObject);
    }

    next();
  }
}
