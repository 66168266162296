import { useLocation } from 'react-router-dom';

export default function useQuery(caseInsensitive = false): any {
  const query = {};

  const params = new URLSearchParams(useLocation().search);
  params.forEach((value, key) => {
    if (caseInsensitive === true) {
      query[key.toLowerCase()] = value.toLowerCase();
    } else {
      query[key] = value;
    }
  });

  return query;
}
