import { format } from 'winston';

/**
 * Fix passing an Error object without metadata
 * There seems to be a bug where the logger is called with only one parameter that contains an error object
 *    eg: Logger.error(new Error('error message'))
 * It doesn't happen when meta object is passed on in the second parameter
 */
const fixErrorObjectMessage = format((info) => {
  // When the logger is called with only one parameter that contains an object, the info argument becomes an Error instance
  // Fix this by creating a new info object which has the error object as a message attribute
  // and spread the other attributes
  if (info instanceof Error) {
    return {
      message: info,
      ...info,
    };
  }

  return info;
});

export default fixErrorObjectMessage;
