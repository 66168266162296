import { SvgIcon, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { ReactComponent } from './sparkle.svg';

const Sparkle: OverridableComponent<SvgIconTypeMap> = (props) => {
  return <SvgIcon component={ReactComponent} {...props}/>;
};

export default Sparkle;
