import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles';
import { FunctionComponent } from 'react';

import { useAppSelector } from '@@src/hooks/store';
import { getProgress } from '@@stores/ProgressStore';

import ProgressBar, { ProgressBarProps } from './ProgressBar';

const useStyles = makeStyles<Theme, VideoProgressBarProps>(() => {
  return createStyles({
    root: ({ position }) => {
      if (position === 'bottom') {
        return {
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
        };
      }
      return {};
    },
    progressBar: {
      height: 6,
    },
    progressBarFill: {},
  });
});

interface VideoProgressBarProps extends Omit<ProgressBarProps, 'progressPercent'> {
  videoId: string;
  /**
   * The position of the progress bar. `bottom` will absolutely position the component at the bottom. Default value is `relative`
   */
  position?: 'relative' | 'bottom';
  classes?: Partial<ClassNameMap<ClassKeyOfStyles<typeof useStyles>>>
}

const VideoProgressBar: FunctionComponent<VideoProgressBarProps> = (props) => {
  const { videoId } = props;
  const classes = useStyles(props);

  const progressData = useAppSelector((state) => {
    return getProgress(state, videoId);
  });

  let progressPercent = 0;
  if (progressData) {
    progressPercent = progressData.completed ? 100 : progressData.percent;
  }

  if (progressPercent === 0) {
    return null;
  }

  return (
    <div className={classes.root} aria-hidden>
      <ProgressBar
        progressPercent={progressPercent}
        classes={{
          root: classes.progressBar,
          progressBarFill: classes.progressBarFill,
        }}
      />
    </div>
  );
};

export default VideoProgressBar;
