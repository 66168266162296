import { DateTime } from 'luxon';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useDurationToLive from '@@src/hooks/useDurationToLive';
import { getShortLocaleCode } from '@@src/i18n';

export interface UpcomingOrLiveDateTimeLabelProps {
  start: string,
  shortUnits?: boolean,
}

/**
 * Display the time until the stream becomes live
 * or the time since the stream went live
 * @param props
 */
const AvailableDateTimeLabel: FunctionComponent<UpcomingOrLiveDateTimeLabelProps> = (props) => {
  const {
    start,
  } = props;
  const { t, i18n: { language } } = useTranslation('common');

  let startDateTime = DateTime.fromISO(start);
  startDateTime = startDateTime.setLocale(getShortLocaleCode(language, '-'));

  const now = DateTime.local().setLocale(getShortLocaleCode(language, '-'));
  const durationToLive = useDurationToLive(start);
  let dateTimeLabel;
  let tKey = 'video.willBeAvailableOnDate';

  if (durationToLive > 0) {
    if (startDateTime.startOf('day').toMillis() === now.startOf('day').toMillis()) {
      tKey = 'video.willBeAvailableToday';
    } else if (startDateTime.startOf('day').toMillis() === (now.startOf('day').plus({ days: 1 })).toMillis()) {
      tKey = 'video.willBeAvailableTomorrow';
    }

    dateTimeLabel = t(tKey, {
      date: startDateTime,
      ...(durationToLive > 24 && {
        formatParams: {
          date: {
            showDayOfWeek: false,
          },
        },
      }),
    });
  }

  if (durationToLive <= 0) {
    dateTimeLabel = t('playback.startedTimeDescription', {
      duration: durationToLive,
      units: ['mo', 'w', 'd', 'h', 'm', 's'],
      largest: durationToLive > -86400 && durationToLive < -3600 ? 2 : 1,
      // use shorthand units if duration since the stream went live is less than 23h 59m
      // this is to ensure we display "1 day" if duration since the stream went live is more than 23h 59m
      shortUnits: durationToLive >= -86340,
      spacer: durationToLive >= -86340 ? '' : ' ',
      delimiter: ' ',
    });
  }

  return dateTimeLabel;
};

export default AvailableDateTimeLabel;
