import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { DateTime } from 'luxon';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCalculateProgress from '@@src/hooks/useCalculateProgress';
import useDurationToLive from '@@src/hooks/useDurationToLive';
import useLocalTimeZone from '@@src/hooks/useLocalTimeZone';
import OnDemand from '@@types/OnDemand';

import ClassificationBadge from '../Utils/ClassificationBadge';
import LiveInLabel from '../Utils/LiveInLabel';
import LiveTvTilePrimary from './LiveTvTilePrimary';
import LiveTvTileSecondary from './LiveTvTileSecondary';

const useStyles = makeStyles<Theme, LiveTvTileProps>((theme: Theme) => {
  return createStyles({
    classificationContainer: {
      display: 'flex',

      // The classification container will sit inside "metadata" element in the primary/secondary tiles
      // To adjust the classification badge size, change the fontSize of "metadata"
      fontSize: '1em',
    },
    classification: {
      // to align this element vertically centered
      position: 'relative',
    },
    metadataContainer: {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      minWidth: 0,
      '& .MuiGrid-item:not(:nth-child(2))': {
        flexShrink: 0,
      },
      '& .MuiGrid-item:nth-child(2)': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    metadata: {
      color: alpha(theme.palette.primary.main, 0.6),
      whiteSpace: 'nowrap',
    },
  });
});

export interface LiveTvTileProps {
  /** The card variant. Use `primary` for the current tile or `secondary` for the upcoming tile. */
  variant: 'primary' | 'secondary';
  /** Is available for streaming. */
  availableForStreaming?: boolean
  /** The title of the live stream. */
  title: string;
  /** The description of the live stream. */
  description: string;
  /** The TV channel the program was broadcasted on */
  channel: string;
  /** Start date/time in ISO format. */
  start: string;
  /** End date/time in ISO format. */
  end: string;
  /** The url for the image. */
  imageUrl: string;
  /** The metadata for the program. */
  metadata?: (string | {
    type: 'classification';
    value: OnDemand.Classification;
  })[];
  clickUrl?: string;
}

const LiveTvTile: FunctionComponent<LiveTvTileProps> = (props) => {
  const classes = useStyles(props);

  const {
    variant,
    availableForStreaming = true,
    title,
    imageUrl,
    start,
    end,
    description,
    metadata = [],
    clickUrl,
    channel,
  } = props;

  const { t } = useTranslation();

  const zone = useLocalTimeZone();
  const startDateTime = DateTime.fromISO(start).setZone(zone);
  const endDateTime = DateTime.fromISO(end).setZone(zone);

  const [status, setStatus] = useState<string | ReactElement>('');
  const [badgeColor, setBadgeColor] = useState<'warning' | 'error' | 'success'>('warning');
  const durationToLive = useDurationToLive(start);
  const progressPercent = useCalculateProgress(startDateTime.toISO(), endDateTime.toISO());

  let playButtonLabel;
  // durationToLive will be negative when the program is live
  if (durationToLive < 0) {
    playButtonLabel = t('playback.watchLivestream', { program: title, duration: durationToLive, channel });
  }

  // since status and badgeColor is dynamic, they need to be rendered client side
  useEffect(() => {
    if (availableForStreaming) {
      setBadgeColor(durationToLive > 0 ? 'warning' : 'success');

      setStatus(
        <LiveInLabel durationToAvailable={durationToLive} shortUnits/>,
      );
    } else {
      setStatus(t('availabilityBadge.liveStreamUnavailable'));
      setBadgeColor('error');
    }
  }, [availableForStreaming, durationToLive, t]);

  let time;

  if (startDateTime < endDateTime) {
    const startTime = startDateTime.toFormat('h:mm').toLowerCase();
    const endTime = endDateTime.toFormat('h:mma').toLowerCase();
    time = (
      <div>
        <time dateTime={startDateTime.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}>{startTime}</time>
        -
        <time dateTime={endDateTime.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}>{endTime}</time>
      </div>
    );
  } else {
    time = startDateTime.toFormat('h:mma').toLowerCase();
  }

  let metadataComponent = null;

  if (metadata.length) {
    metadataComponent = (
      <Grid container spacing={2} className={classes.metadataContainer}>
        {
          metadata.map((m) => {
            if (typeof m === 'object' && m.type === 'classification') {
              return (
                <Grid item key={m.value} className={classes.classificationContainer}>
                  <ClassificationBadge
                    size="1em"
                    classes={{ root: classes.classification }}
                    classification={m.value}
                  />
                </Grid>
              );
            }

            return (
              <Grid item key={`${m}`} className={classes.metadata}>
                {m}
              </Grid>
            );
          })
        }
      </Grid>
    );
  }

  if (variant === 'primary') {
    return (
      <LiveTvTilePrimary
        title={title}
        imageUrl={imageUrl}
        description={description}
        badgeColor={badgeColor}
        status={status}
        available={availableForStreaming}
        time={time}
        metadataComponent={metadataComponent}
        progressPercent={progressPercent}
        clickUrl={availableForStreaming ? clickUrl : null}
        playButtonLabel={playButtonLabel}
      />
    );
  }

  return (
    <LiveTvTileSecondary
      title={title}
      imageUrl={imageUrl}
      description={description}
      badgeColor={badgeColor}
      status={status}
      available={availableForStreaming}
      time={time}
      metadataComponent={metadataComponent}
    />
  );
};

export default LiveTvTile;
