import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import ViaPlay from '../../../static/images/distributor-viaplay.png';

interface DistributorLogoProps {
  id: string;
  name: string;
  classes?: any;
}

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return createStyles({
    root: {},
    logo: {
      height: 18,
      [theme.breakpoints.down('sm')]: {
        height: 20,
      },
      [theme.breakpoints.down('xs')]: {
        height: 14,
      },
    },
  });
});

const DistributorLogo: FunctionComponent<DistributorLogoProps> = (props) => {
  const { id, name } = props;
  const classes = useStyles(props);
  const distributorsLogos = {
    20746: ViaPlay,
    99999: ViaPlay, // For unit test purposes until we have more than one distributor
  };

  if (id in distributorsLogos) {
    return (
      <span className={classes.root}>
        <img src={distributorsLogos[id]} alt={name} className={classes.logo}/>
      </span>
    );
  }

  return null;
};

export default DistributorLogo;
