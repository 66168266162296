import { forwardRef, PropsWithChildren, useState, useRef, useCallback, RefObject } from 'react';

import { onButtonKeyListener } from '@@utils/helpers';

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
interface ActivateTileButtonProps {
  onActivate?: () => void;
  onDeactivate?: () => void;
  className?: string;
  tabIndex?: number;
}
/* eslint-enable react/require-default-props */

const ActivateTileButton = forwardRef<HTMLDivElement, PropsWithChildren<ActivateTileButtonProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    children,
    onActivate,
    onDeactivate,
    tabIndex,
    ...other
  } = props;

  const [isActive, setIsActive] = useState<boolean>(false);

  const timeout = 50;

  const activate = useCallback(() => {
    setIsActive(true);
    setTimeout(() => {
      onActivate();
    }, timeout);
  }, [onActivate]);

  const deactivate = useCallback(() => {
    setIsActive(false);
    setTimeout(() => {
      onDeactivate();
    }, timeout);
  }, [onDeactivate]);

  const toggleActive = useCallback(() => {
    if (isActive) {
      deactivate();
    } else {
      activate();
    }
  }, [isActive, activate, deactivate]);

  const deactivateTimeout = useRef(null);

  /**
   *  This complex logic is to handle deactivation of a tile when tabbing over to the next element
   *  outside of this TileSlider. This is done to keep the logic here instead of moving it to the parent
   *  and adding complexity to the parent element.
   *  It's done by registering the index to be deactivated on blur and create a timer to deactivate in 50ms
   */
  // onblur, we want to set the deactivation flag
  function handleBlur() {
    // create a timeout to deactivate
    deactivateTimeout.current = setTimeout(() => {
      deactivate();
    }, timeout);
  }

  function handleFocus() {
    // clear the deactivateTimeout because the child element is in focus
    clearTimeout(deactivateTimeout.current);
  }

  function handleMouseOver() {
    // get the currently focused element and blur it if it's not the current element and has aria-expanded=true
    const { activeElement } = document;
    if (activeElement !== ref.current
      && (
        activeElement.getAttribute('aria-expanded')
        || activeElement.closest('[aria-expanded=true]')
      )) {
      (activeElement as HTMLElement).blur();
    }
    activate();
  }

  function handleMouseLeave() {
    deactivate();
  }

  function handleKeyDown(e) {
    if (e.key === 'Escape') {
      deactivate();
    }
  }

  const handleKeyPress = onButtonKeyListener(() => {
    toggleActive();
  });

  return (
    <div
      ref={ref}
      role="button"
      tabIndex={tabIndex}
      aria-hidden={tabIndex === -1}
      aria-expanded={isActive}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      onKeyPress={handleKeyPress}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {children}
    </div>
  );
});

ActivateTileButton.displayName = 'TileExpandButton';

export default ActivateTileButton;
