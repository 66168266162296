import { combineReducers, configureStore, PreloadedState, ThunkAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import configStore from './stores/ConfigStore';
import favouritesStore from './stores/FavouritesStore';
import progressStore from './stores/ProgressStore';
import recommendationStore from './stores/RecommendationStore';
import searchStore from './stores/SearchStore';
import seriesStore from './stores/SeriesStore';
import settingsStore from './stores/SettingsStore';
import userPreferencesStore from './stores/UserPreferencesStore';
import userStore from './stores/UserStore';
import videoStore from './stores/VideoStore';
import chromeCastStore from './stores/chromeCastStore';

const rootReducer = combineReducers({
  config: configStore.reducer,
  search: searchStore.reducer,
  user: userStore.reducer,
  progress: progressStore.reducer,
  settings: settingsStore.reducer,
  favourites: favouritesStore.reducer,
  userPreferences: userPreferencesStore.reducer,
  video: videoStore.reducer,
  series: seriesStore.reducer,
  chromecast: chromeCastStore.reducer,
  recommendation: recommendationStore.reducer,
});

export default function initialiseStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof initialiseStore>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
