import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';

import GlobalStyles from './components/Styles/GlobalStyles';
import { isRtl, SupportedLanguage } from './i18n';
import { addLanguageSpecificFont } from './styles/typography/fontFamily';
import { generateThemeOptions } from './themes/dark';

/**
 * Provides dynamic rtl support for the children component if the current language is rtl
 * @param children
 * @constructor
 */
export const RtlThemeProvider: FunctionComponent = ({ children }) => {
  const { i18n: { language } } = useTranslation();

  const rtlTheme = useMemo(() => {
    return createTheme({
      ...generateThemeOptions(),
      direction: 'rtl',
    });
  }, []);

  if (isRtl(language)) {
    const jssPlugins = [...jssPreset().plugins];
    jssPlugins.push(rtl());
    const jss = create({ plugins: jssPlugins });

    return (
      <ThemeProvider theme={rtlTheme}>
        <StylesProvider jss={jss}>
          {children}
        </StylesProvider>
      </ThemeProvider>
    );
  }

  return <>{children}</>;
};

export const ThemeProviderWrapper: FunctionComponent = ({ children }) => {
  const { i18n: { language } } = useTranslation();

  addLanguageSpecificFont(language as SupportedLanguage);
  const theme = useMemo(() => {
    return createTheme(generateThemeOptions());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

const ProviderWrapper: FunctionComponent<{ store: any }> = ({ children, store }) => {
  return (
    <ReduxProvider store={store}>
      <ThemeProviderWrapper>
        <CssBaseline/>
        <GlobalStyles/>
        {children}
      </ThemeProviderWrapper>
    </ReduxProvider>
  );
};

export default ProviderWrapper;
